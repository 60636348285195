import SiteBranding from "./SiteBranding";
import ConfigurePages from "./ConfigurePages";
import Button from "../../../../../components/Button/Button";
import Form from "../../../../../components/forms/Form";
import { designValidationSchema } from "../../../../../utils/validationUtils";

// UI components
const SectionContainer = ({ children }) => (
    <div className="row mt-4">
        <div className="col-12">{children}</div>
    </div>
);

const SaveButton = ({ isLoading }) => (
    <Button type="submit" className="float-end" disabled={isLoading}>
        {isLoading ? (
            <div>
                <span
                    className="spinner-border spinner-border-sm mx-2"
                    aria-hidden="true"
                ></span>
                <span role="status">Saving...</span>
            </div>
        ) : (
            "Save Configuration"
        )}
    </Button>
);

const DesignConfig = ({
    handleSubmit,
    logoImage,
    initialValues = {},
    onConfigurePage,
    isLoading = false,
    onPageToggle,
    pageConfigurations,
    handleFileSelected,
}) => {
    return (
        <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={designValidationSchema}
            enableReinitialize={true}
        >
            <SectionContainer>
                <SiteBranding
                    logoImage={logoImage}
                    disabled={isLoading}
                    logoImageUrl={initialValues.logo_image_file_url}
                    logoImageName={initialValues.logo_image_file_name}
                    handleFileSelected={handleFileSelected}
                />
            </SectionContainer>

            <SectionContainer>
                <ConfigurePages
                    onConfigurePage={onConfigurePage}
                    onPageToggle={onPageToggle}
                    pageConfigurations={pageConfigurations}
                    enabledPages={initialValues.enabledPages}
                />
            </SectionContainer>

            <SectionContainer>
                <SaveButton isLoading={isLoading} />
            </SectionContainer>
        </Form>
    );
};

export default DesignConfig;
