import { useState } from "react";
import { useModal } from "../../hooks/modalsHooks";
import Typography from "../Typography/Typography";
import ModalLayout from "../modals/ModalLayout";
import "./model.scss";
import { useNavigate } from "react-router-dom";
import Form from "../forms/Form";
import TextField from "../forms/TextField";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { logEvent } from "@amplitude/analytics-browser";
import { useCreateBatchMutation } from "../../services/supplyChainService";
import Button from "../Button/Button";

export default function CreateBatchModal({
    title = "Create Batch",
    projectId,
    refetch,
    setLoading = () => {}, // Default value for setLoading
}) {
    const { hideModal } = useModal();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [createBatch] = useCreateBatchMutation();
    const handleClose = () => {
        hideModal();
    };

    const BatchValidationSchema = Yup.object().shape({
        name: Yup.string()
            .max(255, "Name is too long")
            .required("Name is required"),
    });

    const handleCreateBatch = async (values) => {
        setIsLoading(true);
        setLoading(true);

        const { name } = values;
        console.log("values", values);
        try {
            const createBatchRes = await createBatch({
                id: projectId,
                name,
            });
            if (createBatchRes.data) {
                setIsLoading(false);
                setLoading(false);
                hideModal();
                toast("Batch Created");
                navigate(`/overview/${projectId}/batches`);
                refetch().then(() => setLoading(false)); // Call refetch after batch creation
                logEvent({
                    eventName: "batch_created_successfully",
                    eventProperties: {
                        name,
                    },
                });
            } else {
                navigate(`/batches`);
                hideModal();
                toast(createBatchRes.error.data.errors[0]);
                logEvent({
                    eventName: "batch_create_failed",
                    eventProperties: {
                        name,
                        errorMessage: createBatchRes.error.data.errors[0],
                    },
                });
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    return (
        <ModalLayout className="modal-dialog-centered mx-2">
            <div className="card d-flex justify-content-center">
                <ModalLayout.Header>
                    <div className="w-100 p-4 pb-0 d-flex justify-content-start">
                        <Typography variant="h2">{title}</Typography>
                    </div>
                </ModalLayout.Header>
                <ModalLayout.Body>
                    <div className="w-100 pt-0 p-4 d-flex justify-content-center">
                        <Form
                            initialValues={{
                                name: "",
                            }}
                            validationSchema={BatchValidationSchema}
                            onSubmit={handleCreateBatch}
                        >
                            <div className="w-100 my-3 col-12 col-md-12">
                                <TextField
                                    required
                                    id="name"
                                    name="name"
                                    label="Batch Name"
                                    placeholder="Enter batch name"
                                />
                            </div>

                            {isLoading && (
                                <div className="text-center d-flex justify-content-center">
                                    <div className="spinner-grow" role="status">
                                        <span className="visually-hidden">
                                            Loading...
                                        </span>
                                    </div>
                                </div>
                            )}
                            <div className="my-4 d-flex justify-content-end flex-row align-items-center">
                                <Button
                                    type="button"
                                    className="d-flex align-items-center btn btn-secondary"
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                                <Button
                                    type="submit"
                                    className="d-flex align-items-center btn btn-primary ms-2"
                                >
                                    Create
                                </Button>
                            </div>
                        </Form>
                    </div>
                </ModalLayout.Body>
            </div>
        </ModalLayout>
    );
}
