import React, { useRef, useEffect } from "react";
import Typography from "../../../../../../components/Typography/Typography";
import Button from "../../../../../../components/Button/Button";
import TextField from "../../../../../../components/forms/TextField";
import TextArea from "../../../../../../components/forms/TextArea";
import UnifiedFileUpload from "../../../../../../components/forms/UnifiedFileUpload";

const StandardItem = ({
    index,
    standard,
    standardImage,
    handleStandardImageselected,
    onRemove,
}) => {
    const fileUploadRef = useRef(null);

    const handleFileChange = (file) => {
        handleStandardImageselected(file, index);
    };

    return (
        <div className="card mb-4">
            <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <Typography variant="h6">Standard {index + 1}</Typography>
                    <Button
                        type="button"
                        variant="danger"
                        size="sm"
                        fullWidth={false}
                        onClick={onRemove}
                    >
                        <i className="bi bi-x-lg" />
                    </Button>
                </div>
                <div className="mb-3">
                    <TextField
                        name={`standards.${index}.standard_title`}
                        label="Standard Title"
                    />
                </div>
                <div className="mb-3">
                    <UnifiedFileUpload
                        ref={fileUploadRef}
                        name={`standards.${index}.standard_image`}
                        label="Standard Image"
                        fileType="image"
                        initialFile={
                            standard.standard_image_file_url
                                ? {
                                      url: standard.standard_image_file_url,
                                      name: standard.standard_image_file_name,
                                      isExisting: true,
                                  }
                                : null
                        }
                        selectedFile={standardImage}
                        onFileSelected={handleFileChange}
                    />
                </div>
                <div className="mb-3">
                    <TextArea
                        name={`standards.${index}.standard_description`}
                        label="Description"
                        rows={3}
                    />
                </div>
            </div>
        </div>
    );
};

export default StandardItem;
