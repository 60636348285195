import React from "react";
import Typography from "../../../../../components/Typography/Typography";
import Form from "../../../../../components/forms/Form";
import Button from "../../../../../components/Button/Button";
import { traceValidationSchema } from "../../../../../utils/validationUtils";
import UnifiedFileUpload from "../../../../../components/forms/UnifiedFileUpload";
import { TextField } from "../../../../../components/forms";
import ToggleSwitch from "../../../../../components/forms/ToggleSwitch";
import { useNavigate, useParams } from "react-router-dom";

export default function TraceConfiguration({
    handleSubmit,
    bannerImage,
    initialValues = {},
    isLoading = false,
    handleFileSelected,
}) {
    const navigate = useNavigate();
    const { id } = useParams();

    const handleCancelClick = () => {
        navigate(`/overview/${id}/site-builder`);
    };

    return (
        <div className="container-fluid px-0">
            <Typography variant="h3" className="mb-3">
                Trace Page Configuration
            </Typography>

            <Form
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={traceValidationSchema}
                enableReinitialize={true}
            >
                {/* Page Settings Card */}
                <div className="card p-3 border-0 rounded-3 mb-3">
                    <Typography variant="h3" className="mb-3">
                        Page Settings
                    </Typography>

                    <div className="mt-3">
                        <TextField name="page_title" label="Page Title" />
                    </div>
                    <div className="mt-3">
                        <TextField
                            name="page_description"
                            label="Page Description"
                        />
                    </div>

                    <div className="mt-3">
                        <UnifiedFileUpload
                            name="bannerImage"
                            fileType="image"
                            label="Banner Image"
                            onFileSelected={handleFileSelected}
                            initialFile={
                                initialValues.banner_image_file_url
                                    ? {
                                          url: initialValues.banner_image_file_url,
                                          name: initialValues.banner_image_file_name,
                                          isExisting: true,
                                      }
                                    : null
                            }
                            selectedFile={bannerImage}
                            width="100%"
                            value={bannerImage}
                        />
                    </div>

                    <div className="mt-3">
                        <ToggleSwitch
                            id="show_trace_widget"
                            name="show_trace_widget"
                            label="Show Trace Widget"
                        />
                    </div>
                </div>

                {/* Buttons */}
                <div className="row mt-4">
                    <div className="col-12 d-flex justify-content-between align-items-center gap-3">
                        <div>
                            <Button
                                type="button"
                                variant="secondary"
                                onClick={handleCancelClick}
                            >
                                Cancel
                            </Button>
                        </div>
                        <div>
                            <Button type="submit" disabled={isLoading}>
                                {isLoading ? (
                                    <div>
                                        <span
                                            className="spinner-border spinner-border-sm mx-2"
                                            aria-hidden="true"
                                        ></span>
                                        <span role="status">Saving...</span>
                                    </div>
                                ) : (
                                    "Save Configuration"
                                )}
                            </Button>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    );
}
