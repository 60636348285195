import React from "react";
import PropTypes from "prop-types";
import "./typography.scss";

const variantsMapping = {
    display1: "h1",
    display2: "h2",
    display3: "h3",
    display4: "h4",
    h1: "h1",
    h2: "h2",
    h3: "h3",
    h4: "h4",
    h5: "h5",
    h6: "h6",
    subtitle: "p",
    buttonText: "p",
    labels: "p",
    hintText: "p",
    body1: "p",
    body2: "p",
    link: "a",
    small: "small",
    heading: "h2",
};

const Typography = ({ variant, children, className, color, ...props }) => {
    const Component = variant ? variantsMapping[variant] : "p";

    return (
        <Component
            className={`typography--variant-${variant} ${className}`}
            style={{ color: color }}
            {...props}
        >
            {children}
        </Component>
    );
};

export default Typography;
