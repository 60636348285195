import React from "react";
import Typography from "../../../../../components/Typography/Typography";
import UnifiedFileUpload from "../../../../../components/forms/UnifiedFileUpload";

const PageSettingsCard = ({
    bannerImage,
    bannerImageUrl,
    bannerImageName,
    isLoading,
    handleFileSelected,
}) => {
    return (
        <div className="card p-3 border-0 rounded-3">
            <Typography variant="h3" className="pb-1 text-lg">
                Page Settings
            </Typography>

            <div className="mt-3">
                <UnifiedFileUpload
                    name="bannerImage"
                    fileType="image"
                    label="Hero Background Image"
                    onFileSelected={handleFileSelected}
                    initialFile={
                        bannerImageUrl
                            ? {
                                  url: bannerImageUrl,
                                  name: bannerImageName,
                                  isExisting: true,
                              }
                            : null
                    }
                    selectedFile={bannerImage}
                    width="100%"
                    disabled={isLoading}
                />
            </div>
        </div>
    );
};

export default PageSettingsCard;
