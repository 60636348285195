import React from 'react';
import Typography from '../../../../../components/Typography/Typography';

const Overview = ({ data }) => (
  <div className="container p-3">
    <div className="row d-flex align-items-center justify-content-center">
      <div className="col-7 d-flex flex-column justify-content-center">
        <div>
          <Typography variant="small" className="process-name">
            {data?.processName} in
          </Typography>
        </div>
        <div>
          <Typography variant="h6" className="location-name">
            {data?.location}
          </Typography>
        </div>
      </div>
      <div className="col-5 text-end d-flex flex-column justify-content-end">
        {data &&
          [
            data.date,
            `${data.quantity} Quantity`,
            `Reference ID #${data.referenceId}`,
          ].map((text, i) => (
            <div key={i} className="date">
              {text}
            </div>
          ))}
      </div>
    </div>
  </div>
);

export default Overview;