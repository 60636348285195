import { useState } from "react";
import Typography from "../../../components/Typography/Typography";
import { useParams } from "react-router-dom";
import BatchSelection from "./BatchSelection";
import BatchRecords from "./BatchRecords/BatchRecords";
import BatchVisualization from "./BatchVisualization";
import Button from "../../../components/Button/Button";
import { useModal } from "../../../hooks/modalsHooks"; // Add this import

const DataEntry = () => {
    const { id } = useParams();
    const { showModal } = useModal(); // Add this hook
    const [selectedBatch, setSelectedBatch] = useState(null);

    const handleAddNewMaterial = () => { // Add this handler
        showModal({
            modalType: "AddRecordModal",
            modalProps: {
                id,
                selectedBatch,
            },
        });
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 pt-2">
                    <Typography variant="h2" className="mb-4 text-2xl">
                        Data Entry
                    </Typography>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card p-3 border-0 rounded-3">
                        <Typography variant="h3" className="pb-1 text-lg">
                            Batch Selection
                        </Typography>
                        <BatchSelection
                            id={id}
                            selectedBatch={selectedBatch}
                            setSelectedBatch={setSelectedBatch}
                        />
                    </div>
                </div>
            </div>

            {selectedBatch && (
                <div className="row mt-4">
                    <BatchVisualization
                        selectedBatch={selectedBatch}
                        defaultCollapsed={true}
                    />

                    <div className="col-12 my-4">
                        <div className="card p-3 border-0 rounded-3">
                            <div className="d-flex align-items-center justify-content-between pb-3">
                                <Typography
                                    variant="h3"
                                    className="text-lg mb-0"
                                >
                                    Batch Records
                                </Typography>
                                <Button
                                    className="btn btn-primary"
                                    fullWidth={false}
                                    onClick={handleAddNewMaterial}
                                >
                                    Add Records
                                </Button>
                            </div>

                            <BatchRecords
                                id={id}
                                selectedBatch={selectedBatch}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DataEntry;
