import { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { DEFAULT_VIEW } from "../constants/pageConfigs";

export const usePageNavigation = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeView, setActiveView] = useState(DEFAULT_VIEW);

    // Parse URL on initialization and when location changes
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const page = searchParams.get("page");

        if (page) {
            setActiveView(page);
        } else {
            updateUrlWithoutReload(DEFAULT_VIEW);
        }
    }, [location]);

    // Update URL without triggering a reload
    const updateUrlWithoutReload = useCallback(
        (view) => {
            const searchParams = new URLSearchParams();
            searchParams.set("page", view);

            navigate(
                {
                    pathname: location.pathname,
                    search: searchParams.toString(),
                },
                { replace: true },
            );

            window.scrollTo(0, 0);
        },
        [navigate, location.pathname],
    );

    // Navigation handler
    const navigateToPage = useCallback(
        (page) => {
            setActiveView(page);
            updateUrlWithoutReload(page);
        },
        [updateUrlWithoutReload],
    );

    return {
        activeView,
        navigateToPage,
    };
};
