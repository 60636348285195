import React from "react";
import Typography from "../../../../../../components/Typography/Typography";
import TextField from "../../../../../../components/forms/TextField";
import TextArea from "../../../../../../components/forms/TextArea";

const SubtitleSection = () => {
    return (
        <div className="mb-4">
            <Typography variant="h5" className="mb-3">
                Page Subtitle
            </Typography>
            <div className="mb-4">
                <div className="mb-3">
                    <TextField
                        name="page_subtitles[0].subtitle"
                        label="Subtitle"
                    />
                </div>
                <div className="mb-3">
                    <TextArea
                        name="page_subtitles[0].subtitle_description"
                        label="Description"
                        rows={3}
                    />
                </div>
            </div>
        </div>
    );
};

export default SubtitleSection;
