import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./baseQueries";
import sctVisualizationTransformer from "../utils/sctVisualizationTransformer";

export const supplyChainAPI = createApi({
    reducerPath: "supplyChainAPI",
    baseQuery: baseQueryWithReauth,
    tagTypes: ["Asset", "AssetType", "Node", "Batch", "Configuration"],
    endpoints: (builder) => ({
        getAssetsType: builder.query({
            query: (id) => `/api/templates/supply_chain/${id}/asset_types/`,
            providesTags: ["AssetType"],
        }),
        getOneAssetsType: builder.query({
            query: ({ id, assetTypeId, available_assets, batch }) => ({
                url: `/api/templates/supply_chain/${id}/asset_types/${assetTypeId}/`,
                params: { available_assets, batch },
            }),
            providesTags: ["AssetType"],
        }),
        createAssetType: builder.mutation({
            query: ({
                id,
                asset_type_name,
                unit_category,
                node,
                material_type,
                dependency_config,
                form_config,
            }) => ({
                url: `/api/templates/supply_chain/${id}/asset_types/`,
                method: "POST",
                body: {
                    asset_type_name,
                    unit_category,
                    node,
                    material_type,
                    dependency_config,
                    form_config,
                },
            }),
            invalidatesTags: ["AssetType"],
        }),
        getAssetsList: builder.query({
            query: ({ projectId, nodeId, batchId }) =>
                `/api/templates/supply_chain/${projectId}/assets/${nodeId ? `?node=${nodeId}` : batchId ? `?batch=${batchId}` : ""}`,
            providesTags: ["Asset"],
        }),
        getNodesList: builder.query({
            query: (id) => `/api/templates/supply_chain/${id}/nodes/`,
            providesTags: ["Node"],
        }),
        createNode: builder.mutation({
            query: ({
                id,
                name,
                description,
                location,
                location_meta_data,
                node_image,
            }) => ({
                url: `/api/templates/supply_chain/${id}/nodes/`,
                method: `POST`,
                body: {
                    name,
                    description,
                    location,
                    location_meta_data,
                    // promo_image,
                    node_image,
                },
            }),
            invalidatesTags: ["Node"],
        }),
        editNode: builder.mutation({
            query: ({
                id,
                nodeId,
                name,
                description,
                location,
                location_meta_data,
                node_image,
            }) => ({
                url: `/api/templates/supply_chain/${id}/nodes/${nodeId}/`,
                method: `PUT`,
                body: {
                    name,
                    description,
                    location,
                    location_meta_data,
                    node_image,
                },
            }),
            invalidatesTags: ["Node", "Asset", "AssetType"],
        }),
        createAsset: builder.mutation({
            query: ({
                id,
                asset_id,
                asset_type,
                node,
                unit,
                qty,
                dependencies,
                form_data,
                batch_id,
            }) => ({
                url: `/api/templates/supply_chain/${id}/assets/`,
                method: "POST",
                body: {
                    asset_id,
                    asset_type,
                    unit,
                    qty,
                    node,
                    dependencies,
                    form_data,
                    batch_id,
                },
            }),
            invalidatesTags: ["Asset"],
        }),
        getTraceAsset: builder.query({
            query: ({ id, asset_id }) =>
                `/api/templates/supply_chain/${id}/assets/${asset_id}/trace-v2/`,
        }),
        confirmAsset: builder.mutation({
            query: ({ id, asset_id, transaction_hash, token_id = null }) => ({
                url: `/api/templates/supply_chain/${id}/assets/${asset_id}/confirm/`,
                method: "PUT",
                body: {
                    transaction_hash,
                    token_id,
                },
            }),
            invalidatesTags: ["Asset"],
        }),
        updateAssetType: builder.mutation({
            query: ({ id, assetTypeId, form_config, dependency_config }) => ({
                url: `/api/templates/supply_chain/${id}/asset_types/${assetTypeId}/`,
                method: "PUT",
                body: {
                    form_config,
                    dependency_config,
                },
            }),
            invalidatesTags: ["AssetType"],
        }),
        addTransfer: builder.mutation({
            query: ({
                id,
                receiver,
                from_node,
                to_node,
                transfer_content,
            }) => ({
                url: `/api/templates/supply_chain/${id}/asset_transfers/`,
                method: "POST",
                body: {
                    receiver,
                    from_node,
                    to_node,
                    transfer_content,
                },
            }),
            invalidatesTags: ["Asset"],
        }),
        getAllTransfer: builder.query({
            query: (id) => `/api/templates/supply_chain/${id}/asset_transfers/`,
        }),
        //Supply Chain Batch
        getAllBatches: builder.query({
            query: (id) => `/api/templates/supply_chain/${id}/batches/`,
        }),
        createBatch: builder.mutation({
            query: ({ id, name }) => ({
                url: `/api/templates/supply_chain/${id}/batches/`,
                method: "POST",
                body: {
                    name,
                },
            }),
            invalidatesTags: ["Batch"],
        }),
        editBatch: builder.mutation({
            query: ({ id, batchId, name }) => ({
                url: `/api/templates/supply_chain/${id}/batches/${batchId}/`,
                method: "PUT",
                body: {
                    name,
                },
            }),
            invalidatesTags: ["Batch"],
        }),
        getBatchById: builder.query({
            query: ({ id, batchId }) =>
                `/api/templates/supply_chain/${id}/batches/${batchId}/`,
            providesTags: ["Batch"],
        }),
        getUnits: builder.query({
            query: () => `/api/templates/supply_chain/unit_categories/`,
        }),
        getAssetTypesVisualizationGraph: builder.query({
            query: (id) =>
                `/api/templates/supply_chain/${id}/asset_types/visualize/`,
            transformResponse: sctVisualizationTransformer,
            providesTags: ["Asset", "AssetType"],
        }),
        getAssetBatchVisualizationGraph: builder.query({
            query: ({ id, batchId }) =>
                `/api/templates/supply_chain/${id}/batches/${batchId}/visualize/`,
            transformResponse: sctVisualizationTransformer,
            providesTags: ["Batch", "Asset", "AssetType"],
        }),
        getDashboardData: builder.query({
            query: (id) => `/api/templates/supply_chain/${id}/dashboard/`,
            providesTags: ["Asset", "AssetType", "Node", "Batch"],
        }),
        //Site builder configurations
        saveSiteConfiguration: builder.mutation({
            query: ({ id, configData }) => ({
                url: `/api/templates/supply_chain/${id}/site_configuration/`,
                method: "POST",
                body: configData,
            }),
            invalidatesTags: ["Configuration"],
        }),
        getAllSiteConfiguration: builder.query({
            query: (id) =>
                `/api/templates/supply_chain/${id}/site_configuration/`,
            providesTags: ["Configuration"],
        }),
        getSiteConfigurationForAPage: builder.query({
            query: ({ id, pageId }) =>
                `/api/templates/supply_chain/${id}/site_configuration/${pageId}/`,
            providesTags: ["Configuration"],
        }),
        updateSiteConfiguration: builder.mutation({
            query: ({ id, pageId, configData }) => ({
                url: `/api/templates/supply_chain/${id}/site_configuration/${pageId}/`,
                method: "PUT",
                body: configData,
            }),
            invalidatesTags: ["Configuration"],
        }),
        patchSiteConfiguration: builder.mutation({
            query: ({ id, pageId, configData }) => ({
                url: `/api/templates/supply_chain/${id}/site_configuration/${pageId}/`,
                method: "PATCH",
                body: configData,
            }),
            invalidatesTags: ["Configuration"],
        }),
    }),
});

export const {
    useGetAssetsTypeQuery,
    useCreateAssetTypeMutation,
    useGetAssetsListQuery,
    useGetNodesListQuery,
    useCreateNodeMutation,
    useCreateAssetMutation,
    useGetTraceAssetQuery,
    useLazyGetTraceAssetQuery,
    useConfirmAssetMutation,
    useGetOneAssetsTypeQuery,
    useUpdateAssetTypeMutation,
    useAddTransferMutation,
    useGetAllTransferQuery,
    useGetAllBatchesQuery,
    useCreateBatchMutation,
    useGetBatchByIdQuery,
    useGetUnitsQuery,
    useEditBatchMutation,
    useEditNodeMutation,
    useGetAssetTypesVisualizationGraphQuery,
    useGetAssetBatchVisualizationGraphQuery,
    useGetDashboardDataQuery,
    useSaveSiteConfigurationMutation,
    useGetAllSiteConfigurationQuery,
    useGetSiteConfigurationForAPageQuery,
    useUpdateSiteConfigurationMutation,
    usePatchSiteConfigurationMutation,
} = supplyChainAPI;
