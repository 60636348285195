import Typography from "../../../components/Typography/Typography";
import ExistingMaterial from "./ExistingMaterial/ExistingMaterial";
import { useParams } from "react-router-dom";
import { useGetAssetsTypeQuery } from "../../../services/supplyChainService";
import Loading from "../../../components/LoadingSkeleton/Loading";
import Button from "../../../components/Button/Button";
import { useModal } from "../../../hooks/modalsHooks";
import MaterialVisualization from "./MaterialVisualization";

const ConfigureMaterialTab = () => {
    // 1. Hooks
    const { id } = useParams();
    const { showModal } = useModal();
    const { data: assetTypesResponse, isLoading: isLoadingAssetTypes } =
        useGetAssetsTypeQuery(id, { skip: !id });

    // 2. Derived state/data
    const assetTypesData = assetTypesResponse?.data || [];
    const mergedData = assetTypesData.map((asset, index) => ({
        ...asset,
        dependencies: asset.dependency_config?.dependencies || [],
    }));

    // 3. Handler functions
    const handleAddNewMaterial = () => {
        showModal({
            modalType: "MaterialPopup",
            modalProps: {
                id,
                onMaterialAdded: (newMaterial) => {
                    // Optionally handle the newly added material
                    // You might want to refresh the data or update the local state
                },
            },
        });
    };

    // 4. Loading state check
    if (isLoadingAssetTypes) {
        return (
            <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100vh" }}
            >
                <Loading />
            </div>
        );
    }
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 pt-2">
                    <Typography variant="h2" className="mb-4 text-2xl">
                        Configure Supply Chain Materials
                    </Typography>
                </div>
            </div>

            <MaterialVisualization defaultCollapsed={true} />

            <div className="row mb-4">
                <div className="col-12">
                    <div className="card p-3 border-0 rounded-3">
                        <div className="d-flex align-items-center justify-content-between pb-3">
                            <Typography variant="h3" className="text-lg mb-0">
                                Existing Materials
                            </Typography>
                            <Button
                                className="btn btn-primary"
                                fullWidth={false}
                                onClick={handleAddNewMaterial}
                            >
                                Add New Material
                            </Button>
                        </div>
                        <ExistingMaterial existingMaterialData={mergedData} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfigureMaterialTab;
