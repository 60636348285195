// 1. Core/Third-party imports
import { useModal } from "../../../hooks/modalsHooks";
import { useGetNodesListQuery } from "../../../services/supplyChainService";

// 2. Component imports
import Button from "../../../components/Button/Button";
import Loading from "../../../components/LoadingSkeleton/Loading";
import { BasicTable } from "../../../components/tables";
import Typography from "../../../components/Typography/Typography";

// 3. Utility imports
import { formatDate } from "../../../utils/projectUtils";

const ConfigureLocation = ({ projectData }) => {
    // Hooks
    const { showModal } = useModal();
    const {
        data: nodesResponse,
        isLoading: isLoadingNodes,
        refetch,
    } = useGetNodesListQuery(projectData.id, { skip: !projectData.id });

    // Derived state
    const nodeData = nodesResponse?.data || [];

    // Utility functions
    const truncateText = (text, maxLength = 50) => {
        if (!text) return "N/A";
        return text.length > maxLength
            ? `${text.substring(0, maxLength)}...`
            : text;
    };

    const truncateLocation = (location, maxLength = 30) => {
        if (!location) return "N/A";
        const parts = location.split(",");
        if (parts.length > 2) return `${parts[0]}, ${parts[1].trim()}...`;
        return location.length > maxLength
            ? `${location.substring(0, maxLength)}...`
            : location;
    };

    // Event handlers
    const handleAddLocation = () => {
        showModal({
            modalType: "AddLocationPopupModal",
            modalProps: {
                id: projectData.id,
                onSubmit: refetch,
                onLocationAdded: refetch,
            },
        });
    };

    const handleEditClick = (node) => {
        showModal({
            modalType: "AddLocationPopupModal",
            modalProps: {
                id: projectData.id,
                editMode: true,
                nodeData: {
                    id: node.id,
                    name: node.name,
                    description: node.description,
                    location: node.location,
                    location_meta_data: node.location_meta_data,
                    node_image: node.promo_image,
                },
                onSubmit: refetch,
                onLocationAdded: refetch,
            },
        });
    };

    // Table configuration
    const columns = [
        {
            header: "Name",
            accessor: "name",
            Cell: ({ cell: { value } }) => value || "N/A",
        },
        {
            header: "Description",
            accessor: "description",
            Cell: ({ cell: { value } }) => (
                <div title={value}>{truncateText(value)}</div>
            ),
        },
        {
            header: "Location",
            accessor: "location",
            Cell: ({ cell: { value } }) => (
                <div title={value}>{truncateLocation(value)}</div>
            ),
        },
        {
            header: "Created At",
            accessor: "created_at",
            Cell: ({ cell: { value } }) => formatDate(value) || "N/A",
        },
        {
            header: "Actions",
            accessor: "actions",
            Cell: ({ row }) => (
                <div className="d-flex gap-2" key={`actions-${row.original.id}`}>
                    <Button
                        variant="primary"
                        fullWidth={false}
                        onClick={() => handleEditClick(row.original)}
                    >
                        Edit
                    </Button>
                </div>
            ),
        },
    ];

    // Loading state
    if (isLoadingNodes) {
        return (
            <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100vh" }}
            >
                <Loading />
            </div>
        );
    }

    // Render
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 pt-2">
                    <Typography variant="h2" className="mb-4 text-2xl">
                        Configure Locations
                    </Typography>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-12">
                    <div className="card p-3 border-0 rounded-3">
                        <div className="d-flex align-items-center justify-content-between pb-3">
                            <Typography variant="h3" className="text-lg mb-0">
                                Locations
                            </Typography>
                            <Button
                                className="btn btn-primary"
                                fullWidth={false}
                                onClick={handleAddLocation}
                            >
                                Add New Location
                            </Button>
                        </div>
                        <div className="pt-2">
                            {nodeData && nodeData.length > 0 ? (
                                <BasicTable
                                    data={nodeData}
                                    columns={columns}
                                    isSortable={true}
                                    isPaginated={nodeData?.length > 4}
                                    pageSize={5}
                                    currentPage={0}
                                />
                            ) : (
                                <Typography variant="body1">
                                    No locations have been added yet.
                                </Typography>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfigureLocation;
