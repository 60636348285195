import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSaveSiteConfigurationMutation } from "../../../../../services/supplyChainService";
import aboutTemplate from "./template.json";
import { useImageProcessing } from "../../../../../utils/imageUtils";
import AboutConfiguration from "./AboutConfiguration";

export default function AboutPageController({
    projectId,
    allConfigurations,
    isLoading: isLoadingAll,
    configId,
    setConfigId,
    initialValues,
    setInitialValues,
    pageConfigurations,
    setPageConfigurations,
}) {
    // API hooks
    const [saveSiteConfiguration, { isLoading: isSaving }] =
        useSaveSiteConfigurationMutation();
    const { processImage } = useImageProcessing();

    // Image state management
    const [aboutBannerImage, setAboutBannerImage] = useState(null);
    const [aboutSecondaryImage, setAboutSecondaryImage] = useState(null);
    const [aboutTeamImage, setAboutTeamImage] = useState(null);
    const [aboutStoryImages, setAboutStoryImages] = useState([]);

    // Initialize configuration from existing data
    useEffect(() => {
        if (!allConfigurations?.data) return;

        const aboutConfig = allConfigurations.data.find(
            (config) => config.config_type === "ABOUT_US",
        );

        if (aboutConfig) {
            setConfigId(aboutConfig.id);
            const aboutConfigData = aboutConfig.config_data;

            // Set images from existing configuration
            if (aboutConfigData.banner_image_file_url) {
                setAboutBannerImage(aboutConfigData.banner_image_file_url);
            }

            if (aboutConfigData.secondary_image_file_url) {
                setAboutSecondaryImage(
                    aboutConfigData.secondary_image_file_url,
                );
            }

            if (aboutConfigData.team_image_file_url) {
                setAboutTeamImage(aboutConfigData.team_image_file_url);
            }

            if (aboutConfigData.stories?.length) {
                setAboutStoryImages(
                    aboutConfigData.stories.map(
                        (story) => story.story_image_file_url || null,
                    ),
                );
            }

            setInitialValues({
                ...aboutConfigData,
            });
        }
    }, [allConfigurations, setConfigId, setInitialValues]);

    // Image selection handlers
    const handleAboutBannerSelected = (file) => {
        if (file !== undefined) {
            setAboutBannerImage(file);
        }
    };

    const handleAboutSecondarySelected = (file) => {
        if (file !== undefined) {
            setAboutSecondaryImage(file);
        }
    };

    const handleAboutTeamSelected = (file) => {
        if (file !== undefined) {
            setAboutTeamImage(file);
        }
    };

    const handleAboutStoryImageSelected = (file, index) => {
        setAboutStoryImages((prev) => {
            const newImages = [...prev];
            newImages[index] = file;
            return newImages;
        });
    };

    // Form submission handler
    const handleSubmit = async (values) => {
        try {
            // Process all images in parallel
            const bannerImageFileId = await processImage(
                aboutBannerImage,
                initialValues.banner_image_file_id,
            );

            const secondaryImageFileId = await processImage(
                aboutSecondaryImage,
                initialValues.secondary_image_file_id,
            );

            const teamImageFileId = await processImage(
                aboutTeamImage,
                initialValues.team_image_file_id,
            );

            // Process stories to handle any image uploads
            const processedStories = await Promise.all(
                values.stories
                    .filter(
                        (story) =>
                            // Only include stories that have at least one field filled
                            story.story_title?.trim() ||
                            story.story_content?.trim() ||
                            aboutStoryImages[values.stories.indexOf(story)],
                    )
                    .map(async (story, index) => {
                        // Get the story image from the aboutStoryImages array
                        const storyImage =
                            aboutStoryImages[values.stories.indexOf(story)];

                        // Use the image utility to process the image
                        const storyImageFileId = await processImage(
                            storyImage,
                            story.story_image_file_id,
                        );

                        return {
                            story_title: story.story_title?.trim() || null,
                            story_image_file_id: storyImageFileId,
                            story_content: story.story_content?.trim() || null,
                        };
                    }),
            );

            // Determine page enablement status
            const existingConfiguration = pageConfigurations.about?.exists;
            const isEnabled = existingConfiguration
                ? pageConfigurations.about?.enabled !== false
                : true;

            // Prepare configuration data
            const configData = {
                ...aboutTemplate,
                config_data: {
                    page_title: values.page_title || initialValues.page_title,
                    page_description:
                        values.page_description ||
                        initialValues.page_description,
                    banner_image_file_id: bannerImageFileId,
                    secondary_image_file_id: secondaryImageFileId,
                    page_subtitles: [
                        {
                            subtitle:
                                values.page_subtitles[0]?.subtitle ||
                                initialValues.page_subtitles[0]?.subtitle ||
                                "",
                            subtitle_description:
                                values.page_subtitles[0]
                                    ?.subtitle_description ||
                                initialValues.page_subtitles[0]
                                    ?.subtitle_description ||
                                "",
                        },
                    ],
                    team_image_file_id: teamImageFileId,
                    team_quote:
                        values.team_quote || initialValues.team_quote || "",
                    team_author:
                        values.team_author || initialValues.team_author || "",
                    video_content: values.video_content,
                    stories: processedStories,
                },
                enabled: isEnabled,
                required: aboutTemplate.required,
            };

            // Save configuration to backend
            const result = await saveSiteConfiguration({
                id: projectId,
                configData,
            }).unwrap();

            // Update local state with new configuration
            if (result?.data?.id) {
                setConfigId(result.data.id);
                setPageConfigurations((prev) => ({
                    ...prev,
                    about: {
                        ...prev.about,
                        id: result.data.id,
                        exists: true,
                        enabled: existingConfiguration
                            ? prev.about.enabled
                            : true,
                    },
                }));
            }

            toast.success("About page configuration saved successfully");
        } catch (error) {
            console.error("Error saving about configuration:", error);
            toast.error("Failed to save about page configuration");
        }
    };

    return (
        <AboutConfiguration
            handleSubmit={handleSubmit}
            initialValues={initialValues}
            isLoading={isSaving || isLoadingAll}
            aboutBannerImage={aboutBannerImage}
            aboutSecondaryImage={aboutSecondaryImage}
            aboutTeamImage={aboutTeamImage}
            aboutStoryImages={aboutStoryImages}
            handleAboutBannerSelected={handleAboutBannerSelected}
            handleAboutSecondarySelected={handleAboutSecondarySelected}
            handleAboutTeamSelected={handleAboutTeamSelected}
            handleAboutStoryImageSelected={handleAboutStoryImageSelected}
        />
    );
}
