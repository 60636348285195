import React from "react";
import Typography from "../../../../../../components/Typography/Typography";
import TextField from "../../../../../../components/forms/TextField";
import TextArea from "../../../../../../components/forms/TextArea";

const VideoContent = () => {
    return (
        <div className="mb-4">
            <Typography variant="h5" className="mb-3">
                Video Content
            </Typography>
            <div className="mb-3">
                <TextField
                    name="video_content.video_title"
                    label="Video Title"
                />
            </div>
            <div className="mb-3">
                <TextField
                    name="video_content.video_link"
                    label="Video Link"
                    type="url"
                />
            </div>
            <div className="mb-3">
                <TextArea
                    name="video_content.video_description"
                    label="Video Description"
                    rows={3}
                />
            </div>
        </div>
    );
};

export default VideoContent;
