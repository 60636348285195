import Typography from "../../components/Typography/Typography";
import "./project.scss";
import { useParams } from "react-router-dom";
import CreateSupplyChainTraceabilityProject from "../sct/CreateSupplyChainTraceabilityProject";
import CreateTaaSProject from "../taas/CreateTaaSProject";

// const headingMap = {
//     // "token-service": "tokenization",
//     "supply-chain": "supply chain",
// };

export default function CreateProject() {
    const { projectType } = useParams();

    // const title = headingMap[projectType] ? headingMap[projectType] : "";

    return (
        <div className="project-container h-100 w-100 px-4 mt-5 pt-5">
            <div className="pt-3 text-center">
                <Typography variant="h1">Create new project</Typography>
            </div>
            <div className="container col-xxl-8 px-4 ">
                <div className="row align-items-center justify-content-center d-flex flex-row">
                    <div className="col-lg-10 col-md-12 mx-auto justify-content-center">
                        <div className="card create-project-card p-4">
                            <Typography variant="h2" className="section-title">
                                Project details
                            </Typography>
                            {projectType === "supply-chain" && (
                                <CreateSupplyChainTraceabilityProject />
                            )}
                            {projectType === "token-service" && (
                                <CreateTaaSProject />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
