import React, { useMemo, useState } from "react";
import Typography from "../../../../components/Typography/Typography";
import { useGetAssetBatchVisualizationGraphQuery } from "../../../../services/supplyChainService";
import { useParams } from "react-router-dom";
import Loading from "../../../../components/LoadingSkeleton/Loading";
import Flow from "../../../../components/Flow";

export default function BatchVisualization({ selectedBatch, defaultCollapsed = false }) {
    const { id } = useParams();
    const { data: graphResponse, isLoading: isLoadingGraph } =
        useGetAssetBatchVisualizationGraphQuery(
            { id, batchId: selectedBatch?.value },
            { skip: !id || !selectedBatch },
        );

    const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);

    const nodes = useMemo(() => {
        if (
            !graphResponse ||
            !graphResponse.data ||
            !graphResponse.data.nodes ||
            !graphResponse.data.nodes.length
        ) {
            return [];
        }

        return graphResponse.data.nodes.map((node) => {
            return {
                ...node,
                type: "SCTProductNode",
            };
        });
    }, [graphResponse]);

    if (isLoadingGraph) {
        return (
            <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100vh" }}
            >
                <Loading />
            </div>
        );
    }

    if (nodes.length === 0) {
        return null;
    }

    return (
        <div className="col-12">
            <div className="card p-3 border-0 rounded-3">
                <div 
                    className={`d-flex align-items-center justify-content-between ${isCollapsed ? "" : "pb-3"}`}
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    style={{ cursor: 'pointer' }}
                >
                    <Typography variant="h3" className="text-lg mb-0">
                        Batch Visualization
                    </Typography>
                    <button
                        className="btn btn-link p-0 text-dark"
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsCollapsed(!isCollapsed);
                        }}
                        title={isCollapsed ? "Expand" : "Collapse"}
                    >
                        {isCollapsed ? (
                            <i className="bi bi-caret-down-fill" style={{ color: "black" }}></i>
                        ) : (
                            <i className="bi bi-caret-up-fill" style={{ color: "black" }}></i>
                        )}
                    </button>
                </div>

                {!isCollapsed && (
                    <div className="w-100 bg-white rounded-3 shadow-sm">
                        <Flow
                            initialNodes={nodes}
                            initialEdges={graphResponse.data.links}
                            layoutDirrection="TB"
                            displayPanels={true}
                            displayMiniMap={false}
                            displayControls={true}
                            nodeHeight={100}
                            nodeWidth={200}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
