import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useGetOneAssetsTypeQuery } from "../../../../services/supplyChainService";
import Typography from "../../../../components/Typography/Typography";
import RecordForm from "./RecordForm";
import Button from "../../../../components/Button/Button";

const DependencyContainer = ({
    dependency,
    projectId,
    onDependencyChange,
    onRemoveDependency,
    onMissingDependencies,
    index,
    batch
}) => {
    // State Management
    const [formValues, setFormValues] = useState({
        [`asset_${dependency.asset_type_id}`]: "",
        [`quantity_${dependency.asset_type_id}`]: "",
        [`unit_${dependency.asset_type_id}`]: "",
    });
    const [combinedQuantities, setCombinedQuantities] = useState({});
    const previousHasAssets = useRef(null);

    // API Query
    const {
        data: mergeableAssetsResponse,
        isLoading,
        refetch,
    } = useGetOneAssetsTypeQuery(
        {
            id: projectId,
            assetTypeId: dependency.asset_type_id,
            available_assets: true,
            batch
        },
        { skip: !projectId || !dependency.asset_type_id },
    );

    // Data Processing
    const filteredAssets =
        mergeableAssetsResponse?.data?.assets
            ?.filter((asset) => asset.qty > 0)
            .map((asset) => ({
                assetId: asset.asset_id,
                assetUuid: asset.uuid,
                assetTypeId: asset.asset_type.id,
                qty: asset.qty,
                unit: asset.unit.symbol,
                available: !asset.is_locked,
            })) || [];

    // Helper Functions
    const getRemainingQuantities = () => {
        const usedQuantities = {};
        dependency.selectedAssets?.forEach((asset) => {
            usedQuantities[asset.assetId] =
                combinedQuantities[asset.assetId] || 0;
        });

        return filteredAssets
            .map((asset) => ({
                ...asset,
                remainingQty: asset.qty - (usedQuantities[asset.assetId] || 0),
            }))
            .filter((asset) => asset.remainingQty > 0);
    };

    const isFormValid = () => {
        const assetId = formValues[`asset_${dependency.asset_type_id}`];
        const quantity = Number(
            formValues[`quantity_${dependency.asset_type_id}`],
        );
        const unit = formValues[`unit_${dependency.asset_type_id}`];

        return Boolean(assetId) && quantity > 0 && Boolean(unit);
    };

    // Effects
    useEffect(() => {
        if (!isLoading && mergeableAssetsResponse?.data?.assets) {
            const hasAvailableAssets = mergeableAssetsResponse.data.assets.some(
                (asset) => asset.qty > 0,
            );

            if (previousHasAssets.current !== hasAvailableAssets) {
                previousHasAssets.current = hasAvailableAssets;
                onMissingDependencies?.(!hasAvailableAssets);
            }
        }
    }, [isLoading, mergeableAssetsResponse, onMissingDependencies]);

    useEffect(() => {
        const checkInitialAvailability = () => {
            if (mergeableAssetsResponse?.data?.assets) {
                const hasAvailableAssets =
                    mergeableAssetsResponse.data.assets.some(
                        (asset) => asset.qty > 0,
                    );
                previousHasAssets.current = hasAvailableAssets;
                onMissingDependencies?.(!hasAvailableAssets);
            }
        };

        checkInitialAvailability();

        return () => {
            previousHasAssets.current = null;
            onMissingDependencies?.(false);
        };
    }, []);

    useEffect(() => {
        if (dependency.selectedAssets?.length >= 0) {
            refetch().then(() => {
                if (mergeableAssetsResponse?.data?.assets) {
                    const hasAvailableAssets =
                        mergeableAssetsResponse.data.assets.some(
                            (asset) => asset.qty > 0,
                        );
                    onMissingDependencies?.(!hasAvailableAssets);
                }
            });
        }
    }, [dependency.selectedAssets, refetch]);

    // Event Handlers
    const handleInputChange = (field) => (e) => {
        const value = e.target.value;
        if (field === `asset_${dependency.asset_type_id}`) {
            const selectedAsset = filteredAssets.find(
                (asset) => asset.assetId === value,
            );
            setFormValues((prev) => ({
                ...prev,
                [field]: value,
                [`unit_${dependency.asset_type_id}`]: selectedAsset?.unit || "",
            }));
            return;
        }
        setFormValues((prev) => ({ ...prev, [field]: value }));
    };

    const handleAdd = () => {
        const assetId = formValues[`asset_${dependency.asset_type_id}`];
        const quantity = Number(
            formValues[`quantity_${dependency.asset_type_id}`],
        );
        const unit = formValues[`unit_${dependency.asset_type_id}`];

        if (!assetId || !quantity || !unit) return;

        const selectedAsset = filteredAssets.find(
            (asset) => asset.assetId === assetId,
        );

        const existingQuantity = combinedQuantities[assetId] || 0;
        const totalQuantity = existingQuantity + quantity;

        // Validation checks
        const remainingAsset = getRemainingQuantities().find(
            (asset) => asset.assetId === assetId,
        );

        if (quantity > remainingAsset?.remainingQty) {
            toast.error(
                `Quantity cannot exceed remaining amount of ${remainingAsset.remainingQty} ${remainingAsset.unit}`,
            );
            return;
        }

        if (totalQuantity > selectedAsset.qty) {
            toast.error(
                `Total quantity cannot exceed ${selectedAsset.qty} ${unit}`,
            );
            return;
        }

        // Update combined quantities by adding the new quantity
        setCombinedQuantities((prev) => ({
            ...prev,
            [assetId]: totalQuantity,
        }));

        const existingAssetIndex = dependency.selectedAssets?.findIndex(
            (asset) => asset.assetId === assetId,
        );

        if (existingAssetIndex !== -1) {
            // Update existing asset quantity
            const updatedSelectedAssets = [...dependency.selectedAssets];
            updatedSelectedAssets[existingAssetIndex].quantity = totalQuantity;

            onDependencyChange({
                ...dependency,
                selectedAssets: updatedSelectedAssets,
            });
        } else {
            // Add new asset
            const newAsset = {
                assetId,
                assetUuid: selectedAsset?.assetUuid,
                quantity: totalQuantity,
                unit,
            };

            onDependencyChange({
                ...dependency,
                selectedAssets: [
                    ...(dependency.selectedAssets || []),
                    newAsset,
                ],
            });
        }

        setFormValues({
            [`asset_${dependency.asset_type_id}`]: "",
            [`quantity_${dependency.asset_type_id}`]: "",
            [`unit_${dependency.asset_type_id}`]: "",
        });
    };

    // Render Helpers
    const renderBadges = () => (
        <div className="selected-dependencies mt-1">
            {Object.entries(combinedQuantities).map(([assetId, totalQty]) => {
                const asset = filteredAssets.find((a) => a.assetId === assetId);
                if (!asset) return null;

                return (
                    <span
                        key={assetId}
                        className="badge me-2 mb-2 p-2 d-inline-flex align-items-center"
                        style={{
                            fontSize: "0.9rem",
                            backgroundColor: "#DBEAFE",
                            color: "#1C8AD1",
                            border: "1px solid #DBEAFE",
                        }}
                    >
                        {assetId} - {totalQty} {asset.unit}
                        <i
                            className="bi bi-x-circle ms-2"
                            style={{
                                cursor: "pointer",
                                color: "#1C8AD1",
                            }}
                            onClick={() => {
                                const newQuantities = { ...combinedQuantities };
                                delete newQuantities[assetId];
                                setCombinedQuantities(newQuantities);

                                const newSelectedAssets =
                                    dependency.selectedAssets.filter(
                                        (asset) => asset.assetId !== assetId,
                                    );

                                onDependencyChange({
                                    ...dependency,
                                    selectedAssets: newSelectedAssets,
                                });
                            }}
                        />
                    </span>
                );
            })}
        </div>
    );

    // Loading State
    if (isLoading) {
        return <div>Loading mergeable assets...</div>;
    }

    // Empty State
    if (!mergeableAssetsResponse?.data?.assets?.length) {
        return (
            <div className="dependency-item mb-4 bg-white">
                <Typography variant="h3" className="mb-4 fw-bold">
                    Type {(index + 1).toString().padStart(2, "0")} -{" "}
                    {dependency.asset_value}
                </Typography>
                <div>
                    <Typography variant="body1">No assets found.</Typography>
                </div>
            </div>
        );
    }

    // Main Render
    return (
        <div className="dependency-item  mb-4 bg-white">
            <Typography variant="h3" className="fw-bold">
                Type {(index + 1).toString().padStart(2, "0")} -{" "}
                {dependency.label}
            </Typography>
            <div className="row g-3 align-items-end">
                <div className="col-5">
                    <RecordForm
                        fieldType="dropdown"
                        name={`asset_${dependency.asset_type_id}`}
                        label="Asset"
                        value={formValues[`asset_${dependency.asset_type_id}`]}
                        onChange={handleInputChange(
                            `asset_${dependency.asset_type_id}`,
                        )}
                        options={getRemainingQuantities().map((asset) => ({
                            label: `${asset.assetId} (${asset.remainingQty} ${asset.unit})`,
                            value: asset.assetId,
                            uuid: asset.assetUuid,
                        }))}
                    />
                </div>
                <div className="col-3">
                    <RecordForm
                        fieldType="number"
                        name={`quantity_${dependency.asset_type_id}`}
                        label="Quantity"
                        value={
                            formValues[`quantity_${dependency.asset_type_id}`]
                        }
                        onChange={handleInputChange(
                            `quantity_${dependency.asset_type_id}`,
                        )}
                        min="0"
                        step="1"
                    />
                </div>
                {formValues[`unit_${dependency.asset_type_id}`] && (
                    <div className="col-2 my-4">
                        <label className="form-label">Unit</label>
                        <div className="form-control bg-light">
                            {formValues[`unit_${dependency.asset_type_id}`]}
                        </div>
                    </div>
                )}
                <div className="col-2 my-4">
                    <Button
                        variant="primary"
                        onClick={handleAdd}
                        disabled={!isFormValid()}
                        className="w-100"
                    >
                        Add
                    </Button>
                </div>
            </div>
            {renderBadges()}
        </div>
    );
};

export default DependencyContainer;
