import React from "react";
import { useField } from "formik";
import "./form.scss";

const ToggleSwitch = ({ label, ...props }) => {
    const [field, meta, helpers] = useField(props);

    // Ensure the value is always boolean
    const handleChange = (e) => {
        helpers.setValue(e.target.checked);
    };

    return (
        <div className="toggle-switch">
            <input
                type="checkbox"
                className="toggle-switch-checkbox"
                id={props.id || props.name}
                {...field}
                checked={!!field.value}
                onChange={handleChange}
            />
            <label
                className="toggle-switch-label"
                htmlFor={props.id || props.name}
            >
                <span className="toggle-switch-inner" />
                <span className="toggle-switch-switch" />
            </label>
            {label && <span className="toggle-switch-text">{label}</span>}
        </div>
    );
};

export default ToggleSwitch;
