import ConfigureTokenizationProject from "../../taas/Admin/ConfigurationTab/ConfigureTokenizationProject";
import { ProjectTemplates } from "../../../utils/projectUtils";
import ConfigureMaterialTab from "../../sct/ConfigureMaterialTab/ConfigureMaterialTab";

export default function ConfigurationTab({ projectType }) {
    if (projectType === ProjectTemplates.SUPPLY_CHAIN) {
        return <ConfigureMaterialTab />;
    }

    if (projectType === ProjectTemplates.TOKENIZATION) {
        return <ConfigureTokenizationProject />;
    }
}
