import { useState } from "react";
import { Form } from "../../../components/forms";
import { useGetAllBatchesQuery, useCreateBatchMutation, useEditBatchMutation } from "../../../services/supplyChainService";
import { toast } from "react-toastify";
import InputSearchDropdown from "./InputSearchDropdown/InputSearchDropdown";

const BatchSelection = ({ id, selectedBatch, setSelectedBatch }) => {
    // State declarations
    const [loading, setLoading] = useState(false);

    // API hooks
    const [createBatch] = useCreateBatchMutation();
    const [editBatch] = useEditBatchMutation();
    const {
        data: batchesResponse,
        isLoading: isLoadingBatches,
        refetch,
    } = useGetAllBatchesQuery(id);

    // Derived values
    const batchesList = batchesResponse?.data;
    const batchData = batchesList?.map((batch) => ({ 
        value: batch.id, 
        label: batch.name 
    })) || [];
    const transformedBatches = batchData.map(batch => ({
        id: batch.value,
        name: batch.label
    }));

    // Event handlers
    const handleBatchSelect = (batch) => {
        setSelectedBatch({
            value: batch.id,
            label: batch.name
        });
    };

    const handleCreateBatch = async (batchName) => {
        setLoading(true);
        try {
            const response = await createBatch({
                id: id,
                name: batchName,
            }).unwrap();

            if (response) {
                toast("Batch Created");
                await refetch();
                // Select the newly created batch
                setSelectedBatch({
                    value: response.data.id,
                    label: response.data.name
                });
            }
        } catch (error) {
            toast.error(error.data?.errors?.[0] || "Failed to create batch");
        } finally {
            setLoading(false);
        }
    };

    const handleEditBatch = async (batchId, newName) => {
        setLoading(true);
        try {
            const response = await editBatch({
                id: id,
                batchId: batchId,
                name: newName,
            }).unwrap();

            if (response) {
                toast("Batch Updated");
                await refetch();
                setSelectedBatch({
                    value: response.data.id,
                    label: response.data.name
                });
            }
        } catch (error) {
            toast.error(error.data?.errors?.[0] || "Failed to update batch");
        } finally {
            setLoading(false);
        }
    };

    // Render
    return (
        <div className="d-flex flex-column flex-sm-row align-items-center mt-3">
            <Form
                initialValues={{
                    batch: "",
                }}
            >
                <div className="row d-flex align-items-end">
                    <div className="col">
                        <InputSearchDropdown
                            batches={transformedBatches}
                            selectedBatch={selectedBatch ? {
                                id: selectedBatch.value,
                                name: selectedBatch.label
                            } : null}
                            onSelect={handleBatchSelect}
                            onCreateBatch={handleCreateBatch}
                            onEditBatch={handleEditBatch}
                            placeholder="Search or create batch..."
                            loading={loading}
                        />
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default BatchSelection;
