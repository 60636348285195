export const PAGES = [
    { key: "home", label: "Home Page", mandatory: true },
    { key: "trace", label: "Trace Page", mandatory: true },
    {
        key: "certification",
        label: "Certifications and Standards Page",
        mandatory: false,
    },
    // { key: "sustainability", label: "Sustainability Page", mandatory: false },
    { key: "about", label: "About Us Page", mandatory: false },
];

/**
 * Creates the default enabled pages configuration
 * @returns {Object.<string, boolean>}
 */
export const getDefaultEnabledPages = () => {
    return PAGES.reduce((acc, page) => {
        acc[page.key] = !!page.mandatory;
        return acc;
    }, {});
};
