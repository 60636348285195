import { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import { qualityIcons } from "../../../constants/icons";

const QualityIconSelector = ({ name, index }) => {
    const [selectedIcon, setSelectedIcon] = useState("bi-star-fill");
    const { setFieldValue, values } = useFormikContext();

    // Keep local state in sync with Formik values
    useEffect(() => {
        if (values.qualities[index]?.quality_icon) {
            setSelectedIcon(values.qualities[index].quality_icon);
        }
    }, [values.qualities, index]);

    const handleIconChange = (e) => {
        const newIcon = e.target.value;
        setSelectedIcon(newIcon);
        setFieldValue(name, newIcon);
    };

    return (
        <div className="mb-3">
            <label className="form-label">Quality Icon</label>
            <div className="d-flex align-items-center gap-2">
                <div
                    className="p-2 border rounded d-flex justify-content-center align-items-center"
                    style={{ width: "40px", height: "40px" }}
                >
                    <i className={`bi ${selectedIcon} fs-4`}></i>
                </div>
                <select
                    className="form-select"
                    name={name}
                    value={selectedIcon}
                    onChange={handleIconChange}
                    style={{ maxWidth: "250px" }}
                >
                    {qualityIcons.map((icon) => (
                        <option key={icon.value} value={icon.value}>
                            {icon.label}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default QualityIconSelector;
