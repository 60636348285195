import Typography from "../../../../../../components/Typography/Typography";
import Button from "../../../../../../components/Button/Button";
import TextField from "../../../../../../components/forms/TextField";
import TextArea from "../../../../../../components/forms/TextArea";
import QualityIconSelector from "../../../../../../components/sct/site_builder/QualityIconSelector";

const QualityItem = ({ index, quality, onRemove }) => {
    return (
        <div className="card mb-4">
            <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <Typography variant="h6">Quality {index + 1}</Typography>

                    <Button
                        type="button"
                        variant="danger"
                        size="sm"
                        fullWidth={false}
                        onClick={onRemove}
                    >
                        <i className="bi bi-x-lg" />
                    </Button>
                </div>
                <div className="mb-3">
                    <TextField
                        name={`qualities.${index}.quality_title`}
                        label="Quality Title"
                    />
                </div>

                <div className="mb-3">
                    <TextArea
                        name={`qualities.${index}.quality_description`}
                        label="Description"
                        rows={3}
                    />
                </div>

                <QualityIconSelector
                    name={`qualities.${index}.quality_icon`}
                    index={index}
                />
            </div>
        </div>
    );
};

export default QualityItem;
