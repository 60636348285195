import React from "react";
import { useField } from "formik";
import "./form.scss";

/**
 * Native select input wrapper to support dynamic option rendering and Formik context.
 * @param {{
 * name: string,
 * label: string,
 * options: { value: string, label: string }[],
 * disabled?: boolean,
 * onChange?: (selectedOption: { value: string, label: string }) => void,
 * placeholder?: string,
 * }} props
 * @returns {React.FunctionComponent}
 */
export default function FilterSelect({ name, label, options = [], onChange, placeholder = "Select...", ...rest }) {
    const [field, meta, helpers] = useField({ name, label, ...rest });

    const handleChange = (event) => {
        const selectedValue = event.target.value;
        const selectedOption = options.find(option => option.value === selectedValue);
        helpers.setValue(selectedValue);        
        if (onChange && selectedOption) {
            onChange(selectedOption);
        }
    };

    return (
        <div className="w-100 select dropdown">
            {label && (
                <label htmlFor={name} className="form-label">
                    {label}
                </label>
            )}
            <select
                className="form-select"
                id={name}
                {...field}
                onChange={handleChange}
                {...rest}
            >
                <option value="">{placeholder}</option>
                {options.map((option) => (
                    <option
                        key={option.value}
                        value={option.value}
                    >
                        {option.label}
                    </option>
                ))}
            </select>

            {meta.touched && meta.error ? (
                <div className="invalid-feedback" style={{ display: "block" }}>
                    {meta.error}
                </div>
            ) : null}
        </div>
    );
}