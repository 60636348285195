import SidebarLayout from "../../components/Sidebar/SidebarLayout";
import UserPage from "./users/UserPage";
import BillingPage from "./billing/BillingPage";
import PaymentsPage from "./payments/PaymentsPage";
import OrganizationPage from "./organization/OrganizationPage";

const pageData = [
    {
        name: "Organization",
        component: <OrganizationPage />,
        path: "company",
        icon: "bi bi-building",
    },
    {
        name: "Users",
        component: <UserPage />,
        path: "users",
        icon: "bi bi-people",
    },
    {
        name: "Billing",
        component: <BillingPage />,
        path: "billing",
        icon: "bi bi-receipt",
    },
    // {
    //     name: "Payments",
    //     component: <PaymentsPage />,
    //     path: "payments",
    //     icon: "bi bi-coin",
    // },
];

export default function SettingsPage() {
    return <SidebarLayout pageData={pageData} defaultPage="configuration" />;
}
