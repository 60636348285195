import { useCallback, useEffect } from "react";
import {
    ReactFlow,
    MiniMap,
    Controls,
    ConnectionLineType,
    Background,
    useNodesState,
    useEdgesState,
    addEdge,
    Panel,
} from "@xyflow/react";
import getLayoutedElements from "./getLayoutedElements";
import "@xyflow/react/dist/style.css";
import Button from "../Button/Button";
import { useState } from "react";
import SCTProductNode from "./Nodes/SCTProductNode";

export default function Flow({
    initialNodes,
    initialEdges,
    containerWidth,
    containerHeight,
    nodeWidth,
    nodeHeight,
    layoutDirrection,
    displayPanels,
    displayMiniMap,
    displayControls,
    onNodesChangeEventHandler,
    onEdgesChangeEventHandler,
}) {
    containerWidth = containerWidth || "100%";
    containerHeight = containerHeight || "500px";
    nodeWidth = nodeWidth || 180;
    nodeHeight = nodeHeight || 80;
    layoutDirrection = layoutDirrection || "TB";

    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [rfInstance, setRfInstance] = useState(null);
    const [isInteractive, setIsInteractive] = useState(false);

    useEffect(() => {
        const { nodes: layoutedNodes, edges: layoutedEdges } =
            getLayoutedElements(
                initialNodes,
                initialEdges,
                nodeWidth,
                nodeHeight,
                layoutDirrection,
            );

        setNodes(layoutedNodes);
        setEdges(layoutedEdges);

        // Optionally fit the view to reflect the new layout
        setTimeout(() => {
            rfInstance?.fitView?.();
        }, 100);
    }, [
        initialNodes,
        initialEdges,
        nodeWidth,
        nodeHeight,
        layoutDirrection,
        rfInstance,
        setNodes,
        setEdges,
    ]);

    useEffect(() => {
        if (onNodesChangeEventHandler) {
            onNodesChangeEventHandler(nodes);
        }
    }, [nodes, onNodesChangeEventHandler]);

    useEffect(() => {
        if (onEdgesChangeEventHandler) {
            onEdgesChangeEventHandler(edges);
        }
    }, [edges, onEdgesChangeEventHandler]);

    const onConnect = useCallback(
        (params) =>
            setEdges((eds) =>
                addEdge(
                    {
                        ...params,
                        type: ConnectionLineType.SmoothStep,
                        animated: true,
                    },
                    eds,
                ),
            ),
        [setEdges],
    );

    const onLayout = useCallback(
        (direction) => {
            if (!isInteractive) return;
            const { nodes: layoutedNodes, edges: layoutedEdges } =
                getLayoutedElements(
                    nodes,
                    edges,
                    nodeWidth,
                    nodeHeight,
                    direction,
                );

            setNodes([...layoutedNodes]);
            setEdges([...layoutedEdges]);
            setTimeout(() => {
                rfInstance?.fitView?.();
            }, 100);
        },
        [
            nodes,
            edges,
            nodeWidth,
            nodeHeight,
            isInteractive,
            rfInstance,
            setNodes,
            setEdges,
        ],
    );

    const onInteractiveChange = useCallback(() => {
        setIsInteractive(!isInteractive);
    }, [isInteractive]);

    const nodeTypes = { SCTProductNode: SCTProductNode };

    return (
        <div className="w-100 h-100">
            <div
                style={{
                    height: containerHeight,
                    width: containerWidth,
                }}
            >
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    nodeTypes={nodeTypes}
                    nodesFocusable={true}
                    onInit={(instance) => setRfInstance(instance)}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    connectionLineType={ConnectionLineType.SmoothStep}
                    fitView={true}
                    style={{ backgroundColor: "#F7F9FB" }}
                    nodesDraggable={false}
                    nodesConnectable={false}
                    elementsSelectable={false}
                >
                    <Background />
                    {displayMiniMap && <MiniMap />}
                    {displayControls && (
                        <Controls
                            className="w-auto"
                            onInteractiveChange={onInteractiveChange}
                        />
                    )}

                    {displayPanels && (
                        <Panel position="top-left">
                            <div className="d-flex gap-2 p-2">
                                <Button
                                    onClick={() => onLayout("TB")}
                                    size="sm"
                                    variant="outline-primary"
                                    className="w-auto rounded"
                                    disabled={!isInteractive}
                                >
                                    <span
                                        style={{
                                            fontSize: "12px",
                                            textWrap: "nowrap",
                                        }}
                                    >
                                        {" "}
                                        Vertical Layout
                                    </span>
                                </Button>
                                <Button
                                    onClick={() => onLayout("LR")}
                                    size="sm"
                                    variant="outline-primary"
                                    className="w-auto rounded"
                                    disabled={!isInteractive}
                                >
                                    <span
                                        style={{
                                            fontSize: "12px",
                                            textWrap: "nowrap",
                                        }}
                                    >
                                        Horizontal Layout
                                    </span>
                                </Button>
                            </div>
                        </Panel>
                    )}
                </ReactFlow>
            </div>
        </div>
    );
}
