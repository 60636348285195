import Typography from "../../../../../../components/Typography/Typography";
import Button from "../../../../../../components/Button/Button";
import { FieldArray } from "formik";
import StoryItem from "./StoryItem";

const StoriesSection = ({ storyImages, handleStoryImageSelected }) => {
    return (
        <div className="mb-4">
            <Typography variant="h5" className="mb-3">
                Stories
            </Typography>
            <FieldArray name="stories">
                {({ push, remove, form }) => (
                    <div>
                        {form.values.stories?.map((story, index) => (
                            <StoryItem
                                key={index}
                                index={index}
                                story={story}
                                storyImage={storyImages[index]}
                                handleStoryImageSelected={(file) =>
                                    handleStoryImageSelected(file, index)
                                }
                                onRemove={() => remove(index)}
                            />
                        ))}
                        <Button
                            variant="secondary"
                            type="button"
                            onClick={() =>
                                push({
                                    story_title: "",
                                    story_image: null,
                                    story_content: "",
                                })
                            }
                            startIcon={<i className="bi bi-plus" />}
                            size="sm"
                        >
                            Add Story
                        </Button>
                    </div>
                )}
            </FieldArray>
        </div>
    );
};

export default StoriesSection;
