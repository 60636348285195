import React from "react";
import Typography from "../../../../../components/Typography/Typography";
import UnifiedFileUpload from "../../../../../components/forms/UnifiedFileUpload";
import ColorPicker from "../../../../../components/forms/ColorPicker";
import { TextField } from "../../../../../components/forms";

export default function SiteBranding({
    logoImage,
    disabled,
    logoImageUrl,
    logoImageName,
    handleFileSelected,
}) {
    return (
        <div className="card p-3 border-0 rounded-3">
            <Typography variant="h3" className="pb-1 text-lg">
                Site Branding
            </Typography>

            <div className="mt-2">
                <ColorPicker
                    label="Primary Color"
                    name="primary_color"
                    placeholder="Pick a color"
                    disabled={disabled}
                    required={true}
                />
            </div>

            <div className="mt-3">
                <TextField
                    name="site_slug"
                    label="Site Slug"
                    placeholder="site-slug"
                    disabled={disabled}
                    required={true}
                />
            </div>

            <div className="mt-3">
                <UnifiedFileUpload
                    name="logoImage"
                    fileType="image"
                    label="Logo Image"
                    onFileSelected={handleFileSelected}
                    initialFile={
                        logoImageUrl
                            ? {
                                  url: logoImageUrl,
                                  name: logoImageName,
                                  isExisting: true,
                              }
                            : null
                    }
                    selectedFile={logoImage}
                    width="100%"
                    disabled={disabled}
                />
            </div>
        </div>
    );
}
