import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./baseQueries";

export const tenantAPI = createApi({
    reducerPath: "tenantAPI",
    baseQuery: baseQueryWithReauth,
    tagTypes: ["Profile", "Tenant"],
    endpoints: (builder) => ({
        getTenant: builder.query({
            query: () => ({
                url: "/api/tenants/",
                method: "GET",
            }),
            providesTags: ["Tenant"],
        }),
        createTenant: builder.mutation({
            query: ({
                first_name,
                last_name,
                name,
                job_role,
                employee_count,
                industry,
            }) => ({
                url: "/api/tenants/",
                method: "POST",
                body: {
                    first_name,
                    last_name,
                    name,
                    job_role,
                    employee_count,
                    industry,
                },
            }),
            invalidatesTags: ["Tenant"],
        }),
        getAllUsers: builder.query({
            query: () => ({
                url: "/api/tenants/users/",
                method: "GET",
            }),
            invalidatesTags: ["Profile"],
        }),
        updateTenantOrganization: builder.mutation({
            query: ({ name, tenantId, slug }) => ({
                url: `/api/tenants/${tenantId}/`,
                method: "PUT",
                body: {
                    name,
                    slug,
                },
            }),
            invalidatesTags: ["Tenant"],
        }),
    }),
});

export const {
    useGetTenantQuery,
    useCreateTenantMutation,
    useGetAllUsersQuery,
    useUpdateTenantOrganizationMutation,
} = tenantAPI;
