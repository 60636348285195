import React, { useState } from "react";
import { useGetAssetsListQuery } from "../../../../services/supplyChainService";
import { TextField, Select, Checkbox } from "../../../../components/forms";
import { useParams } from "react-router-dom";
import UnifiedFileUpload from "../../../../components/forms/UnifiedFileUpload";
import TextArea from "../../../../components/forms/TextArea";

const RecordForm = ({
    fieldType,
    name,
    label,
    options = [],
    typeName = null,
    isRequired,
    ...props
}) => {
    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);

    const onFileSelected = (file) => {
        setFiles([file]);
        setSelectedFile(file);
    };

    const renderField = () => {
        switch (fieldType) {
            case "text":
                return <TextField name={name} label={label} />;
            case "number":
                return (
                    <TextField
                        name={name}
                        label={label}
                        type="number"
                        min="0"
                        required={isRequired}
                        onKeyDown={(e) => {
                            if (e.key === "-" || e.key === "e") {
                                e.preventDefault();
                            }
                        }}
                        {...props}
                    />
                );
            case "date":
                return (
                    <TextField
                        name={name}
                        label={label}
                        type="date"
                        required={isRequired}
                    />
                );
            case "dropdown":
                return (
                    <Select
                        name={name}
                        label={label}
                        options={options}
                        {...props}
                        required={isRequired}
                        value={props.value}
                    />
                );
            case "file":
                return (
                    <UnifiedFileUpload
                        name={name}
                        fileType="document"
                        label={label}
                        onFileSelected={onFileSelected}
                        selectedFile={selectedFile}
                        width="100%"
                        required={isRequired}
                    />
                );
            case "image":
                return (
                    <UnifiedFileUpload
                        name={name}
                        fileType="image"
                        label={label}
                        onFileSelected={onFileSelected}
                        selectedFile={selectedFile}
                        width="100%"
                        required={isRequired}
                    />
                );
            case "checkbox":
                return (
                    <Checkbox name={name} label={label} required={isRequired} />
                );
            case "description":
                return (
                    <TextArea name={name} label={label} required={isRequired} />
                );
            default:
                return (
                    <TextField
                        name={name}
                        label={label}
                        required={isRequired}
                    />
                );
        }
    };

    return <div className="my-4">{renderField()}</div>;
};

export default RecordForm;
