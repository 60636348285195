import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useSaveSiteConfigurationMutation } from "../../../../../services/supplyChainService";
import designTemplate from "./template.json";
import DesignConfig from "./DesignConfig";
import { useImageProcessing } from "../../../../../utils/imageUtils";

const DesignPageController = ({
    projectId,
    allConfigurations,
    isLoading: isLoadingAll,
    navigateToPage,
    configId,
    setConfigId,
    initialValues,
    setInitialValues,
    pageConfigurations,
    setPageConfigurations,
}) => {
    // API hooks
    const [saveSiteConfiguration, { isLoading: isSaving }] =
        useSaveSiteConfigurationMutation();
    const { processImage } = useImageProcessing();

    // Local state
    const [logoImage, setLogoImage] = useState(null);
    const [enabledPagesChanges, setEnabledPagesChanges] = useState({});

    // Initialize from configuration data
    useEffect(() => {
        if (!allConfigurations?.data) return;

        const designConfig = allConfigurations.data.find(
            (config) => config.config_type === "DESIGN",
        );

        if (designConfig) {
            setConfigId(designConfig.id);
            const configData = designConfig.config_data;

            if (configData.logo_image_file_url) {
                setLogoImage(configData.logo_image_file_url);
            }

            setInitialValues({
                ...configData,
            });
        }
    }, [allConfigurations, setConfigId, setInitialValues]);

    // Event handlers
    const handleLogoFileSelected = (file) => {
        setLogoImage(file);
    };

    const handlePageToggle = (pageKey, isEnabled) => {
        if (isEnabled && !pageConfigurations[pageKey]?.exists) {
            toast.warning(
                `You need to configure the ${pageKey} page before it can be enabled`,
            );
            return;
        }

        setEnabledPagesChanges((prev) => ({
            ...prev,
            [pageKey]: isEnabled,
        }));

        setPageConfigurations((prev) => ({
            ...prev,
            [pageKey]: {
                ...prev[pageKey],
                enabled: isEnabled,
            },
        }));
    };

    // Form submission handler
    const handleDesignSubmit = async (values) => {
        try {
            const logoImageFileId = await processImage(
                logoImage,
                initialValues.logo_image_file_id,
            );

            const configData = {
                ...designTemplate,
                config_data: {
                    site_slug: values.site_slug || initialValues.site_slug,
                    primary_color:
                        values.primary_color || initialValues.primary_color,
                    logo_image_file_id: logoImageFileId,
                },
                enabled: designTemplate.enabled,
                required: designTemplate.required,
            };

            const result = await saveSiteConfiguration({
                id: projectId,
                configData,
            }).unwrap();

            if (result?.data?.id) {
                setConfigId(result.data.id);
            }

            // Apply page enablement changes
            if (allConfigurations?.data) {
                for (const [pageKey, isEnabled] of Object.entries(
                    enabledPagesChanges,
                )) {
                    let configType, configId, template;

                    // Map page key to config type and ID
                    switch (pageKey) {
                        case "home":
                            configType = "HOME";
                            configId = allConfigurations.data.find(
                                (config) => config.config_type === "HOME",
                            )?.id;
                            break;
                        case "about":
                            configType = "ABOUT_US";
                            configId = allConfigurations.data.find(
                                (config) => config.config_type === "ABOUT_US",
                            )?.id;
                            break;
                        case "trace":
                            configType = "TRACE";
                            configId = allConfigurations.data.find(
                                (config) => config.config_type === "TRACE",
                            )?.id;
                            break;
                        case "certification":
                            configType = "CERTIFICATION";
                            configId = allConfigurations.data.find(
                                (config) =>
                                    config.config_type === "CERTIFICATION",
                            )?.id;
                            break;
                        default:
                            continue;
                    }

                    if (!pageConfigurations[pageKey]?.exists && isEnabled) {
                        console.warn(
                            `Attempted to enable ${pageKey} page that doesn't exist yet`,
                        );
                        continue;
                    }

                    if (configId) {
                        const existingConfig = allConfigurations.data?.find(
                            (config) => config?.id === configId,
                        );

                        if (existingConfig) {
                            await saveSiteConfiguration({
                                id: projectId,
                                configData: {
                                    ...existingConfig,
                                    enabled: isEnabled,
                                },
                            }).unwrap();
                        }
                    }
                }
            }

            setEnabledPagesChanges({});
            toast.success("Design configuration saved successfully");
        } catch (error) {
            console.error("Error saving design configuration:", error);
            if (error?.data?.errors?.[0]) {
                toast.error(
                    error.data.errors[0] ||
                        "Failed to save design configuration",
                );
            } else {
                toast.error("Failed to save design configuration");
            }
        }
    };

    return (
        <DesignConfig
            handleSubmit={handleDesignSubmit}
            logoImage={logoImage}
            initialValues={initialValues}
            onConfigurePage={navigateToPage}
            isLoading={isSaving}
            pageConfigurations={pageConfigurations}
            onPageToggle={handlePageToggle}
            handleFileSelected={handleLogoFileSelected}
        />
    );
};

export default DesignPageController;
