const getBackgroundColorForMaterialType = (materialType) => {
    switch (materialType) {
        case "RAW":
            return "rgb(76, 175, 80)";
        case "EXTERNAL":
            return "rgb(33, 150, 243)";
        case "FINAL":
            return "rgb(255, 193, 7)";
        default:
            return "rgb(96, 125, 139)";
    }
};

const sctVisualizationTransformer = (response) => {
    if (!response?.data?.nodes) return response;

    const nodes = response.data.nodes.map((node) => {
        const materialType = node?.data?.material_type || node?.data?.asset_type?.material_type;
        const background = getBackgroundColorForMaterialType(materialType);
        return {
            ...node,
            style: {
                background: node.cstyle?.background || background,
                color: node.cstyle?.color || "#fff",
                border: "1px solid #000",
                textAlign: "center",
                borderRadius: "10px",
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            },
        };
    });
    response.data.nodes = nodes;

    return response;
};

export default sctVisualizationTransformer;
