import React from "react";
import Typography from "../../../../../components/Typography/Typography";
import ConfigPreview from "./ConfigPreview";

const TemplateConfigurationsCard = ({ configurations }) => {
    // Filter configurations by specific types
    const aboutConfig = configurations.find(
        (config) => config.config_type === "ABOUT_US",
    );
    const traceConfig = configurations.find(
        (config) => config.config_type === "TRACE",
    );
    const sustainabilityConfig = configurations.find(
        (config) => config.config_type === "SUSTAINABILITY",
    );
    const certificationConfig = configurations.find(
        (config) => config.config_type === "CERTIFICATION",
    );

    // Check if any of the specific configs exist
    const hasSpecificConfigs =
        aboutConfig ||
        traceConfig ||
        sustainabilityConfig ||
        certificationConfig;

    return (
        <div className="card p-3 border-0 rounded-3">
            <Typography variant="h3" className="pb-1 text-lg">
                Preview of Other Pages' Sections
            </Typography>

            <div className="mt-3">
                <Typography variant="body1" className="mb-3">
                    Below are the preview of the other template configurations
                    for your website:
                </Typography>

                {aboutConfig && <ConfigPreview config={aboutConfig} />}
                {traceConfig && <ConfigPreview config={traceConfig} />}
                {sustainabilityConfig && (
                    <ConfigPreview config={sustainabilityConfig} />
                )}
                {certificationConfig && (
                    <ConfigPreview config={certificationConfig} />
                )}

                {!hasSpecificConfigs && (
                    <div className="alert alert-info">
                        No templates are configured yet. Add About Us, Trace,
                        Sustainability or Certification templates to enhance
                        your website.
                    </div>
                )}
            </div>
        </div>
    );
};

export default TemplateConfigurationsCard;
