import React, { useEffect } from "react";
import { useField } from "formik";
import "./form.scss";

const ColorPicker = ({ label, name, placeholder, ...props }) => {
    const [field, meta, helpers] = useField(name);

    // Initialize the field with a default value if it's empty
    useEffect(() => {
        if (!field.value && !meta.touched) {
            helpers.setValue("");
        }
    }, []);

    const handleChange = (event) => {
        helpers.setValue(event.target.value);
        helpers.setTouched(true);
    };

    return (
        <div className="w-100">
            {label && (
                <label htmlFor={name} className="form-label">
                    {label} {props.required && <span>*</span>}
                </label>
            )}
            <label
                className="color-picker w-100"
                htmlFor={`${name}-color-picker`}
            >
                <input
                    type="color"
                    className="color-input"
                    id={`${name}-color-picker`}
                    value={field.value || "#000000"} // Ensure a fallback value for display
                    onChange={handleChange}
                    onBlur={() => helpers.setTouched(true)} // Mark as touched on blur
                    {...props}
                />
                <input
                    value={field.value || ""}
                    type="text"
                    className="text-input"
                    id={name}
                    placeholder={placeholder || ""}
                    onChange={handleChange}
                    onBlur={() => helpers.setTouched(true)} // Mark as touched on blur
                    disabled={props.disabled}
                />
            </label>
            {meta.touched && meta.error ? (
                <div className="text-danger mt-2">{meta.error}</div>
            ) : null}
        </div>
    );
};

export default ColorPicker;
