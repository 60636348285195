export const qualityIcons = [
    { value: "bi-award", label: "Award" },
    { value: "bi-trophy", label: "Trophy" },
    { value: "bi-patch-check", label: "Checkmark Badge" },
    { value: "bi-shield-check", label: "Shield" },
    { value: "bi-clipboard2-check", label: "Clipboard" },
    { value: "bi-mortarboard", label: "Graduate Cap" },
    { value: "bi-card-checklist", label: "Checklist" },
    { value: "bi-star", label: "Star" },
    { value: "bi-gem", label: "Gem" },
    { value: "bi-hand-thumbs-up", label: "Thumbs Up" },
    { value: "bi-check2-circle", label: "Check Circle" },
    { value: "bi-fingerprint", label: "Fingerprint" },
    { value: "bi-graph-up-arrow", label: "Growth" },
    { value: "bi-calendar-check", label: "Calendar" },
    { value: "bi-person-check", label: "Person" },
    { value: "bi-bookmark-check", label: "Bookmark" },
    { value: "bi-shield-lock", label: "Security" },
    { value: "bi-patch-exclamation", label: "Alert Badge" },
    { value: "bi-file-earmark-check", label: "Document" },
];
