import { useField, useFormikContext } from "formik";
import AsyncSelect from "react-select/async";
import debounce from "lodash.debounce";
import "./form.scss";

const LocationSearchSelect = ({
    placeholder = "Search locations...",
    label,
    name,
    required = false,
    searchLocationsHook,
    defaultValue = null
}) => {
    const { setFieldValue } = useFormikContext();
    const [, meta] = useField(name);
    const [searchLocations] = searchLocationsHook();

    const fetchLocationOptions = async (inputValue, callback) => {
        if (inputValue.trim()) {
            try {
                const data = await searchLocations(inputValue).unwrap();
                if (data) {
                    callback(
                        data.map((item) => ({
                            label: item.display_name,
                            value: item.display_name,
                            meta: { ...item },
                        }))
                    );
                } else {
                    callback([]);
                }
            } catch (error) {
                callback([]);
            }
        } else {
            callback([]);
        }
    };

    const loadOptions = debounce(fetchLocationOptions, 200);

    const handleChange = (selectedOption) => {
        setFieldValue(
            name,
            selectedOption
                ? { name: selectedOption.value, meta: selectedOption.meta }
                : null
        );
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: 'transparent',
            border: 'none',
            boxShadow: 'none',
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: '#f7fafa',
        }),
        option: (provided) => ({
            ...provided,
            backgroundColor: '#f7fafa',
        }),
    };

    return (
        <div className="w-100 searchable-select">
            {label && (
                <label htmlFor={name} className="form-label">
                    {label}{" "}
                    {required && <span className="required-field">*</span>}
                </label>
            )}
            <AsyncSelect
                cacheOptions
                loadOptions={loadOptions}
                onChange={handleChange}
                placeholder={placeholder}
                className="form-control searchable-select"
                id={name}
                name={name}
                required={required}
                styles={customStyles}
                isClearable
                isSearchable
                defaultValue={defaultValue}
                value={meta.value ? {
                    value: meta.value.name,
                    label: meta.value.meta.display_name
                } : null}
            />
            {meta.touched && meta.error ? (
                <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                >
                    {meta.error}
                </div>
            ) : null}
        </div>
    );
};

export default LocationSearchSelect;