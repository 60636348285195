import { useState, useEffect } from "react";
import Typography from "../../../../../components/Typography/Typography";
import PageOption from "../../../../../components/sct/site_builder/PageOption";
import { PAGES } from "../../../../../constants/pages";

export default function ConfigurePages({
    onConfigurePage,
    onPageToggle,
    pageConfigurations = {},
    enabledPages,
}) {
    // State management
    const [selectedPages, setSelectedPages] = useState(() => {
        const defaultPages = {};
        PAGES.forEach((page) => {
            defaultPages[page.key] = page.mandatory || false;
        });
        return defaultPages;
    });

    // Side effects
    useEffect(() => {
        const updatedPages = {};

        // Set defaults based on mandatory status
        PAGES.forEach((page) => {
            updatedPages[page.key] = page.mandatory || false;
        });

        // Apply explicitly enabled pages from props
        if (enabledPages) {
            Object.keys(enabledPages).forEach((key) => {
                updatedPages[key] = !!enabledPages[key];
            });
        }

        // Handle special case mapping between about_us and about
        Object.keys(pageConfigurations).forEach((key) => {
            const config = pageConfigurations[key];
            const pageKey = key === "about_us" ? "about" : key;
            if (config && typeof config.enabled === "boolean") {
                updatedPages[pageKey] = config.enabled;
            }
        });

        setSelectedPages(updatedPages);
    }, [enabledPages, pageConfigurations]);

    // Helper functions
    const getPageStatus = (pageKey) => {
        const configKey = Object.keys(pageConfigurations).find((k) => {
            const configType = k.toLowerCase();
            const pageKeyLower = pageKey.toLowerCase();
            return (
                configType === pageKeyLower ||
                (pageKeyLower === "about" &&
                    (configType === "about_us" || configType === "about"))
            );
        });

        const config = configKey ? pageConfigurations[configKey] : null;
        const isMandatory =
            PAGES.find((p) => p.key === pageKey)?.mandatory || false;

        return {
            exists: config?.exists || false,
            enabled:
                config?.enabled !== undefined ? config.enabled : isMandatory,
            isConfigured: config?.exists || false,
            configDetails: config || {},
        };
    };

    // Event handlers
    const handleCheckboxChange = (pageKey) => (e) => {
        const isChecked = e.target.checked;

        setSelectedPages((prev) => ({
            ...prev,
            [pageKey]: isChecked,
        }));

        if (onPageToggle) {
            onPageToggle(pageKey, isChecked);
        }
    };

    // Render
    return (
        <div className="card p-3 border-0 rounded-3">
            <Typography variant="h3" className="pb-1 text-lg">
                Select and Configure Pages
            </Typography>

            <div className="alert alert-info py-2 mb-3">
                Page selection changes will be applied when you save the
                configuration. You can enable/disable any page regardless of its
                configuration status.
            </div>

            <div>
                {PAGES.map((page) => {
                    const pageStatus = getPageStatus(page.key);
                    const statusTooltip = pageStatus.isConfigured
                        ? `Last updated: ${pageStatus.configDetails.updated_at || "Recently"}`
                        : "This page needs to be configured";

                    return (
                        <PageOption
                            key={page.key}
                            name={`${page.key}Page`}
                            label={page.label}
                            checked={selectedPages[page.key]}
                            onChange={handleCheckboxChange(page.key)}
                            disabled={page.mandatory}
                            status={
                                pageStatus.isConfigured
                                    ? "configured"
                                    : "unconfigured"
                            }
                            statusTooltip={statusTooltip}
                            onConfigure={() => onConfigurePage(page.key)}
                            showConfigure={selectedPages[page.key]}
                        />
                    );
                })}
            </div>

            {Object.entries(selectedPages).map(([key, value]) => (
                <input
                    key={key}
                    type="hidden"
                    name={`enabledPages.${key}`}
                    value={value !== undefined ? value.toString() : "false"}
                />
            ))}
        </div>
    );
}
