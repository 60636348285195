import { createContext, useState } from "react";

export const ModalContext = createContext({
    modalTypes: [], // stack
    modalProps: {},
    showModal: () => {},
    hideModal: () => {},
    hideAllModals: () => {},
    setModalLoading: () => {},
    isModalLoading: false,
});

export default function ModalProvider({ children }) {
    const [modalTypes, setModalTypes] = useState([]);
    const [modalPropsByModalType, setModalPropsByModalType] = useState({});
    const [isModalLoading, setIsModalLoading] = useState(false);

    const showModal = ({ modalType, modalProps }) => {
        const modifiedModalProps = { ...modalPropsByModalType };
        const modifiedModalTypes = [...modalTypes];
        modifiedModalProps[modalType] = modalProps;
        modifiedModalTypes.push(modalType);
        setModalPropsByModalType(modifiedModalProps);
        setModalTypes(modifiedModalTypes);
    };

    const hideModal = () => {
        const modifiedModalTypes = [...modalTypes];
        const removedModalType = modifiedModalTypes.pop();
        
        const { [removedModalType]: _, ...modifiedModalProps } = modalPropsByModalType;
        
        setModalPropsByModalType(modifiedModalProps);
        setModalTypes(modifiedModalTypes);
        setIsModalLoading(false);
        return removedModalType;
    };

    const hideAllModals = () => {
        setModalPropsByModalType({});
        setModalTypes([]);
    };
    return (
        <ModalContext.Provider
            value={{
                modalTypes,
                modalProps: modalPropsByModalType,
                showModal,
                hideModal,
                hideAllModals,
                setModalLoading: setIsModalLoading,
                isModalLoading,
            }}
        >
            {children}
        </ModalContext.Provider>
    );
}
