import Typography from "../../../../../../components/Typography/Typography";
import Button from "../../../../../../components/Button/Button";
import { FieldArray } from "formik";
import StandardItem from "./StandardItem";

const Standards = ({ standardImages, handleStandardImageselected }) => {
    return (
        <div className="mb-4">
            <Typography variant="h5" className="mb-3">
                Standards (Optional)
            </Typography>
            <FieldArray name="standards">
                {({ push, remove, form }) => (
                    <div>
                        {form.values.standards?.map((standard, index) => (
                            <StandardItem
                                key={index}
                                index={index}
                                standard={standard}
                                standardImage={standardImages[index]}
                                handleStandardImageselected={(file) =>
                                    handleStandardImageselected(file, index)
                                }
                                onRemove={() => remove(index)}
                            />
                        ))}
                        <Button
                            variant="secondary"
                            type="button"
                            onClick={() =>
                                push({
                                    standard_title: "",
                                    standard_image: null,
                                    standard_description: "",
                                })
                            }
                            startIcon={<i className="bi bi-plus" />}
                            size="sm"
                        >
                            Add Standard
                        </Button>
                    </div>
                )}
            </FieldArray>
        </div>
    );
};

export default Standards;
