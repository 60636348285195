import "./accordion.scss";
import Map from "../Map/Map";

const DetailRow = ({ label, value }) => (
    <div className="info-row">
        <h6 className="info-label">{label}</h6>
        <p className="info-value">{value}</p>
    </div>
);

const FacilityDetail = ({ facilityData }) => (
    <div className="container mt-5">
        <div className="row d-flex align-items-center">
            <div className="col-6 p-0">
                <div className="facility-map">
                    <Map locationData={facilityData.locationMetaData} />
                </div>
            </div>
            <div className="col-6 ps-4">
                <h5 className="facility-title">{facilityData.facilityName}</h5>
                <div className="facility-info">
                    {facilityData.details.map((detail, index) => (
                        <DetailRow key={index} {...detail} />
                    ))}
                </div>
            </div>
        </div>
    </div>
);

export default FacilityDetail;
