/**
 * Page configuration definitions for the site builder
 * When adding a new page, just add a new entry to this object
 */
export const PAGE_CONFIGS = {
    design: {
        id: "design",
        title: "Design Configuration",
        required: true,
        configType: "DESIGN",
        default: true,
    },
    home: {
        id: "home",
        title: "Home Page Configuration",
        required: true,
        configType: "HOME",
    },
    trace: {
        id: "trace",
        title: "Trace Page Configuration",
        required: true,
        configType: "TRACE",
    },
    about: {
        id: "about",
        title: "About Us Configuration",
        required: false,
        configType: "ABOUT_US",
    },
    certification: {
        id: "certification",
        title: "Certifications and Standards Configuration",
        required: false,
        configType: "CERTIFICATION",
    },
};

// Default active view
export const DEFAULT_VIEW = "design";
