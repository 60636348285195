import React, { useState } from "react";

export default function SearchableInput(props) {
    const { name, label, options, onSelect, ...rest } = props;
    const [inputValue, setInputValue] = useState("");
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [isFocused, setIsFocused] = useState(false);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
        setFilteredOptions(
            options.filter((option) =>
                option.label
                    .toLowerCase()
                    .includes(event.target.value.toLowerCase()),
            ),
        );
    };

    const handleOptionClick = (option) => {
        onSelect(option);
        setInputValue("");
        setFilteredOptions(options);
    };

    const handleFocus = () => {
        setIsFocused(true);
        setFilteredOptions(options);
    };

    const handleBlur = () => {
        // Small delay to allow option click to register
        setTimeout(() => setIsFocused(false), 200);
    };

    return (
        <>
            <div className="search-input">
                <input
                    className="form-control"
                    name={name}
                    id={name}
                    value={inputValue}
                    onChange={handleInputChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder={label}
                    {...rest}
                />
                <i className="bi bi-search search-icon"></i>
            </div>
            {(isFocused || inputValue) && (
                <ul className="list-group">
                    {filteredOptions.map((option, index) => (
                        <li
                            key={index}
                            className="list-group-item cursor-pointer"
                            onClick={() => handleOptionClick(option)}
                        >
                            {option.label}
                        </li>
                    ))}
                </ul>
            )}
        </>
    );
}
