import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../../components/LoadingSkeleton/Loading";
import Typography from "../../../components/Typography/Typography";
import { PAGE_CONFIGS } from "../../../constants/pageConfigs";
import { usePageNavigation } from "../../../hooks/usePageNavigation";
import { useGetAllSiteConfigurationQuery } from "../../../services/supplyChainService";
import designTemplate from "./Configurations/DesignPage/template.json";
import homeTemplate from "./Configurations/HomePage/template.json";
import aboutTemplate from "./Configurations/AboutPage/template.json";
import traceTemplate from "./Configurations/TracePage/template.json";
import certificationTemplate from "./Configurations/CertificationPage/template.json";
import DesignPageController from "./Configurations/DesignPage";
import HomePageController from "./Configurations/HomePage";
import TracePageController from "./Configurations/TracePage";
import AboutPageController from "./Configurations/AboutPage";
import CertificationPageController from "./Configurations/CertificationPage";

export default function SiteBuilder() {
    // URL Parameters and Navigation
    const { id: projectId } = useParams();
    const { activeView, navigateToPage } = usePageNavigation();

    // API hooks
    const { data: allConfigurations, isLoading: isLoadingAll } =
        useGetAllSiteConfigurationQuery(projectId);

    // Configuration IDs
    const [designConfigId, setDesignConfigId] = useState(null);
    const [homeConfigId, setHomeConfigId] = useState(null);
    const [aboutConfigId, setAboutConfigId] = useState(null);
    const [traceConfigId, setTraceConfigId] = useState(null);
    const [certificationConfigId, setCertificationConfigId] = useState(null);

    // Page Configuration States
    const [pageConfigurations, setPageConfigurations] = useState({
        home: { id: null, exists: false, enabled: true, required: true },
        trace: { id: null, exists: false, enabled: true, required: true },
        about: { id: null, exists: false, enabled: false, required: false },
        certification: {
            id: null,
            exists: false,
            enabled: false,
            required: false,
        },
    });

    // Form Initial Values
    const [designInitialValues, setDesignInitialValues] = useState({
        ...designTemplate.config_data,
    });
    const [homeInitialValues, setHomeInitialValues] = useState({
        ...homeTemplate.config_data,
    });
    const [aboutInitialValues, setAboutInitialValues] = useState({
        ...aboutTemplate.config_data,
    });
    const [traceInitialValues, setTraceInitialValues] = useState({
        ...traceTemplate.config_data,
    });

    const [certificationInitialValues, setCertificationInitialValues] =
        useState({ ...certificationTemplate.config_data });

    // Used to force re-render of form components
    const formKey = useRef(0);

    // Update state when configurations are loaded
    useEffect(() => {
        if (!allConfigurations?.data) return;

        const configStatus = { ...pageConfigurations };

        // Process design configuration
        const designConfig = allConfigurations.data.find(
            (config) => config.config_type === "DESIGN",
        );
        if (designConfig) {
            setDesignConfigId(designConfig.id);
            setDesignInitialValues({
                ...designConfig.config_data,
            });
        }

        // Process home configuration
        const homeConfig = allConfigurations.data.find(
            (config) => config.config_type === "HOME",
        );
        if (homeConfig) {
            setHomeConfigId(homeConfig.id);
            setHomeInitialValues({
                ...homeConfig.config_data,
            });
            configStatus.home = {
                id: homeConfig.id,
                exists: true,
                enabled:
                    homeConfig.enabled !== undefined
                        ? homeConfig.enabled
                        : true,
                required:
                    homeConfig.required !== undefined
                        ? homeConfig.required
                        : true,
                updated_at: homeConfig.updated_at,
            };
        }

        // Process about configuration
        const aboutConfig = allConfigurations.data.find(
            (config) => config.config_type === "ABOUT_US",
        );
        if (aboutConfig) {
            setAboutConfigId(aboutConfig.id);
            setAboutInitialValues({
                ...aboutConfig.config_data,
            });
            configStatus.about = {
                id: aboutConfig.id,
                exists: true,
                enabled:
                    aboutConfig.enabled !== undefined
                        ? aboutConfig.enabled
                        : false,
                required:
                    aboutConfig.required !== undefined
                        ? aboutConfig.required
                        : false,
            };
        } else {
            configStatus.about = {
                id: null,
                exists: false,
                enabled: false,
                required: false,
            };
        }

        // Process trace configuration
        const traceConfig = allConfigurations.data.find(
            (config) => config.config_type === "TRACE",
        );
        if (traceConfig) {
            setTraceConfigId(traceConfig.id);
            setTraceInitialValues({
                ...traceConfig.config_data,
            });
            configStatus.trace = {
                id: traceConfig.id,
                exists: true,
                enabled:
                    traceConfig.enabled !== undefined
                        ? traceConfig.enabled
                        : true,
                required:
                    traceConfig.required !== undefined
                        ? traceConfig.required
                        : true,
                updated_at: traceConfig.updated_at,
            };
        }

        // Process certification configuration
        const certificationConfig = allConfigurations.data.find(
            (config) => config.config_type === "CERTIFICATION",
        );
        if (certificationConfig) {
            setCertificationConfigId(certificationConfig.id);
            setCertificationInitialValues({
                ...certificationConfig.config_data,
            });
            configStatus.certification = {
                id: certificationConfig.id,
                exists: true,
                enabled:
                    certificationConfig.enabled !== undefined
                        ? certificationConfig.enabled
                        : false,
                required:
                    certificationConfig.required !== undefined
                        ? certificationConfig.required
                        : false,
            };
        } else {
            configStatus.certification = {
                id: null,
                exists: false,
                enabled: false,
                required: false,
            };
        }

        setPageConfigurations(configStatus);
        formKey.current += 1;
    }, [allConfigurations]);

    // Render content based on active view
    const renderContent = () => {
        if (isLoadingAll) {
            return (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "100vh" }}
                >
                    <Loading />
                </div>
            );
        }

        if (activeView === "design") {
            return (
                <div className="mb-3">
                    <Typography variant="h3" className="mb-3">
                        {PAGE_CONFIGS.design.title}
                    </Typography>
                    <DesignPageController
                        key={`design-config-${formKey.current}`}
                        projectId={projectId}
                        allConfigurations={allConfigurations}
                        isLoading={isLoadingAll}
                        navigateToPage={navigateToPage}
                        configId={designConfigId}
                        setConfigId={setDesignConfigId}
                        initialValues={designInitialValues}
                        setInitialValues={setDesignInitialValues}
                        pageConfigurations={pageConfigurations}
                        setPageConfigurations={setPageConfigurations}
                    />
                </div>
            );
        } else if (activeView === "home") {
            return (
                <HomePageController
                    key={`home-config-${formKey.current}`}
                    projectId={projectId}
                    allConfigurations={allConfigurations}
                    isLoading={isLoadingAll}
                    configId={homeConfigId}
                    setConfigId={setHomeConfigId}
                    initialValues={homeInitialValues}
                    setInitialValues={setHomeInitialValues}
                    pageConfigurations={pageConfigurations}
                    setPageConfigurations={setPageConfigurations}
                />
            );
        } else if (activeView === "about") {
            return (
                <AboutPageController
                    key={`about-config-${formKey.current}`}
                    projectId={projectId}
                    configId={aboutConfigId}
                    setConfigId={setAboutConfigId}
                    initialValues={aboutInitialValues}
                    setInitialValues={setAboutInitialValues}
                    pageConfigurations={pageConfigurations}
                    setPageConfigurations={setPageConfigurations}
                    isLoading={isLoadingAll}
                    allConfigurations={allConfigurations}
                />
            );
        } else if (activeView === "trace") {
            return (
                <TracePageController
                    key={`trace-config-${formKey.current}`}
                    projectId={projectId}
                    configId={traceConfigId}
                    setConfigId={setTraceConfigId}
                    initialValues={traceInitialValues}
                    setInitialValues={setTraceInitialValues}
                    pageConfigurations={pageConfigurations}
                    setPageConfigurations={setPageConfigurations}
                    isLoading={isLoadingAll}
                    allConfigurations={allConfigurations}
                />
            );
        } else if (activeView === "certification") {
            return (
                <CertificationPageController
                    key={`certification-config-${formKey.current}`}
                    projectId={projectId}
                    allConfigurations={allConfigurations}
                    isLoading={isLoadingAll}
                    configId={certificationConfigId}
                    setConfigId={setCertificationConfigId}
                    initialValues={certificationInitialValues}
                    setInitialValues={setCertificationInitialValues}
                    pageConfigurations={pageConfigurations}
                    setPageConfigurations={setPageConfigurations}
                />
            );
        }

        return null;
    };

    return <div className="container-fluid px-0">{renderContent()}</div>;
}
