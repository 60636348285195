import Typography from "../../../components/Typography/Typography";
import { BasicTable } from "../../../components/tables";
import { useGetPaymentHistoryQuery } from "../../../services/billingService";
import { useGetTenantQuery } from "../../../services/tenantService";
import Loading from "../../../components/LoadingSkeleton/Loading";
import PricingComponent from "./PricingComponent";

export default function BillingPage() {
    const { data: paymentHistory } = useGetPaymentHistoryQuery();
    const paymentData = paymentHistory?.data;
    const { data: tenantData } = useGetTenantQuery();
    const currentTier = tenantData?.data?.current_tier;
    if (!paymentData || !tenantData) {
        return (
            <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100vh" }}
            >
                <Loading />
            </div>
        );
    }

    return (
        <div className="container-fluid project-container h-100 min-vh-100 w-100 m-0 p-0 mb-3">
            <div className="text-container">
                <div className="d-flex flex-column flex-md-row justify-content-center align-items-center pt-3 pb-2 mb-3 d-md-flex justify-content-md-between">
                    <Typography
                        className="ps-md-4 ps-0 text-md-start text-center mb-md-0"
                        variant="h1"
                    >
                        Billing
                    </Typography>
                </div>
                <div className="m-4 mb-2 divider-line"></div>
            </div>

            <div className="mx-3 row d-flex flex-wrap">
                {/* Pricing Component replaces Credit Usage and Subscription sections */}
                <div className="col-12 my-2">
                    <div className="mb-3">
                        <Typography variant="body1">
                            Manage your plan and billing history here
                        </Typography>
                    </div>
                    <PricingComponent
                        currentTier={currentTier}
                        isTierActive={tenantData?.data?.is_tier_active}
                    />
                </div>

                <div className="col-12 col-md-12 pt-3">
                    <div>
                        <Typography variant="h3">Invoices</Typography>
                    </div>
                    <BasicTable
                        data={paymentData}
                        columns={[
                            {
                                header: "Date",
                                accessor: "created",
                            },
                            {
                                header: "Amount",
                                accessor: "amount",
                            },
                            {
                                header: "Status",
                                accessor: "status",
                                Cell: ({ value }) => (
                                    <div>
                                        {value.charAt(0).toUpperCase() +
                                            value.slice(1)}
                                    </div>
                                ),
                            },
                        ]}
                        isSortable={false}
                        isPaginated={paymentData?.length > 8}
                        pageSize={8}
                        currentPage={0}
                        showCheckbox={false}
                    />
                </div>
            </div>
        </div>
    );
}
