import ModalContainer from "./components/modals/ModalContainer";
import "./custom.scss";
import { routesConfig } from "./config/routesConfig";
import RoutesRender from "./components/routing/RoutesRender";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useJune } from "./hooks/juneHook";
import { useEffect } from "react";
import { useLoginStatus } from "./hooks/authHooks";
import { useDispatch, useSelector } from "react-redux";
import { useLazyProfileQuery } from "./services/userService";
import { authActions } from "./services/authSlice";
import * as amplitude from '@amplitude/analytics-browser';
import { initAmplitude, setUserProperties } from './utils/amplitudeUtlis';

// initAmplitude();

function App() {
	const analytics = useJune('vMs7XFIikIMXHaJ3');
	const dispatch = useDispatch();
	const [getProfile] = useLazyProfileQuery();
	const isLoggedIn = useLoginStatus();
	const user = useSelector(state => state.auth.user);

	useEffect(() => {
		init();
	}, [])

	useEffect(() => {
		if (user && analytics) {
			analytics.identify(user.username, {
				email: user.username,
				name: user.first_name
			})
			analytics.track("App load")
			analytics.group(user.tenant)
			setUserProperties(user?.username);
			// amplitude.setUserId(user.username);
		}
	}, [user, analytics]);

	// Auth check and dispatch
	const init = async () => {
		if (isLoggedIn) {
			const profile = await getProfile();

			if (profile.data?.status === "OK") {
				dispatch(authActions.login(profile.data.data));
			}
		}
	}


	return (
		<div className="App">
			<ModalContainer />
			<RoutesRender routes={routesConfig} />
			<ToastContainer style={{ zIndex: 10001 }}/>		
		</div>
	);
}

export default App;
