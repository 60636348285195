import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Fix for default marker icon
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default function Map({ locationData }) {
    if (!locationData?.lat || !locationData?.lon) return null;

    const center = [parseFloat(locationData.lat), parseFloat(locationData.lon)];

    return (
        <MapContainer
            center={center}
            zoom={14}
            style={{ height: "300px", width: "100%", borderRadius: "8px" }}
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            />
            <Marker position={center}>
                <Popup>{locationData.name}</Popup>
            </Marker>
        </MapContainer>
    );
}
