import Typography from "../../../../../../components/Typography/Typography";
import Button from "../../../../../../components/Button/Button";
import { FieldArray } from "formik";
import QualityItem from "./QualityItem";

const QualityParameter = () => {
    return (
        <div className="mb-4">
            <Typography variant="h5" className="mb-3">
                Quality Parameters (Optional)
            </Typography>
            <FieldArray name="qualities">
                {({ push, remove, form }) => (
                    <div>
                        {form.values.qualities?.map((quality, index) => (
                            <QualityItem
                                key={index}
                                index={index}
                                quality={quality}
                                onRemove={() => remove(index)}
                            />
                        ))}
                        <Button
                            variant="secondary"
                            type="button"
                            onClick={() =>
                                push({
                                    quality_title: "",
                                    quality_description: null,
                                    quality_icon: "",
                                })
                            }
                            startIcon={<i className="bi bi-plus" />}
                            size="sm"
                        >
                            Add Parameter
                        </Button>
                    </div>
                )}
            </FieldArray>
        </div>
    );
};

export default QualityParameter;
