import React, { useRef, useEffect } from "react";
import Typography from "../../../../../../components/Typography/Typography";
import Button from "../../../../../../components/Button/Button";
import TextField from "../../../../../../components/forms/TextField";
import TextArea from "../../../../../../components/forms/TextArea";
import UnifiedFileUpload from "../../../../../../components/forms/UnifiedFileUpload";

const StoryItem = ({
    index,
    story,
    storyImage,
    handleStoryImageSelected,
    onRemove,
}) => {
    const fileUploadRef = useRef(null);

    // Ensure the parent component gets notified when file is removed internally
    const handleFileChange = (file) => {
        handleStoryImageSelected(file, index);
    };

    return (
        <div className="card mb-4">
            <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <Typography variant="h6">Story {index + 1}</Typography>
                    {index > 0 && (
                        <Button
                            type="button"
                            variant="danger"
                            size="sm"
                            fullWidth={false}
                            onClick={onRemove}
                        >
                            <i className="bi bi-x-lg" />
                        </Button>
                    )}
                </div>
                <div className="mb-3">
                    <TextField
                        name={`stories.${index}.story_title`}
                        label="Story Title"
                    />
                </div>
                <div className="mb-3">
                    <UnifiedFileUpload
                        ref={fileUploadRef}
                        name={`stories.${index}.story_image`}
                        label="Story Image"
                        fileType="image"
                        initialFile={
                            story.story_image_file_url
                                ? {
                                      url: story.story_image_file_url,
                                      name: story.story_image_file_name,
                                      isExisting: true,
                                  }
                                : null
                        }
                        selectedFile={storyImage}
                        onFileSelected={handleFileChange}
                    />
                </div>
                <div className="mb-3">
                    <TextArea
                        name={`stories.${index}.story_content`}
                        label="Story Content"
                        rows={3}
                    />
                </div>
            </div>
        </div>
    );
};

export default StoryItem;
