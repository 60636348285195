import React from "react";
import "./card.scss";

export default function ProjectDashboardCard({ name, value, style, variant }) {
    const cardStyle =
        variant === "main"
            ? "transparent-card"
            : variant === "summary"
              ? "summary-card"
              : "dashboard-card";

    return (
        <div className={`card w-100 ${cardStyle}`} style={style}>
            <div className="card-body">
                <div className="card-title">
                    <div>
                        <h6
                            className={`card-subtitle name-text ${variant === "summary" ? "summary-title text-base" : "mb-2 m-3"}`}
                        >
                            {name}
                        </h6>
                        <span>
                            <h2>
                                <span
                                    className={`number-text ${variant === "summary" ? "summary-value text-xl" : "p-3"}`}
                                >
                                    {value}
                                </span>
                            </h2>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
