import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useModal } from "../../hooks/modalsHooks";
import "./model.scss";
import ModalLayout from "../modals/ModalLayout";
import QRCode from "react-qr-code";
import { logEvent } from "../../utils/amplitudeUtlis";
import Typography from "../Typography/Typography";
import Button from "../Button/Button";

export default function TraceAssetModal({
    assetLink,
    assetId,
    projectId,
    showSiteConfigWarning = false,
}) {
    const { hideModal } = useModal();
    const qrCodeRef = useRef(null);
    const navigate = useNavigate();
    const [isDownloading, setIsDownloading] = useState(false);

    const qrLink = assetLink;

    const handleClose = () => {
        hideModal();
        logEvent({
            eventName: "cancel_button_clicked",
            eventProperties: {
                page_name: "Trace Asset Modal Page",
            },
        });
    };

    const handleViewTrace = () => {
        window.open(qrLink, "_blank");
    };

    const handleNavigateToSiteBuilder = () => {
        hideModal();
        navigate(`/overview/${projectId}/site-builder`);
        setTimeout(() => {
            window.location.reload();
        }, 100);
    };

    const handleDownloadQR = () => {
        setIsDownloading(true);
        // Extract asset ID from the URL for the filename
        // This handles both internal and external trace URLs
        let fileAssetId;
        try {
            const url = new URL(qrLink);
            const pathParts = url.pathname.split("/");
            fileAssetId = pathParts[pathParts.length - 1] || "asset";
        } catch (e) {
            const pathParts = qrLink.split("/");
            fileAssetId = pathParts[pathParts.length - 1] || "asset";
        }

        try {
            const svg = qrCodeRef.current.querySelector("svg");
            const svgData = new XMLSerializer().serializeToString(svg);
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            const img = new Image();

            img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0);
                const pngFile = canvas.toDataURL("image/png");
                const downloadLink = document.createElement("a");
                downloadLink.href = pngFile;
                downloadLink.download = `trace-${fileAssetId}.png`;
                downloadLink.click();
                setIsDownloading(false);
            };

            img.onerror = () => {
                console.error("Error loading QR code image");
                setIsDownloading(false);
            };

            // Use safer encoding for SVG data with Unicode support
            img.src =
                "data:image/svg+xml;base64," +
                btoa(unescape(encodeURIComponent(svgData)));
        } catch (error) {
            console.error("Error downloading QR code:", error);
            setIsDownloading(false);
        }
    };

    return (
        <ModalLayout width="100%" className="overflow-auto">
            <div className="card shadow border-0 rounded-4 d-flex justify-content-center p-4">
                <ModalLayout.Header>
                    <div className="text-center pb-3 border-bottom mb-3">
                        <h4 className="fw-bold mb-0">
                            {showSiteConfigWarning
                                ? "Site Configuration Required"
                                : "View Record Trace"}
                        </h4>
                    </div>
                </ModalLayout.Header>
                <ModalLayout.Body>
                    {showSiteConfigWarning ? (
                        <div className="alert alert-warning border-start border-warning shadow-sm">
                            <Typography
                                variant="body1"
                                className="mb-2 fw-semibold"
                            >
                                <i className="bi bi-exclamation-triangle-fill me-2"></i>
                                No site configurations found
                            </Typography>
                            <Typography
                                variant="body2"
                                className="text-secondary"
                            >
                                Please configure the site in Site Builder before
                                viewing the trace.
                            </Typography>
                        </div>
                    ) : (
                        <div className="d-flex flex-column align-items-center justify-content-center py-3">
                            <div
                                ref={qrCodeRef}
                                className="p-3 bg-white rounded-3 shadow-sm border"
                                style={{ maxWidth: "300px" }}
                            >
                                <QRCode
                                    value={qrLink}
                                    size={256}
                                    fgColor="#212529"
                                    style={{ width: "100%", height: "auto" }}
                                />
                            </div>
                            <div className="mt-3 text-center text-secondary">
                                <small>
                                    Scan this QR code to access the asset trace
                                </small>
                            </div>
                        </div>
                    )}
                </ModalLayout.Body>
                <ModalLayout.Footer>
                    <div className="d-flex justify-content-center flex-row align-items-center gap-2 mt-2">
                        {showSiteConfigWarning ? (
                            <>
                                <Button
                                    variant="primary"
                                    className="py-2 px-4"
                                    onClick={handleNavigateToSiteBuilder}
                                    fullWidth={false}
                                >
                                    Go to Site Builder
                                </Button>
                                <Button
                                    variant="secondary"
                                    className="py-2 px-3"
                                    onClick={handleClose}
                                    fullWidth={false}
                                >
                                    Cancel
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    variant="primary"
                                    className="py-2 px-4 "
                                    onClick={handleDownloadQR}
                                    disabled={isDownloading}
                                    isLoading={isDownloading}
                                    loadingText="Downloading..."
                                    fullWidth={false}
                                >
                                    Download QR
                                </Button>
                                <Button
                                    variant="primary"
                                    className="py-2 px-4 "
                                    onClick={handleViewTrace}
                                    fullWidth={false}
                                >
                                    View Trace
                                </Button>
                                <Button
                                    variant="secondary"
                                    className="py-2 px-3 "
                                    onClick={handleClose}
                                    fullWidth={false}
                                >
                                    Cancel
                                </Button>
                            </>
                        )}
                    </div>
                </ModalLayout.Footer>
            </div>
        </ModalLayout>
    );
}
