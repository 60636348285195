import Typography from "../../../../../components/Typography/Typography";
import Accordion from "../Accordion/Accordion";

export default function JourneyOfSection({ data, assetId, projectName }) {
    return (
        <div className="container p-0 px-3 py-3 px-lg-0">
            <div className="pt-2 d-flex flex-column justify-content-center align-items-center">
                <Typography variant="h1" color="#084133" className="m-0 pb-2">
                    {projectName}
                </Typography>
                <Typography variant="h2" className="m-0">
                    {assetId}
                </Typography>
                <Typography
                    variant="subtitle"
                    className="pb-2 pt-3 text-center px-2"
                >
                    The following trace data shows the production process of the
                    selected process, through the different nodes of the
                    journey.
                </Typography>
            </div>
            <div className="pt-2 ps-3 ps-md-0 d-flex justify-content-center"></div>

            <div className="mx-3">
                <div className="py-2">
                    <Accordion items={data} isLanding={false} />
                </div>
            </div>
        </div>
    );
}
