import React from "react";
import "./accordion.scss";
import { useDownloadFileQuery } from "../../../../../services/fileManagementService";

const InfoItem = ({ iconSrc, title, value, url, type }) => {
    const { data: fileUrlResponse } = useDownloadFileQuery(
        { fileId: value },
        { skip: type !== "file" && type !== "image" },
    );

    const getFileUrl = (response) => {
        if (!response) return "";
        return response.data?.file || "";
    };

    const displayUrl = getFileUrl(fileUrlResponse);

    return (
        <div className="info-item">
            <div className="icon">
                {iconSrc ? (
                    <img src={iconSrc} alt={title} className="rounded-2" />
                ) : (
                    <i className="bi bi-info-circle"></i>
                )}
            </div>
            <div>
                <div className="info-item-title">{title}</div>
                <div className="info-item-subtitle">
                    {type === "file" || type === "image" ? (
                        <a
                            href={displayUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="file-link"
                        >
                            {fileUrlResponse?.data?.file_name || displayUrl}
                        </a>
                    ) : typeof value === "string" ? (
                        value
                    ) : (
                        JSON.stringify(value)
                    )}
                </div>
            </div>
        </div>
    );
};

const ProcessDetail = ({ data = [] }) => {
    return (
        <div className="info-grid">
            {Array.isArray(data) &&
                data.map((item, index) => {
                    return (
                        <InfoItem
                            key={index}
                            iconSrc={item.iconSrc}
                            title={item.title}
                            value={item.value}
                            url={item.url}
                            type={item.type}
                            fileUrl={item.fileUrl}
                            fileName={item.fileName}
                        />
                    );
                })}
        </div>
    );
};

export default ProcessDetail;
