import React, { useState } from "react";
import Typography from "../../../../components/Typography/Typography";
import { useGetAssetTypesVisualizationGraphQuery } from "../../../../services/supplyChainService";
import { useParams } from "react-router-dom";
import Loading from "../../../../components/LoadingSkeleton/Loading";
import Flow from "../../../../components/Flow";

export default function MaterialVisualization({
    containerWidth,
    containerHeight,
    defaultCollapsed = false,
}) {
    const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);
    const { id } = useParams();
    const { data: graphResponse, isLoading: isLoadingGraph } =
        useGetAssetTypesVisualizationGraphQuery(id, { skip: !id });

    if (isLoadingGraph) {
        return (
            <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100vh" }}
            >
                <Loading />
            </div>
        );
    }

    if (
        !graphResponse ||
        !graphResponse.data ||
        !graphResponse.data.nodes ||
        !graphResponse.data.nodes.length
    ) {
        return null;
    }

    return (
        <div
            className="mb-4 w-100"
            style={{ height: isCollapsed ? "auto" : "100%" }}
        >
            <div
                className="card p-3 border-0 rounded-3 w-100"
                style={{ height: isCollapsed ? "auto" : "100%" }}
            >
                <div
                    className={`d-flex align-items-center justify-content-between ${isCollapsed ? "" : "pb-3"}`}
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    style={{ cursor: 'pointer' }}
                >
                    <Typography variant="h3" className="text-lg mb-0">
                        Supply Chain Visualization
                    </Typography>
                    <button
                        className="btn btn-link p-0 text-dark"
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsCollapsed(!isCollapsed);
                        }}
                        title={isCollapsed ? "Expand" : "Collapse"}
                    >
                        {isCollapsed ? (
                            <i
                                className="bi bi-caret-down-fill"
                                style={{ color: "black" }}
                            ></i>
                        ) : (
                            <i
                                className="bi bi-caret-up-fill"
                                style={{ color: "black" }}
                            ></i>
                        )}
                    </button>
                </div>

                {!isCollapsed && (
                    <div className="w-100 h-100 bg-white rounded-3 shadow-sm">
                        <Flow
                            initialNodes={graphResponse.data.nodes}
                            initialEdges={graphResponse.data.links}
                            containerWidth={containerWidth || "100%"}
                            containerHeight={containerHeight || "500px"}
                            layoutDirrection="TB"
                            displayPanels={true}
                            displayMiniMap={false}
                            displayControls={true}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
