import { useEffect, useState, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import JourneyOfSection from "./components/ JourneyOfSection";
import { useLazyGetTraceAssetQuery } from "../../../services/supplyChainService";
import Loading from "../../../components/LoadingSkeleton/Loading";
import { formatDateAsDDMMYYYY } from "../../../utils/projectUtils";
import "./journeyPage.scss";
import { getExplorerLink } from "../../../web3/helpers/utils";
import Footer from "../../../components/traceApp/Footer/Footer";

const JourneyPage = () => {
    // State variables
    const { projectId, assetId } = useParams();
    const [journeyData, setJourneyData] = useState(null);
    const [productName, setProductName] = useState("Trace Product");
    const [projectName, setProjectName] = useState(null);

    // API hook
    const [getTrace, results] = useLazyGetTraceAssetQuery();

    // Function to handle trace asset retrieval
    const handleTraceAsset = useCallback(async () => {
        try {
            const trace = await getTrace({
                id: projectId,
                asset_id: assetId,
            });

            if (trace.error) {
                throw new Error("Error in trace");
            }

            const processedData = await processTraceData(trace.data.data.trace);
            setJourneyData(processedData.journeyData);
            setProductName(processedData.productName);
            setProjectName(processedData.projectName);
        } catch (error) {
            console.error(error);
        }
    }, [getTrace, projectId, assetId]);

    // Effect hook
    useEffect(() => {
        handleTraceAsset();
    }, [projectId, assetId, handleTraceAsset]);

    // Create a memoized function to get explorer URL
    const getExplorerUrlMemoized = useMemo(() => {
        return (chain) => getExplorerLink(chain);
    }, []);

    // Function to process trace data
    const processTraceData = (data) => {
        let journeyData = [];
        let productName = "Trace Product";
        let projectName = null;

        for (const key in data) {
            for (const asset of data[key] || []) {
                const processDetails =
                    asset.form_data?.map((form) => {
                        if (form.type === "group") {
                            return {
                                iconSrc: "",
                                title: form.label,
                                value: Array.isArray(form.value)
                                    ? form.value
                                          .flat()
                                          .map((item) => {
                                              if (
                                                  item.type === "image" ||
                                                  item.type === "file"
                                              ) {
                                                  return {
                                                      label: item.label,
                                                      value: item.value,
                                                      type: item.type,
                                                  };
                                              }
                                              if (item.type === "date") {
                                                  return `${item.label}: ${formatDateAsDDMMYYYY(item.value)}`;
                                              }
                                              return `${item.label}: ${item.value}`;
                                          })
                                          .join(", ")
                                    : "",
                                isGroupWithFiles:
                                    Array.isArray(form.value) &&
                                    form.value
                                        .flat()
                                        .some(
                                            (item) =>
                                                item.type === "image" ||
                                                item.type === "file",
                                        ),
                            };
                        } else {
                            return {
                                iconSrc: "",
                                title: form.label || form.name,
                                value:
                                    form.type === "date"
                                        ? formatDateAsDDMMYYYY(form.value)
                                        : form.value,
                                type: form.type,
                            };
                        }
                    }) || [];

                // Safely access nested properties with optional chaining and default values
                const assetType = asset?.asset_type || {};
                const node = assetType?.node || {};
                const locationMetaData = node?.location_meta_data || {};
                const unit = asset?.unit || {};

                journeyData.push({
                    image:
                        node?.promo_image || "/images/image_not_available.svg",
                    createdAt: asset?.created_at || new Date().toISOString(),
                    overview: {
                        processName: assetType?.type_name || "",
                        location: locationMetaData?.name || "",
                        date: asset?.created_at
                            ? formatDateAsDDMMYYYY(asset.created_at)
                            : "",
                        quantity: `${asset?.qty || 0} ${unit?.symbol || ""}`,
                        referenceId: asset?.asset_id || "",
                    },
                    processDetails: processDetails,
                    facilityData: {
                        facilityName: node?.name || "",
                        locationMetaData: locationMetaData,
                        details: [
                            {
                                label: "Description",
                                value: node?.description || "",
                            },
                            {
                                label: "Material Type",
                                value: assetType?.material_type || "",
                            },
                        ],
                    },
                    rawFormData: asset?.form_data || [],
                    // Blockchain data with safe access
                    token_id: asset?.token_id || "",
                    transaction_hash: asset?.transaction_hash || "",
                    contract_address: asset?.contract_address || "",
                    chain: asset?.chain || "",
                    explorer_url: getExplorerUrlMemoized(asset?.chain || ""),
                });
            }
        }

        journeyData.sort(
            (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
        );

        if (data[0] && data[0][0]) {
            productName = data[0][0]["asset_id"];
            projectName = data[0][0]?.supply_chain?.project?.name || null;
        }

        return { journeyData, productName, projectName };
    };

    // Render loading state
    if (!journeyData) {
        return (
            <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100vh" }}
            >
                <Loading />
            </div>
        );
    }

    // Render main content
    return (
        <div className="journey-wrapper">
            <div className="sections-container">
                <section className="section-spacing">
                    <JourneyOfSection
                        data={journeyData}
                        assetId={productName}
                        projectName={projectName}
                    />
                </section>
                <Footer />
            </div>
        </div>
    );
};

export default JourneyPage;
