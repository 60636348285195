import dagre from "@dagrejs/dagre";
const dagreGraph = new dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
const getLayoutedElements = (
    nodes,
    edges,
    nodeWidth,
    nodeHeight,
    direction = "TB",
) => {
    const isHorizontal = direction === "LR";
    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node) => {
        dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    const newNodes = nodes.map((node) => {
        const nodeWithPosition = dagreGraph.node(node.id);
        const isFixedPosition =
            node?.position?.fixed === true ||
            node?.data?.label === "Material 1";
        const newNode = {
            ...node,
            height: nodeHeight,
            width: nodeWidth,
            targetPosition: isHorizontal ? "left" : "top",
            sourcePosition: isHorizontal ? "right" : "bottom",
            // We are shifting the dagre node position (anchor=center center) to the top left
            // so it matches the React Flow node anchor point (top left).
            position: {
                x: isFixedPosition
                    ? nodeWithPosition.x
                    : nodeWithPosition.x - nodeWidth / 2,
                y: isFixedPosition
                    ? nodeWithPosition.y
                    : nodeWithPosition.y - nodeHeight / 2,
                fixed: false, // We need to set this to false after the first render to allow the user to move the node
            },
            style: node.style || {
                background: node.cstyle?.background || "rgb(96, 125, 139)",
                color: node.cstyle?.color || "#fff",
                border: "1px solid #000",
                textAlign: "center",
                borderRadius: "10px",
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            },
        };

        return newNode;
    });

    const newEdges = edges.map((edge) => {
        const newEdge = {
            ...edge,
           animated: true,
        };

        return newEdge;
    });
    return { nodes: newNodes, edges: newEdges };
};

export default getLayoutedElements;
