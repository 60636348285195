import React from "react";
import Typography from "../../../../../../components/Typography/Typography";
import TextField from "../../../../../../components/forms/TextField";
import TextArea from "../../../../../../components/forms/TextArea";
import UnifiedFileUpload from "../../../../../../components/forms/UnifiedFileUpload";

const TeamSection = ({ initialValues, teamImage, handleTeamFileSelected }) => {
    return (
        <div className="mb-4">
            <Typography variant="h5" className="mb-3">
                Team Section
            </Typography>
            <div className="mb-3">
                <UnifiedFileUpload
                    name="teamImage"
                    label="Team Image"
                    fileType="image"
                    initialFile={
                        initialValues.team_image_file_url
                            ? {
                                  url: initialValues.team_image_file_url,
                                  name: initialValues.team_image_file_name,
                                  isExisting: true,
                              }
                            : null
                    }
                    selectedFile={teamImage}
                    onFileSelected={handleTeamFileSelected}
                />
            </div>
            <div className="mb-3">
                <TextArea name="team_quote" label="Team Quote" rows={3} />
            </div>
            <div className="mb-3">
                <TextField name="team_author" label="Quote Author" />
            </div>
        </div>
    );
};

export default TeamSection;
