import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Typography from "../../../../../components/Typography/Typography";
import Form from "../../../../../components/forms/Form";
import Button from "../../../../../components/Button/Button";
import { aboutValidationSchema } from "../../../../../utils/validationUtils";
import {
    BasicInformationSection,
    SubtitleSection,
    TeamSection,
    VideoContentSection,
    StoriesSection,
} from "./sections";

export default function AboutConfiguration({
    handleSubmit,
    initialValues,
    isLoading = false,
    aboutBannerImage,
    aboutSecondaryImage,
    aboutTeamImage,
    aboutStoryImages,
    handleAboutBannerSelected,
    handleAboutSecondarySelected,
    handleAboutTeamSelected,
    handleAboutStoryImageSelected,
}) {
    // Navigation hooks
    const navigate = useNavigate();
    const { id } = useParams();

    // Navigation handler
    const handleCancelClick = () => {
        navigate(`/overview/${id}/site-builder`);
    };

    return (
        <div className="container-fluid px-0">
            <Typography variant="h3" className="mb-3">
                About Page Configuration
            </Typography>

            <Form
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={aboutValidationSchema}
                enableReinitialize={true}
            >
                <div className="row mt-4">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <BasicInformationSection
                                    initialValues={initialValues}
                                    bannerImage={aboutBannerImage}
                                    secondaryImage={aboutSecondaryImage}
                                    handleBannerFileSelected={
                                        handleAboutBannerSelected
                                    }
                                    handleSecondaryFileSelected={
                                        handleAboutSecondarySelected
                                    }
                                />
                                <SubtitleSection
                                    initialValues={initialValues}
                                />
                                <TeamSection
                                    initialValues={initialValues}
                                    teamImage={aboutTeamImage}
                                    handleTeamFileSelected={
                                        handleAboutTeamSelected
                                    }
                                />
                                <VideoContentSection
                                    initialValues={initialValues}
                                />
                                <StoriesSection
                                    storyImages={aboutStoryImages}
                                    handleStoryImageSelected={
                                        handleAboutStoryImageSelected
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Action buttons */}
                <div className="row mt-4">
                    <div className="col-12 d-flex justify-content-between align-items-center gap-3">
                        <div>
                            <Button
                                type="button"
                                variant="secondary"
                                onClick={handleCancelClick}
                            >
                                Cancel
                            </Button>
                        </div>
                        <div>
                            <Button
                                type="submit"
                                className="float-end"
                                disabled={isLoading}
                            >
                                {isLoading ? (
                                    <div>
                                        <span
                                            className="spinner-border spinner-border-sm mx-2"
                                            aria-hidden="true"
                                        ></span>
                                        <span role="status">Saving...</span>
                                    </div>
                                ) : (
                                    "Save Configuration"
                                )}
                            </Button>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    );
}
