import { useNavigate, useParams } from "react-router-dom";
import Typography from "../../../../../components/Typography/Typography";
import Form from "../../../../../components/forms/Form";
import Button from "../../../../../components/Button/Button";
import { certificationValidationSchema } from "../../../../../utils/validationUtils";
import BasicInformation from "./sections/BasicInformation";
import QualityParameter from "./sections/QualityParameter";
import Standards from "./sections/Standards";
import VideoContent from "./sections/VideoContent";

export default function CertificationConfiguration({
    handleSubmit,
    initialValues,
    isLoading = false,
    certificationsBannerImage,
    handleCertificationsBannerSelected,
    standardImages,
    handleStandardImageselected,
}) {
    // Navigation hooks
    const navigate = useNavigate();
    const { id } = useParams();

    // Navigation handler
    const handleCancelClick = () => {
        navigate(`/overview/${id}/site-builder`);
    };

    return (
        <div className="container-fluid px-0">
            <Typography variant="h3" className="mb-3">
                Certifications and Standards Page Configuration
            </Typography>

            <Form
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={certificationValidationSchema}
                enableReinitialize={true}
            >
                <div className="row mt-4">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <BasicInformation
                                    initialValues={initialValues}
                                    bannerImage={certificationsBannerImage}
                                    handleBannerFileSelected={
                                        handleCertificationsBannerSelected
                                    }
                                />
                                <QualityParameter />
                                <Standards
                                    standardImages={standardImages}
                                    handleStandardImageselected={
                                        handleStandardImageselected
                                    }
                                />
                                <VideoContent initialValues={initialValues} />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Action buttons */}
                <div className="row mt-4">
                    <div className="col-12 d-flex justify-content-between align-items-center gap-3">
                        <div>
                            <Button
                                type="button"
                                variant="secondary"
                                onClick={handleCancelClick}
                            >
                                Cancel
                            </Button>
                        </div>
                        <div>
                            <Button
                                type="submit"
                                className="float-end"
                                disabled={isLoading}
                            >
                                {isLoading ? (
                                    <div>
                                        <span
                                            className="spinner-border spinner-border-sm mx-2"
                                            aria-hidden="true"
                                        ></span>
                                        <span role="status">Saving...</span>
                                    </div>
                                ) : (
                                    "Save Configuration"
                                )}
                            </Button>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    );
}
