import React from "react";
import { Handle, Position } from "@xyflow/react";

export default function SCTProductNode({ data, isConnectable }) {
    const label = data?.label;
    const product_id = data?.asset?.asset_id;
    const material_type = data?.asset_type?.material_type;
    const material_name = data?.asset_type?.type_name;
    const qty = data?.asset?.qty || 0;
    const created_at = data?.created_at?.split("T")[0];
    const unit_symbol = data.asset.unit?.symbol;

    if (!product_id) return <div>{label || "Invalid product"}</div>;

    return (
        <div
            className="d-flex flex-column gap-1
        "
        >
            <span>{product_id}</span>
            <span>
                {material_name} ({material_type})
            </span>
            <span>
                {qty} {unit_symbol} - {created_at}
            </span>

            <Handle type="source" position={Position.Top} id="a" />
            <Handle type="target" position={Position.Bottom} id="b" />
        </div>
    );
}
