import React, { useState, useEffect, useRef } from 'react';
import './InputSearchDropdown.scss';

const InputSearchDropdown = ({ 
  batches = [],
  onSelect, 
  onCreateBatch,
  onEditBatch,
  selectedBatch,
  placeholder = "Search or create batch...",
  loading
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [filteredBatches, setFilteredBatches] = useState([]);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (selectedBatch?.name && !isEditing) {
      setSearchTerm(selectedBatch.name);
    }
  }, [selectedBatch, isEditing]);

  useEffect(() => {
    if (searchTerm && Array.isArray(batches)) {
      const filtered = batches.filter(batch => 
        batch?.name?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredBatches(filtered);
    } else {
      setFilteredBatches(batches || []);
    }
  }, [searchTerm, batches]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    setIsOpen(true);
  };

  const handleSelect = (batch) => {
    if (onSelect) onSelect(batch);
    setSearchTerm(batch.name);
    setIsOpen(false);
  };

  const handleCreateNew = () => {
    if (onCreateBatch) onCreateBatch(searchTerm);
    setIsOpen(false);
  };

  const handleEditClick = (e) => {
    e.stopPropagation();
    setIsEditing(true);
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  const handleEditSubmit = () => {
    if (selectedBatch && searchTerm !== selectedBatch.name) {
      onEditBatch(selectedBatch.id, searchTerm);
    }
    setIsEditing(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && isEditing) {
      handleEditSubmit();
    }
    if (e.key === 'Escape' && isEditing) {
      setIsEditing(false);
      setSearchTerm(selectedBatch?.name || '');
    }
  };

  return (
    <div className="input-search-dropdown" ref={dropdownRef}>
      <div className="input-search-dropdown__input-wrapper">
        <input
          ref={inputRef}
          type="text"
          value={searchTerm}
          onChange={handleInputChange}
          onFocus={() => !isEditing && setIsOpen(true)}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          className="input-search-dropdown__input"
          disabled={loading}
        />
        <button 
          className="input-search-dropdown__toggle-btn"
          onClick={() => setIsOpen(!isOpen)}
          disabled={loading}
        >
          <i className={`bi bi-chevron-down ${isOpen ? 'rotate' : ''}`}></i>
        </button>
        {selectedBatch && (
          <>
            {isEditing ? (
              <button 
                className="input-search-dropdown__edit-btn"
                onClick={handleEditSubmit}
                disabled={loading}
              >
                <i className="bi bi-check-lg"></i>
              </button>
            ) : (
              <button 
                className="input-search-dropdown__edit-btn"
                onClick={handleEditClick}
                disabled={loading}
              >
                <i className="bi bi-pencil-square"></i>
              </button>
            )}
          </>
        )}
      </div>
      
      {isOpen && !isEditing && (
        <div className="input-search-dropdown__menu">
          {filteredBatches.map((batch) => (
            <div
              key={batch.id}
              className="input-search-dropdown__item"
              onClick={() => handleSelect(batch)}
            >
              {batch.name}
            </div>
          ))}
          
          {searchTerm && filteredBatches.length === 0 && (
            <div 
              className="input-search-dropdown__item input-search-dropdown__item--create"
              onClick={handleCreateNew}
            >
              Create new batch: "{searchTerm}"
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default InputSearchDropdown;