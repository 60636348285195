import { useParams } from "react-router-dom";
import Typography from "../../../components/Typography/Typography";
import ProjectDashboardCard from "../../../components/Card/ProjectDashboardCard";
import { useGetDashboardDataQuery } from "../../../services/supplyChainService";
import Loading from "../../../components/LoadingSkeleton/Loading";
import "./dashboardTab.scss";

function SupplyChainDashboardTab() {
    const { id } = useParams();
    const { data: dashboardData, isLoading } = useGetDashboardDataQuery(id, {
        refetchOnMountOrArgChange: true,
    });

    if (isLoading) {
        return (
            <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100vh" }}
            >
                <Loading />
            </div>
        );
    }

    const nodes = dashboardData?.data?.nodes;
    const batches = dashboardData?.data?.batches;
    const assetTypes = dashboardData?.data?.asset_types;

    return (
        <div className="container-fluid">
            <div className="row mb-4">
                <div className="col-12 pt-2">
                    <Typography variant="h3" className="mb-3 text-2xl">
                        Dashboard
                    </Typography>
                </div>
            </div>

            <div className="row g-3">
                <div className="col-12 col-md-4">
                    <ProjectDashboardCard
                        name="Total Nodes"
                        value={nodes ?? "0"}
                        variant="summary"
                    />
                </div>
                <div className="col-12 col-md-4">
                    <ProjectDashboardCard
                        name="Total Batches"
                        value={batches ?? "0"}
                        variant="summary"
                    />
                </div>
                <div className="col-12 col-md-4">
                    <ProjectDashboardCard
                        name="Total Materials"
                        value={assetTypes ?? "0"}
                        variant="summary"
                    />
                </div>
            </div>
        </div>
    );
}

export default SupplyChainDashboardTab;
