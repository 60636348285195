import Checkbox from "../../forms/Checkbox";
import Button from "../../Button/Button";

const PageOption = ({
    name,
    label,
    checked,
    disabled,
    onChange,
    status,
    onConfigure,
    showConfigure,
    statusTooltip,
}) => (
    <div className="mt-3 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
            <Checkbox
                name={name}
                label={label}
                checked={checked}
                onChange={onChange}
                disabled={disabled}
            />
            {status === "unconfigured" && (
                <span className="ms-2 badge bg-warning text-dark" title={statusTooltip}>
                    Not configured
                </span>
            )}
            {status === "configured" && (
                <span className="ms-2 badge bg-success text-white" title={statusTooltip}>
                    Configured
                </span>
            )}
        </div>
        {showConfigure && (
            <Button
                type="button"
                variant="primary"
                fullWidth={false}
                onClick={onConfigure}
            >
                Configure
            </Button>
        )}
    </div>
);

export default PageOption;