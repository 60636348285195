import React from "react";
import Typography from "../../../../../components/Typography/Typography";
import Form from "../../../../../components/forms/Form";
import Button from "../../../../../components/Button/Button";
import PageSettingsCard from "./PageSettingsCard";
import TemplateConfigurationsCard from "./TemplateConfigurationsCard";
import { homeValidationSchema } from "../../../../../utils/validationUtils";
import { useNavigate, useParams } from "react-router-dom";

const HomeConfiguration = ({
    handleSubmit,
    bannerImage,
    initialValues,
    isLoading = false,
    configurations = [],
    handleFileSelected,
}) => {
    // Navigation and route hooks
    const navigate = useNavigate();
    const { id } = useParams();

    // Event handlers
    const handleCancelClick = () => {
        navigate(`/overview/${id}/site-builder`);
    };

    // Component render
    return (
        <div className="container-fluid px-0">
            <Typography variant="h3" className="mb-3">
                Home Page Configuration
            </Typography>

            <Form
                initialValues={{ initialValues }}
                onSubmit={handleSubmit}
                validationSchema={homeValidationSchema}
                enableReinitialize={true}
            >
                {/* Page Settings section */}
                <div className="row mt-4">
                    <div className="col-12">
                        <PageSettingsCard
                            bannerImage={bannerImage}
                            bannerImageUrl={initialValues.banner_image_file_url}
                            bannerImageName={
                                initialValues.banner_image_file_name
                            }
                            isLoading={isLoading}
                            handleFileSelected={handleFileSelected}
                        />
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-12">
                        <TemplateConfigurationsCard
                            configurations={configurations}
                        />
                    </div>
                </div>

                {/* Action buttons */}
                <div className="row mt-4">
                    <div className="col-12 d-flex justify-content-between align-items-center gap-3">
                        <div>
                            <Button
                                type="button"
                                variant="secondary"
                                onClick={handleCancelClick}
                            >
                                Cancel
                            </Button>
                        </div>
                        <div>
                            <Button type="submit" disabled={isLoading}>
                                {isLoading ? (
                                    <div>
                                        <span
                                            className="spinner-border spinner-border-sm mx-2"
                                            aria-hidden="true"
                                        ></span>
                                        <span role="status">Saving...</span>
                                    </div>
                                ) : (
                                    "Save Configuration"
                                )}
                            </Button>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default HomeConfiguration;
