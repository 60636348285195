import React, { useEffect, useState } from "react";
import { string_to_snake_case } from "../../../utils/projectUtils";
import { Select, TextField } from "../../../components/forms";

export const formConfigOptions = [
    {
        label: "Text Input",
        value: "text",
    },
    {
        label: "Date Input",
        value: "date",
    },
    {
        label: "Image Input",
        value: "image",
        name: "logo",
    },
    {
        label: "File Input",
        value: "file",
    },
];

const RESERVED_FIELD_NAMES = ["created_at"];

export function FormRenderer({
    formFields,
    setFormFields,
    formGroups,
    setFormGroups,
    editMode,
    onValidationChange,
}) {
    const [fieldsToValidate, setFieldsToValidate] = useState([]);
    const [dragOverIndex, setDragOverIndex] = useState(null);

    // Add state to track validation errors for the form builder
    const [formFieldsValid, setFormFieldsValid] = useState(true);

    // Add new state for field errors
    const [fieldErrors, setFieldErrors] = useState({});

    // Add useEffect to initialize the default date field if formFields is empty
    useEffect(() => {
        if (formFields.length === 0) {
            setFormFields([
                {
                    label: "Date",
                    name: "created_at",
                    type: "date",
                    groupId: "0",
                    required: true,
                    isPublic: true,
                },
            ]);
        }
    }, []);

    const handleAddFormField = (e, fieldGroup = "0") => {
        const newFieldIndex = formFields.length;
        setFormFields([
            ...formFields,
            {
                label: "",
                name: "",
                type: "",
                groupId: fieldGroup,
                required: false,
                isPublic: true,
            },
        ]);
        setFieldsToValidate([...fieldsToValidate, newFieldIndex]);
    };

    const handleAddFormGroup = () => {
        const newFieldIndex = formFields.length;
        setFormFields([
            ...formFields,
            {
                name: "Group",
                type: "group",
                groupId: `${formGroups.length + 1}`,
            },
        ]);
        setFormGroups([
            ...formGroups,
            {
                groupId: `${formGroups.length + 1}`,
            },
        ]);
        setFieldsToValidate([...fieldsToValidate, newFieldIndex]);
    };

    const handleRemoveFormField = (index) => {
        setFormFields(formFields.filter((_, i) => i !== index));
        setFieldsToValidate(fieldsToValidate.filter((i) => i !== index));
    };

    const handleRemoveFormGroup = (groupId) => {
        setFormFields(formFields.filter((field) => field.groupId !== groupId));
        setFormGroups(formGroups.filter((group) => group.groupId !== groupId));
    };

    const validateFieldName = (name, index) => {
        // Check if name is reserved and being used on non-first field
        if (index !== 0 && RESERVED_FIELD_NAMES.includes(name)) {
            return `The field name '${name}' is reserved`;
        }

        const isDuplicate = formFields.some(
            (f, i) => i !== index && f.name === name,
        );

        if (isDuplicate) {
            return "This field name already exists. Please use a different name.";
        }

        return null;
    };

    const handleOnChangeFormFieldProp = (index, field, value) => {
        if (field === "label") {
            const newName =
                index === 0 ? "created_at" : string_to_snake_case(value || "");
            const error = validateFieldName(newName, index);

            setFieldErrors((prev) => ({
                ...prev,
                [index]: error,
            }));

            if (!error || index === 0) {
                setFormFields(
                    formFields.map((f, i) => {
                        if (i !== index) return f;
                        return {
                            ...f,
                            label: value,
                            name: index === 0 ? "created_at" : newName, // Always keep 'created_at' for first field
                        };
                    }),
                );
            }
        } else if (field === "type") {
            // Handle type change
            setFormFields(
                formFields.map((f, i) => {
                    if (i !== index) {
                        return f;
                    }
                    return {
                        ...f,
                        type: value,
                        // Set default name for specific types if label is empty
                        name: f.label ? string_to_snake_case(f.label) : value,
                    };
                }),
            );
        } else {
            setFormFields(
                formFields.map((f, i) => {
                    if (i !== index) {
                        return f;
                    }
                    return {
                        ...f,
                        [field]: value,
                    };
                }),
            );
        }
    };

    const handleDragStart = (e, index) => {
        e.dataTransfer.setData("draggedIndex", index);
    };

    const handleDragOverField = (e, index) => {
        e.preventDefault();
        setDragOverIndex(index);
    };

    const handleDrop = (e, index) => {
        const draggedIndex = e.dataTransfer.getData("draggedIndex");
        if (draggedIndex === index) return;
        const updatedFormFields = [...formFields];
        const [draggedField] = updatedFormFields.splice(draggedIndex, 1);
        updatedFormFields.splice(index, 0, draggedField);
        setFormFields(updatedFormFields);
        setDragOverIndex(null);
    };

    // Add validation function to check form fields
    const validateFormFields = () => {
        let isValid = true;
        let newFieldErrors = {};

        formFields.forEach((field, index) => {
            // Skip the first field (date) and group headers
            if (index === 0 || field.type === "group") return;

            // Check required properties
            if (!field.label) {
                newFieldErrors[index] = "Field label is required";
                isValid = false;
            } else if (!field.type) {
                newFieldErrors[index] = "Field type is required";
                isValid = false;
            }
        });

        setFieldErrors(newFieldErrors);
        setFormFieldsValid(isValid);
        return isValid;
    };

    // Expose validation method through ref or context for parent component
    useEffect(() => {
        // Run validation whenever formFields change
        validateFormFields();
    }, [formFields]);

    // Pass validation state to parent component
    useEffect(() => {
        if (typeof onValidationChange === "function") {
            onValidationChange(formFieldsValid);
        }
    }, [formFieldsValid]);

    return (
        <div>
            <div className="m-3">
                {formFields.map((ff, idx) => {
                    if (ff["type"] === "group") {
                        return (
                            <div className="group-container" key={idx}>
                                <div className="d-flex justify-content-between m-3 add-header">
                                    <div className="add-title">Group</div>
                                    <div className="d-flex align-items-center">
                                        <div
                                            className="add-close"
                                            onClick={() =>
                                                handleRemoveFormGroup(
                                                    ff["groupId"],
                                                )
                                            }
                                        >
                                            X Remove Group
                                        </div>
                                    </div>
                                </div>
                                <div className="m-3">
                                    {formFields.map(
                                        (groupField, groupFieldId) => {
                                            if (
                                                groupField["groupId"] !==
                                                    ff["groupId"] ||
                                                groupField["type"] === "group"
                                            ) {
                                                return null;
                                            }
                                            return (
                                                <FormComponent
                                                    key={groupFieldId}
                                                    formFields={formFields}
                                                    fieldName={
                                                        groupField["name"]
                                                    }
                                                    fieldLabel={
                                                        groupField["label"]
                                                    }
                                                    fieldType={
                                                        groupField["type"]
                                                    }
                                                    fieldIndex={groupFieldId}
                                                    fieldGroup={
                                                        groupField["group"]
                                                    }
                                                    onAddFormField={
                                                        handleAddFormField
                                                    }
                                                    onRemoveField={() =>
                                                        handleRemoveFormField(
                                                            groupFieldId,
                                                        )
                                                    }
                                                    onChangeProp={(
                                                        field,
                                                        value,
                                                    ) =>
                                                        handleOnChangeFormFieldProp(
                                                            groupFieldId,
                                                            field,
                                                            value,
                                                        )
                                                    }
                                                    onDragOver={
                                                        handleDragOverField
                                                    }
                                                    onDragStart={
                                                        handleDragStart
                                                    }
                                                    onDrop={handleDrop}
                                                    isDragOver={
                                                        dragOverIndex ===
                                                        groupFieldId
                                                    }
                                                    fieldErrors={fieldErrors}
                                                />
                                            );
                                        },
                                    )}
                                </div>
                                <div className="d-flex flex-row">
                                    <div
                                        className="m-3 add-another-node"
                                        onClick={(e) =>
                                            handleAddFormField(e, ff["groupId"])
                                        }
                                    >
                                        + Add form field
                                    </div>
                                </div>
                            </div>
                        );
                    }

                    if (ff["groupId"] !== "0") {
                        return null;
                    }

                    return (
                        <FormComponent
                            key={idx}
                            formFields={formFields} // Add this prop
                            fieldName={ff["name"]}
                            fieldLabel={ff["label"]}
                            fieldType={ff["type"]}
                            fieldIndex={idx}
                            fieldGroup={ff["group"]}
                            onAddFormField={handleAddFormField}
                            onRemoveField={() => handleRemoveFormField(idx)}
                            onChangeProp={(field, value) =>
                                handleOnChangeFormFieldProp(idx, field, value)
                            }
                            onDragOver={handleDragOverField}
                            onDragStart={handleDragStart}
                            onDrop={handleDrop}
                            isDragOver={dragOverIndex === idx}
                            fieldErrors={fieldErrors} // Add this prop
                        />
                    );
                })}
            </div>
            <div className="d-flex flex-row">
                <div
                    className="m-3 add-another-node"
                    onClick={handleAddFormField}
                >
                    + Add form field
                </div>
                <div
                    className="m-3 add-another-node"
                    onClick={handleAddFormGroup}
                >
                    + Add form group
                </div>
            </div>
        </div>
    );
}

function FormComponent({
    fieldName,
    fieldLabel,
    fieldType,
    fieldIndex,
    fieldGroup,
    formFields, // Add this prop
    onAddFormField,
    onRemoveField,
    onChangeProp,
    onDragStart,
    onDragOver,
    onDrop,
    isDragOver,
    fieldErrors, // Add this prop
}) {
    const handleDragStart = (e) => {
        onDragStart(e, fieldIndex);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        onDragOver(e, fieldIndex);
    };

    const handleDrop = (e) => {
        onDrop(e, fieldIndex);
    };

    // Helper function to determine which field has the error
    const getErrorField = () => {
        if (!fieldErrors[fieldIndex]) return null;

        // Check if the error is specifically for the field type or label
        if (fieldErrors[fieldIndex].includes("type")) return "type";
        if (fieldErrors[fieldIndex].includes("label")) return "label";
        return null;
    };

    const errorField = getErrorField();

    return (
        <div
            className={`add-container ${isDragOver ? "drag-over" : ""}`}
            key={fieldIndex}
            draggable={fieldIndex !== 0} // Disable drag for first field
            onDragStart={fieldIndex !== 0 ? handleDragStart : undefined} // Only allow drag events for non-first fields
            onDragOver={fieldIndex !== 0 ? handleDragOver : undefined}
            onDrop={fieldIndex !== 0 ? handleDrop : undefined}
        >
            <div className="d-flex justify-content-between m-3 add-header">
                <div className="add-title">Form field - #{fieldIndex + 1}</div>
                <div className="d-flex align-items-center">
                    {fieldIndex !== 0 && (
                        <>
                            <div className="add-close" onClick={onRemoveField}>
                                X Remove
                            </div>
                            <div className="drag-icon">
                                <i className="bi bi-grip-vertical"></i>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className="m-3">
                <TextField
                    name={`form_config_label_${fieldIndex}`}
                    label={fieldIndex === 0 ? "Date Label" : "Field Label"}
                    value={fieldLabel}
                    onChange={(e) => onChangeProp("label", e.target.value)}
                    placeholder="Enter field label"
                />
                {errorField === "label" && (
                    <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                    >
                        {fieldErrors[fieldIndex]}
                    </div>
                )}
            </div>
            <div className="m-3">
                <TextField
                    name={`form_config_name_${fieldIndex}`}
                    label="Field Name"
                    value={fieldName}
                    disabled={true}
                    onChange={(e) => onChangeProp("name", e.target.value)}
                />
            </div>
            <div className="m-3">
                <Select
                    options={formConfigOptions}
                    name={`form_config_type_${fieldIndex}`}
                    label="Field Type"
                    value={fieldIndex === 0 ? "date" : fieldType}
                    onChange={(e) => {
                        // Handle Select component's event properly
                        const selectedValue = e?.value || e?.target?.value;
                        onChangeProp("type", selectedValue);
                    }}
                    placeholder="Select field type"
                    disabled={fieldIndex === 0}
                />
                {errorField === "type" && (
                    <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                    >
                        {fieldErrors[fieldIndex]}
                    </div>
                )}
            </div>
            <div className="m-3 d-flex justify-content-start align-items-center ps-4 ms-4">
                <div className="d-flex align-items-center me-5 ps-2">
                    <div className="form-check form-switch d-flex align-items-center gap-2 ps-0">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id={`required-toggle-${fieldIndex}`}
                            checked={formFields[fieldIndex].required}
                            onChange={(e) =>
                                onChangeProp("required", e.target.checked)
                            }
                            style={{ marginTop: 0 }}
                        />
                        <label
                            className="form-check-label"
                            htmlFor={`required-toggle-${fieldIndex}`}
                            style={{ fontSize: "14px", marginLeft: "4px" }}
                        >
                            Required Field
                        </label>
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <div className="form-check form-switch d-flex align-items-center gap-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id={`public-toggle-${fieldIndex}`}
                            checked={formFields[fieldIndex].isPublic}
                            onChange={(e) =>
                                onChangeProp("isPublic", e.target.checked)
                            }
                            style={{ marginTop: 0 }}
                        />
                        <label
                            className="form-check-label"
                            htmlFor={`public-toggle-${fieldIndex}`}
                            style={{ fontSize: "14px", marginLeft: "4px" }}
                        >
                            Public Field
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
}
