import BasicTable from "../../../../components/tables/table";
import Button from "../../../../components/Button/Button";
import Typography from "../../../../components/Typography/Typography";
import { useModal } from "../../../../hooks/modalsHooks";
import { useParams } from "react-router-dom";

const ExistingMaterial = ({ existingMaterialData }) => {

    const { showModal } = useModal();
    const { id } = useParams();
    const handleEditClick = (material) => {
        showModal({
            modalType: "MaterialPopup",
            modalProps: {
                editMode: true,
                materialData: material,
                id,
                onMaterialAdded: () => {
                    // Refresh material data if needed
                }
            }
        });
    };

    const columns = [
        {
            header: "Name",
            accessor: "type_name",
            Cell: ({ cell: { value } }) => value || "N/A",
        },
        {
            header: "Location",
            accessor: "node.location",
            Cell: ({ cell: { value } }) => value || "-",
        },
        {
            header: "Type",
            accessor: "material_type",
            Cell: ({ cell: { value } }) => value || "-",
        },
        {
            header: "Dependencies",
            accessor: "dependency_config",
            Cell: ({ cell: { value } }) => {
                const dependencies = value?.dependencies || [];
                return dependencies.length > 0 
                    ? dependencies.map(dep => dep.label).join(", ") 
                    : "-";
            },
        },
        {
            header: "Actions",
            accessor: "actions",
            Cell: ({ row }) => (
                <div className="d-flex flex-row justify-content-md-start justify-content-end">
                    <Button
                        variant="primary"
                        fullWidth={false}
                        onClick={() => handleEditClick(row.original)}
                    >
                        Edit
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <div className="pt-2">
            {existingMaterialData && existingMaterialData.length > 0 ? (
                <BasicTable
                    data={existingMaterialData}
                    columns={columns}
                    isSortable={false}
                    isPaginated={existingMaterialData?.length > 5}
                    pageSize={5}
                    currentPage={0}
                />
            ) : (
                <Typography variant="body1">
                    There is no material available.
                </Typography>
            )}
        </div>
    );
};

export default ExistingMaterial;
