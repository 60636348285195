import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSaveSiteConfigurationMutation } from "../../../../../services/supplyChainService";
import traceTemplate from "./template.json";
import { useImageProcessing } from "../../../../../utils/imageUtils";
import TraceConfiguration from "./TraceConfiguration";

export default function TracePageController({
    projectId,
    configId,
    setConfigId,
    initialValues,
    pageConfigurations,
    setPageConfigurations,
    allConfigurations,
    setInitialValues,
    isLoading,
}) {
    // Hooks and state
    const [saveSiteConfiguration, { isLoading: isSaving }] =
        useSaveSiteConfigurationMutation();
    const { processImage } = useImageProcessing();
    const [bannerImage, setBannerImage] = useState(null);

    // Load initial configuration data
    useEffect(() => {
        if (!allConfigurations?.data) return;

        const traceConfig = allConfigurations.data.find(
            (config) => config.config_type === "TRACE",
        );
        if (traceConfig) {
            setConfigId(traceConfig.id);
            const traceConfigData = traceConfig.config_data;

            if (traceConfigData.banner_image_file_url) {
                setBannerImage(traceConfigData.banner_image_file_url);
            }

            setInitialValues({
                ...traceConfigData,
            });
        }
    }, [
        allConfigurations,
        setConfigId,
        setInitialValues,
        setPageConfigurations,
    ]);

    // Event handlers
    const handleBannerFileSelected = (file) => {
        setBannerImage(file);
    };

    // Form submission handler
    const handleSubmit = async (values) => {
        try {
            const bannerImageFileId = await processImage(
                bannerImage,
                initialValues.banner_image_file_id,
            );

            const existingConfiguration = pageConfigurations.trace?.exists;
            const isEnabled = existingConfiguration
                ? pageConfigurations.trace?.enabled !== false
                : true;

            const configData = {
                ...traceTemplate,
                config_data: {
                    page_title: values.page_title || initialValues.page_title,
                    page_description:
                        values.page_description ||
                        initialValues.page_description,
                    banner_image_file_id: bannerImageFileId,
                    show_trace_widget:
                        values.show_trace_widget !== undefined
                            ? values.show_trace_widget
                            : initialValues.show_trace_widget,
                },
                enabled: isEnabled,
                required: traceTemplate.required,
            };

            const result = await saveSiteConfiguration({
                id: projectId,
                configData,
            }).unwrap();

            if (result?.data?.id) {
                setConfigId(result.data.id);

                setPageConfigurations((prev) => ({
                    ...prev,
                    trace: {
                        ...prev.trace,
                        id: result.data.id,
                        exists: true,
                        enabled: existingConfiguration
                            ? prev.trace.enabled
                            : true,
                    },
                }));
            }
            toast.success("Trace page configuration saved successfully");
        } catch (error) {
            console.error("Error saving trace configuration:", error);
            toast.error("Failed to save trace page configuration");
        }
    };

    return (
        <TraceConfiguration
            handleSubmit={handleSubmit}
            bannerImage={bannerImage}
            initialValues={initialValues}
            isLoading={isSaving || isLoading}
            handleFileSelected={handleBannerFileSelected}
        />
    );
}
