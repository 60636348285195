import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useSaveSiteConfigurationMutation } from "../../../../../services/supplyChainService";
import homeTemplate from "./template.json";
import HomeConfiguration from "./HomeConfiguration";
import { useImageProcessing } from "../../../../../utils/imageUtils";

const HomePageController = ({
    projectId,
    allConfigurations,
    isLoading: isLoadingAll,
    configId,
    setConfigId,
    initialValues,
    setInitialValues,
    pageConfigurations,
    setPageConfigurations,
}) => {
    // API mutations
    const [saveSiteConfiguration, { isLoading: isSaving }] =
        useSaveSiteConfigurationMutation();

    // Local state
    const [bannerImage, setBannerImage] = useState(null);

    // Utility hooks
    const { processImage } = useImageProcessing();

    // Load initial configuration data
    useEffect(() => {
        if (!allConfigurations?.data) return;

        const homeConfig = allConfigurations.data.find(
            (config) => config.config_type === "HOME",
        );

        if (homeConfig) {
            setConfigId(homeConfig.id);
            const homeConfigData = homeConfig.config_data;

            if (homeConfigData.banner_image_file_url) {
                setBannerImage(homeConfigData.banner_image_file_url);
            }

            setInitialValues({
                ...homeConfigData,
            });
        }
    }, [
        allConfigurations,
        setConfigId,
        setInitialValues,
        setPageConfigurations,
    ]);

    // Event handlers
    const handleFileSelected = (file) => {
        setBannerImage(file);
    };

    // Form submission handler
    const handleHomeSubmit = async (values) => {
        try {
            const bannerImageFileId = await processImage(
                bannerImage,
                initialValues.banner_image_file_id,
            );

            const existingConfiguration = pageConfigurations.home?.exists;
            const isEnabled = existingConfiguration
                ? pageConfigurations.home?.enabled !== false
                : true;

            const configData = {
                ...homeTemplate,
                config_data: {
                    banner_image_file_id: bannerImageFileId,
                },
                enabled: isEnabled,
                required: homeTemplate.required,
            };

            const result = await saveSiteConfiguration({
                id: projectId,
                configData,
            }).unwrap();

            if (result?.data?.id) {
                setConfigId(result.data.id);

                setPageConfigurations((prev) => ({
                    ...prev,
                    home: {
                        ...prev.home,
                        id: result.data.id,
                        exists: true,
                        enabled: true,
                        updated_at: new Date().toISOString(),
                    },
                }));
            }
            toast.success("Home page configuration saved successfully");
        } catch (error) {
            console.error("Error saving home configuration:", error);
            toast.error("Failed to save home page configuration");
        }
    };

    return (
        <HomeConfiguration
            handleSubmit={handleHomeSubmit}
            bannerImage={bannerImage}
            initialValues={initialValues}
            isLoading={isSaving}
            configurations={allConfigurations?.data}
            handleFileSelected={handleFileSelected}
        />
    );
};

export default HomePageController;
