import React from "react";
import { Icon } from "./../Icon.jsx";
import "./button.scss";

/**
 * Native button wrapped to enable customization with bootstrap sizes and variants
 * @param {{
 * children: JSX.Element,
 * size: "sm" | "lg",
 * variant: "primary" | "secondary" | "outline-primary" | "outline-secondary",
 * type: "button" | "submit",
 * disabled: boolean,
 * fullWidth: boolean,
 * isLoading: boolean,
 * loadingText: string,
 * }} props
 * @returns {React.FunctionComponent}
 */
export default function Button({
    style = {},
    className = "",
    type = "button",
    disabled = false,
    children = "Button",
    size = "",
    variant = "primary",
    fullWidth = true,
    isLoading = false,
    dropdown = false,
    dropend = false,
    onClick = () => {},
    icon = "",
    ghost = false,
    color = "",
    loadingText = "Loading...",
    app="admin"
}) {
    const getButtonClassName = () => {
        const btnClasses = ["", "", "", "", ""];

        if (size && typeof size === "string") {
            btnClasses[0] = `btn-${size}`;
        }
        if (typeof variant === "string") {
            btnClasses[1] = `btn-${variant}`;
        }
        if (fullWidth) {
            btnClasses[2] = "w-100 w-md-auto";
        }
        if (dropdown) {
            btnClasses[3] = "dropdown-toggle";
        }
        if (dropend) {
            btnClasses[4] = "dropend";
        }
        if (ghost) {
            btnClasses[1] = `btn-ghost-${variant}`;
        }
        if(app ==="customer"){
            btnClasses[1] = `btn-customer-${variant}`;
        }
        return `btn ${btnClasses.join(" ")}`;
    };
    return (
        <button
            type={type}
            className={`${getButtonClassName()} ${className} d-flex align-items-center justify-content-center`}
            disabled={disabled}
            aria-label="button"
            onClick={onClick}
            color={color}
            style={style}
        >
            {icon && !isLoading ? (
                <Icon
                    className="me-2"
                    src={icon}
                    alt="button-icon"
                    height="25"
                    width="25"
                />
            ) : null}
            {isLoading ? (
                <>
                    <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    ></span>
                    <span className="ms-2">{loadingText}</span>
                </>
            ) : (
                children
            )}
            {dropdown ? (
                <span className="visually-hidden">
                    <Icon
                        src="icon/down-arrow.svg"
                        alt="dropdown"
                        height="20"
                        width="20"
                    />
                </span>
            ) : (
                ""
            )}
            {dropend ? (
                <span>
                    <Icon src={icon} alt="dropdown" height="20" width="20" />
                </span>
            ) : (
                ""
            )}
        </button>
    );
}
