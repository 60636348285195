import React, { useState } from "react";
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import {
    useCreateCheckoutSessionMutation,
    useGetBillingQuery,
} from "../../../services/billingService";
import { logEvent } from "../../../utils/amplitudeUtlis";
import { toast } from "react-toastify";
import "./billing.scss";

const PricingComponent = ({ currentTier, isTierActive = true }) => {
    const [createCheckoutSession] = useCreateCheckoutSessionMutation();
    const { data: billingData } = useGetBillingQuery();
    const [loadingTier, setLoadingTier] = useState(null);
    const [isManagingSubscription, setIsManagingSubscription] = useState(false);

    const pricingPlans = [
        {
            name: "Starter",
            tier: "FREE_TIER",
            price: "$0",
            period: "per month",
            description:
                "Small teams just beginning to illuminate their supply chain",

            features: [
                "10 Batches",
                "1 Project",
                "Core Traceability Tools",
                "Entry Level Reporting",
            ],
        },
        {
            name: "Basic",
            tier: "BASIC_TIER",
            price: "$99",
            period: "per month",
            description: "Growing businesses aiming to refine their operations",
            features: [
                "50 Batches",
                "Unlimited Projects",
                "Core Traceability Tools",
                "Entry Level Reporting",
                "Advanced Analytics, Compliance Features & Multi-Chain Insights",
            ],
        },
        {
            name: "Pro",
            tier: "PRO_TIER",
            price: "$199",
            period: "per month",
            description: "Complex organizations with detailed requirements",
            features: [
                "100 Batches",
                "Unlimited Projects",
                "Core Traceability Tools",
                "Entry Level Reporting",
                "Advanced Analytics, Compliance Features & Multi-Chain Insights",
                "Custom Integration, Mobile App, Dedicated Support & Enterprise Grade Security",
            ],
        },
    ];

    const handleManageSubscription = async () => {
        setIsManagingSubscription(true);
        try {
            if (billingData?.data?.customer_portal_url) {
                window.open(billingData.data.customer_portal_url, "_blank");
                logEvent({
                    eventName: "billing_portal_opened",
                    eventProperties: { plan: currentTier },
                });
            } else {
                const errorMessage =
                    billingData?.error?.data?.errors[0] ||
                    "Failed to access billing portal";
                toast(errorMessage);
                logEvent({
                    eventName: "billing_portal_failed",
                    eventProperties: { plan: currentTier },
                });
            }
        } catch (error) {
            toast("An error occurred while accessing the billing portal");
            logEvent({
                eventName: "manage_subscription_error",
                eventProperties: { plan: currentTier, error: error.message },
            });
        } finally {
            setIsManagingSubscription(false);
        }
    };

    const handleSelectPlan = async (tier) => {
        if (tier === currentTier || loadingTier) return;

        setLoadingTier(tier);
        try {
            const checkoutResponse = await createCheckoutSession({
                product_id: tier,
            });

            if (checkoutResponse.data?.data?.url) {
                logEvent({
                    eventName: "change_plan_initiated",
                    eventProperties: { plan: tier },
                });

                window.open(checkoutResponse.data.data.url, "_blank");
            } else {
                const errorMessage =
                    checkoutResponse?.error?.data?.errors[0] ||
                    "Failed to process request";
                toast(errorMessage);
                logEvent({
                    eventName: "change_plan_failed",
                    eventProperties: { plan: tier },
                });
            }
        } catch (error) {
            toast("An error occurred while processing your request");
            logEvent({
                eventName: "change_plan_error",
                eventProperties: { plan: tier, error: error.message },
            });
        } finally {
            setLoadingTier(null);
        }
    };

    const getButtonText = (plan) => {
        if (loadingTier === plan.tier) return "Loading...";

        // If the current tier is active and matches this plan
        if (isTierActive && currentTier === plan.tier) return "Current Plan";

        // If current tier is FREE_TIER (active or inactive)
        if (currentTier === "FREE_TIER") {
            if (plan.tier === "BASIC_TIER") return "Upgrade to Basic";
            if (plan.tier === "PRO_TIER") return "Upgrade to Pro";
        }

        // If current tier is BASIC_TIER and active
        if (isTierActive && currentTier === "BASIC_TIER") {
            if (plan.tier === "PRO_TIER") return "Upgrade to Pro";
        }

        // If current tier is PRO_TIER and active
        if (isTierActive && currentTier === "PRO_TIER") {
            if (plan.tier === "BASIC_TIER") return "Switch to Basic";
        }

        // If tier is inactive but user had a paid plan before
        if (!isTierActive && currentTier !== "FREE_TIER") {
            if (plan.tier === currentTier) return "Renew plan";
            if (currentTier === "PRO_TIER" && plan.tier === "BASIC_TIER")
                return "Select plan";
            if (currentTier === "BASIC_TIER" && plan.tier === "PRO_TIER")
                return "Upgrade to Pro";
        }

        return "Select Plan";
    };

    // Determine which plans to show
    const visiblePlans =
        currentTier === "FREE_TIER" && isTierActive
            ? pricingPlans
            : pricingPlans.filter((plan) => plan.tier !== "FREE_TIER");

    return (
        <div className="pricing-component mt-4">
            <div className="row">
                {visiblePlans.map((plan, index) => {
                    const isCurrentPlan =
                        isTierActive && currentTier === plan.tier;
                    const isPaidPlan = plan.tier !== "FREE_TIER";

                    return (
                        <div
                            className={`col-md-${12 / visiblePlans.length} mb-4`}
                            key={index}
                        >
                            <div
                                className={`card pricing-card shadow-sm ${
                                    isCurrentPlan ? "current-plan" : ""
                                } `}
                            >
                                <div className={`card-body d-flex flex-column`}>
                                    <Typography variant="h3" className="mb-2">
                                        {plan.name.toUpperCase()}
                                    </Typography>

                                    <Typography
                                        variant="body1"
                                        className="mb-2"
                                    >
                                        {plan.description}
                                    </Typography>
                                    <div className="mb-2 pricing-display">
                                        <span className="plan-price">
                                            {plan.price}
                                        </span>
                                        <Typography
                                            variant="body2"
                                            className="pricing-period"
                                        >
                                            / {plan.period}
                                        </Typography>
                                    </div>
                                    <ul className="list-unstyled mb-4">
                                        {plan.features.map((feature, idx) => (
                                            <li key={idx}>
                                                <div className="d-flex align-items-center">
                                                    <Typography
                                                        variant="body2"
                                                        className="m-0"
                                                    >
                                                        <i className="bi bi-check-circle me-2"></i>
                                                        {feature}
                                                    </Typography>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                    <div className="mt-auto">
                                        {isCurrentPlan && isPaidPlan && (
                                            <div className="text-center mb-3">
                                                <button
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleManageSubscription();
                                                    }}
                                                    className="manage-link"
                                                    disabled={
                                                        isManagingSubscription
                                                    }
                                                >
                                                    {isManagingSubscription
                                                        ? "Processing..."
                                                        : "Manage subscription"}
                                                </button>
                                            </div>
                                        )}
                                        <Button
                                            variant={
                                                isCurrentPlan
                                                    ? "secondary"
                                                    : "primary"
                                            }
                                            fullWidth={true}
                                            disabled={
                                                isCurrentPlan ||
                                                loadingTier === plan.tier
                                            }
                                            onClick={() =>
                                                handleSelectPlan(plan.tier)
                                            }
                                            isLoading={
                                                loadingTier === plan.tier
                                            }
                                        >
                                            {getButtonText(plan)}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default PricingComponent;
