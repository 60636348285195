import { useCallback, useEffect, useMemo, useState } from "react";
import { useModal } from "../../../../hooks/modalsHooks";
import Typography from "../../../../components/Typography/Typography";
import ModalLayout from "../../../../components/modals/ModalLayout";
import { useNavigate, useParams } from "react-router-dom";
import Form from "../../../../components/forms/Form";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { logEvent } from "@amplitude/analytics-browser";
import Button from "../../../../components/Button/Button";
import { useSDK } from "@thirdweb-dev/react";
import {
    useCreateAssetMutation,
    useGetAssetsTypeQuery,
    useGetUnitsQuery,
} from "../../../../services/supplyChainService";
import { useUploadFileMutation } from "../../../../services/fileManagementService";
import Loading from "../../../../components/LoadingSkeleton/Loading";
import RecordForm from "./RecordForm";
import DependencyContainer from "./DependencyContainer";

export default function AddRecordModal({
    title = "New Record",
    id,
    selectedBatch,
    refetch,
    setLoading = () => {}, // Default value for setLoading
}) {
    // 1. React Hooks
    const { hideModal, setModalLoading } = useModal();
    const sdk = useSDK();
    const navigate = useNavigate();
    const { batchId } = useParams();

    // 2. State Management
    const [isLoading, setIsLoading] = useState(false);
    const [loadingStep, setLoadingStep] = useState("");
    const [selectedAssetType, setSelectedAssetType] = useState(null);
    const [dependencies, setDependencies] = useState([]);
    const [hasMissingDependencies, setHasMissingDependencies] = useState(false);
    const [missingDependenciesMap, setMissingDependenciesMap] = useState({});
    const [groupRepeatCount, setGroupRepeatCount] = useState({});

    // 3. API Hooks
    const [createAsset] = useCreateAssetMutation();
    const [uploadFile] = useUploadFileMutation();
    const { data: assetTypesResponse, isLoading: isLoadingAssetTypes } =
        useGetAssetsTypeQuery(id);
    const { data: unitsData } = useGetUnitsQuery();

    // 4. Effects
    useEffect(() => {
        if (selectedAssetType?.dependencyConfig?.dependencies) {
            setDependencies(
                selectedAssetType.dependencyConfig.dependencies.map((dep) => ({
                    ...dep,
                    selectedAssets: [],
                })),
            );
        } else {
            setDependencies([]);
        }
    }, [selectedAssetType]);

    useEffect(() => {
        if (selectedAssetType && selectedAssetType.formConfig?.fields) {
            setGroupRepeatCount(
                selectedAssetType.formConfig.fields
                    .filter((field) => field.type === "group")
                    .reduce(
                        (acc, field) => ({ ...acc, [field.groupId]: 1 }),
                        {},
                    ),
            );
        }
    }, [selectedAssetType]);

    // 5. Derived State (useMemo)
    const assetTypeList = useMemo(() => {
        return assetTypesResponse?.data?.map((assetType) => ({
            id: assetType.id,
            label: assetType.type_name,
            value: assetType.id,
            dependencyConfig: assetType.dependency_config,
            formConfig: assetType.form_config,
            unit_category: assetType.unit_category.id,
        }));
    }, [assetTypesResponse]);

    const unitOptions = useMemo(() => {
        if (!unitsData?.data || !selectedAssetType?.unit_category) return [];
        const selectedCategory = unitsData.data.find(
            (category) => category.id === selectedAssetType.unit_category,
        );
        if (!selectedCategory) return [];
        return selectedCategory.units.map((unit) => ({
            label: `${unit.name} (${unit.symbol})`,
            value: unit.id,
        }));
    }, [unitsData, selectedAssetType]);

    const isAddRecordButtonDisabled = useMemo(() => {
        if (hasMissingDependencies) return true;
        if (dependencies.length === 0) return false;
        return dependencies.every((dep) => dep.selectedAssets.length === 0);
    }, [hasMissingDependencies, dependencies]);

    // 6. Handler Functions
    const handleCloseClick = () => {
        try {
            navigate(-1);

            logEvent({
                eventName: "cancel_button_clicked",
                eventProperties: {
                    page_name: "New Record Page",
                },
            });
        } catch (error) {
            navigate(`/projects/${id}/batches/${batchId}`);
        }
    };

    const handleDependencyChange = (updatedDependency) => {
        setDependencies((prevDeps) =>
            prevDeps.map((dep) =>
                dep.asset_type_id === updatedDependency.asset_type_id
                    ? updatedDependency
                    : dep,
            ),
        );
    };

    const handleRemoveDependency = (assetTypeId, index) => {
        setDependencies((prevDeps) =>
            prevDeps.map((dep) =>
                dep.asset_type_id === assetTypeId
                    ? {
                          ...dep,
                          selectedAssets: dep.selectedAssets.filter(
                              (_, i) => i !== index,
                          ),
                      }
                    : dep,
            ),
        );
    };

    const handleMissingDependencies = useCallback((assetTypeId, hasMissing) => {
        setMissingDependenciesMap((prev) => {
            const newMap = { ...prev, [assetTypeId]: hasMissing };
            const hasAnyMissing = Object.values(newMap).some(Boolean);

            // Batch updates
            setHasMissingDependencies(hasAnyMissing);
            return newMap;
        });
    }, []);

    const handleAssetCreate = async (values) => {
        setIsLoading(true);
        setLoadingStep("Validating data...");
        setModalLoading(true);

        logEvent({
            eventName: "record_creation_initiated",
            eventProperties: {
                page_name: "Ne Record Page",
            },
        });
        // Format dependencies to match backend expectations
        const formattedDependencies = dependencies.reduce((acc, dep) => {
            if (dep.selectedAssets && dep.selectedAssets.length > 0) {
                dep.selectedAssets.forEach((asset) => {
                    acc.push({
                        asset_uuid: asset.assetUuid,
                        qty: asset.quantity,
                    });
                });
            }
            return acc;
        }, []);

        let formConfig = [];
        for (let field in values.field) {
            // Check if the field is an image
            if (field.includes("group")) {
                const groupId = field.replace("group", "");

                let valueSets = [];

                for (let groupField of values.field[field]) {
                    let groupFields = [];

                    for (let key in groupField) {
                        const assetTypeConfig =
                            selectedAssetType.formConfig.fields.find(
                                (config) =>
                                    config.name === key &&
                                    config.groupId === groupId,
                            );

                        if (!assetTypeConfig) {
                            continue;
                        }

                        if (
                            assetTypeConfig.type === "file" ||
                            assetTypeConfig.type === "image"
                        ) {
                            try {
                                const file = groupField[key];
                                if (!file) continue;

                                const formData = new FormData();
                                formData.append("file", file);

                                const uploadResponse = await uploadFile({
                                    file: formData,
                                });
                                if (uploadResponse.error) {
                                    throw new Error("File upload failed");
                                }

                                groupFields.push({
                                    ...assetTypeConfig,
                                    value: uploadResponse.data?.data?.id,
                                    originalFileName: file.name,
                                });
                            } catch (error) {
                                toast.error(
                                    "File upload failed: " + error.message,
                                );
                                setIsLoading(false);
                                hideModal();
                                return;
                            }
                        } else {
                            groupFields.push({
                                ...assetTypeConfig,
                                value: groupField[key],
                            });
                        }
                    }

                    valueSets.push(groupFields);
                }

                formConfig.push({
                    groupId: groupId,
                    label: "Group",
                    type: "group",
                    value: valueSets,
                });
            } else {
                const assetTypeConfig =
                    selectedAssetType.formConfig.fields.find(
                        (config) => config.name === field,
                    );

                if (!assetTypeConfig) {
                    continue;
                }

                if (
                    assetTypeConfig.type === "file" ||
                    assetTypeConfig.type === "image"
                ) {
                    try {
                        const file = values.field[field];
                        if (!file) continue;

                        const formData = new FormData();
                        formData.append("file", file);

                        const uploadResponse = await uploadFile({
                            file: formData,
                        });
                        if (uploadResponse.error) {
                            throw new Error("File upload failed");
                        }
                        formConfig.push({
                            ...assetTypeConfig,
                            value: uploadResponse.data?.data?.id,
                            originalFileName: file.name,
                        });
                    } catch (error) {
                        toast.error("File upload failed: " + error.message);
                        setIsLoading(false);
                        setModalLoading(false);
                        hideModal();
                        return;
                    }
                } else {
                    formConfig.push({
                        ...assetTypeConfig,
                        value: values.field[field],
                    });
                }
            }
        }

        try {
            console.log("Debug: Creating the asset in backend");
            setLoadingStep("Creating record...");
            console.log({
                id: id,
                asset_id: values.asset_id,
                asset_type: selectedAssetType.id,
                node: values.node,
                dependencies: formattedDependencies,
                form_data: formConfig,
            });
            const response = await createAsset({
                id: id,
                asset_id: values.asset_id,
                asset_type: selectedAssetType.id,
                unit: values.unit,
                qty: values.qty,
                node: values.node,
                dependencies: formattedDependencies,
                form_data: formConfig,
                batch_id: selectedBatch.value,
            });
            if (response.error) {
                if (response.error.data?.errors) {
                    const errorMessages = response.error.data.errors
                        .map((error) => {
                            if (typeof error === "string") {
                                return error;
                            }
                            // Handle case where error is an object
                            if (typeof error === "object") {
                                const [field, messages] =
                                    Object.entries(error)[0];
                                // Check if messages is an array, if not convert to array
                                const messageArray = Array.isArray(messages)
                                    ? messages
                                    : [messages];
                                return `${field}: ${messageArray.join(", ")}`;
                            }
                            return "Unknown error";
                        })
                        .join("\n");
                    throw new Error(errorMessages);
                }
                throw new Error("Could not create the asset record");
            }

            logEvent({
                eventName: "record_creation_completed",
                eventProperties: {
                    record_id: values.asset_id,
                    project_id: id,
                },
            });

            const asset = response.data.data.asset;
            const instructions = response.data.data.instructions;

            console.log(asset);
            console.log(instructions);
            console.log("Debug: Asset created, now trying to execute txn");

            if (instructions && instructions.length > 0) {
                setLoadingStep("Doing blockchain magic...");

                for (var instruction of instructions) {
                    console.log("Debug: Trying txn -", instruction);
                    const contract = await sdk.getContractFromAbi(
                        instruction.contract,
                        instruction.abi,
                    );
                    const txn = await contract.call(
                        instruction.command,
                        instruction.args,
                        {
                            gasLimit: 100000000,
                        },
                    );
                    console.log("Debug:", instruction.command, txn);
                }
            } else {
                console.log("Debug: No blockchain instructions to execute");
            }
            hideModal();
            toast("Asset record added!");
            // handleCloseClick();
        } catch (error) {
            const errorMessage =
                error.message || "An unexpected error occurred";

            // Check for duplicate Asset ID error
            if (
                error.response?.data?.errors?.[0]?.includes("Asset ID") &&
                error.response?.data?.errors?.[0]?.includes("already exists")
            ) {
                toast.error(
                    "This Product ID already exists. Please use a different ID.",
                );
            } else if (errorMessage.includes("Asset with id")) {
                toast.error(errorMessage);
            } else if (errorMessage.includes("timeout")) {
                toast(
                    "Transaction to the blockchain is taking longer than usual. If it fails this record might be archived.",
                );
            } else {
                toast.error(errorMessage);
            }

            logEvent({
                eventName: "record_creation_failed",
                eventProperties: {
                    errorMessage: error,
                },
            });
            hideModal();
        } finally {
            setIsLoading(false);
        }
    };

    const handleOnAssetTypeSelectionChange = (event) => {
        const selection = event.target.value;
        const assetType = assetTypeList.find((at) => at.value === selection);

        setSelectedAssetType(assetType || null);
        setMissingDependenciesMap({});
        setHasMissingDependencies(false);
    };

    const handleAddRepeatingGroup = (groupId) => {
        setGroupRepeatCount({
            ...groupRepeatCount,
            [groupId]: 1 + (groupRepeatCount[groupId] || 0),
        });
    };

    const handleRemoveRepeatingGroup = (groupId) => {
        setGroupRepeatCount({
            ...groupRepeatCount,
            [groupId]:
                groupRepeatCount[groupId] > 1
                    ? groupRepeatCount[groupId] - 1
                    : 1,
        });
    };

    // 7. Validation Schema
    const validationSchema = Yup.object().shape({
        asset_id: Yup.string().required(
            "The Product ID must not be empty or just spaces",
        ),
        // asset_type: Yup.string().required("Asset Type is required"),
        // unit: Yup.string().required("Unit is required"),
        // qty: Yup.number()
        //     .required("Quantity is required")
        //     .min(0.1, "Quantity must be greater than 0"),
    });

    // 8. Loading Check
    if (isLoadingAssetTypes) {
        return (
            <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100vh" }}
            >
                <Loading />
            </div>
        );
    }

    return (
        <ModalLayout className="modal-dialog-centered mx-2" width={600}>
            <div className="card d-flex justify-content-center">
                <ModalLayout.Header>
                    <div className="w-100 p-4 pb-0 d-flex justify-content-center">
                        <Typography variant="h2">{title}</Typography>
                    </div>
                </ModalLayout.Header>
                <ModalLayout.Body>
                    <div className="w-100 pt-0 p-4 d-flex justify-content-center">
                        <Form
                            initialValues={{
                                asset_id: "",
                                asset_type: selectedAssetType?.id || "", // Use the selectedAssetType's id
                                // unit: "",
                                // qty: "",
                            }}
                            onSubmit={handleAssetCreate}
                            // enableReinitialize={true}
                            validationSchema={validationSchema}
                            // validateOnChange={true}
                        >
                            <RecordForm
                                fieldType="text"
                                name="asset_id"
                                label="Product ID"
                                isRequired={true}
                            />
                            <RecordForm
                                fieldType="dropdown"
                                name="asset_type"
                                label="Material type"
                                options={assetTypeList}
                                onChange={handleOnAssetTypeSelectionChange}
                                isRequired={true}
                            />
                            <RecordForm
                                fieldType="dropdown"
                                name="unit"
                                label="Unit"
                                options={unitOptions}
                                disabled={!selectedAssetType}
                                isRequired={true}
                            />
                            <RecordForm
                                fieldType="number"
                                name="qty"
                                label="Quantity"
                                isRequired={true}
                            />
                            {selectedAssetType && (
                                <>
                                    {dependencies.length > 0 && (
                                        <div className="dependencies-container mt-5">
                                            <Typography variant="h2">
                                                Asset Dependencies
                                            </Typography>

                                            {hasMissingDependencies && (
                                                <div className="alert alert-warning mt-2 d-flex align-items-center">
                                                    <i className="bi bi-exclamation-triangle-fill me-2"></i>
                                                    <span>
                                                        Asset creation will fail
                                                        because required
                                                        dependencies are not
                                                        available
                                                    </span>
                                                </div>
                                            )}
                                            <div className="mt-4">
                                                {dependencies.map(
                                                    (dep, index) => (
                                                        <DependencyContainer
                                                            key={
                                                                dep.asset_type_id
                                                            }
                                                            index={index}
                                                            dependency={dep}
                                                            unitOptions={
                                                                unitOptions
                                                            }
                                                            onDependencyChange={
                                                                handleDependencyChange
                                                            }
                                                            onRemoveDependency={
                                                                handleRemoveDependency
                                                            }
                                                            projectId={id}
                                                            onMissingDependencies={(
                                                                hasMissing,
                                                            ) =>
                                                                handleMissingDependencies(
                                                                    dep.asset_type_id,
                                                                    hasMissing,
                                                                )
                                                            }
                                                            batch={
                                                                selectedBatch.value
                                                            }
                                                        />
                                                    ),
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {selectedAssetType.formConfig?.fields?.map?.(
                                        (field) => {
                                            if (field.type === "group") {
                                                let groupFieldRender = [];

                                                for (
                                                    let repeat = 0;
                                                    repeat <
                                                    groupRepeatCount[
                                                        field.groupId
                                                    ];
                                                    repeat++
                                                ) {
                                                    groupFieldRender.push(
                                                        <div
                                                            className="field-group-container"
                                                            key={`group_${field.groupId}_repeat_${repeat}`}
                                                        >
                                                            <div className="field-group-header d-flex p-3 pb-0">
                                                                <div className="text-3">
                                                                    Group{" "}
                                                                    {
                                                                        field.groupId
                                                                    }
                                                                </div>
                                                                {repeat > 0 &&
                                                                    repeat ===
                                                                        groupRepeatCount[
                                                                            field
                                                                                .groupId
                                                                        ] -
                                                                            1 && (
                                                                        <div
                                                                            className="text-3 ms-auto field-group-action-remove"
                                                                            onClick={() =>
                                                                                handleRemoveRepeatingGroup(
                                                                                    field.groupId,
                                                                                )
                                                                            }
                                                                        >
                                                                            X
                                                                            Remove
                                                                        </div>
                                                                    )}
                                                            </div>
                                                            <div className="mx-3 field-group-fields">
                                                                {selectedAssetType.formConfig.fields
                                                                    .filter(
                                                                        (
                                                                            groupField,
                                                                        ) =>
                                                                            groupField.type !==
                                                                                "group" &&
                                                                            groupField.groupId ===
                                                                                field.groupId,
                                                                    )
                                                                    .map(
                                                                        (
                                                                            groupField,
                                                                        ) => {
                                                                            return (
                                                                                <RecordForm
                                                                                    fieldType={
                                                                                        groupField.type
                                                                                    }
                                                                                    name={`field.group${groupField.groupId}.${repeat}.${groupField.name}`}
                                                                                    label={
                                                                                        groupField.label
                                                                                    }
                                                                                    isRequired={
                                                                                        groupField.required
                                                                                    }
                                                                                />
                                                                            );
                                                                        },
                                                                    )}
                                                            </div>
                                                            <div className="field-group-actions">
                                                                {repeat ===
                                                                    groupRepeatCount[
                                                                        field
                                                                            .groupId
                                                                    ] -
                                                                        1 && (
                                                                    <div
                                                                        className="m-3 add-another-node"
                                                                        onClick={() =>
                                                                            handleAddRepeatingGroup(
                                                                                field.groupId,
                                                                            )
                                                                        }
                                                                    >
                                                                        + Add
                                                                        another
                                                                        value
                                                                        set
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>,
                                                    );
                                                }

                                                return groupFieldRender;
                                            }

                                            if (
                                                field.groupId &&
                                                field.groupId !== "0"
                                            ) {
                                                return null;
                                            }

                                            return (
                                                <RecordForm
                                                    fieldType={field.type}
                                                    name={`field.${field.name}`}
                                                    label={field.label}
                                                    isRequired={field.required}
                                                />
                                            );
                                        },
                                    )}
                                </>
                            )}

                            <div className="my-4">
                                <Button
                                    type="submit"
                                    variant="primary"
                                    fullWidth={false}
                                    isLoading={isLoading}
                                    loadingText={loadingStep}
                                    disabled={isAddRecordButtonDisabled}
                                >
                                    Add records
                                </Button>
                            </div>
                        </Form>
                    </div>
                </ModalLayout.Body>
            </div>
        </ModalLayout>
    );
}
