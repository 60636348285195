import { useState } from "react";
import "./accordion.scss";
import Overview from "./Overview";
import ProcessDetail from "./ProcessDetail";
import FacilityDetail from "./FacilityDetail";

const AccordionHeader = ({ image, index, isLanding, isOpen, overview }) => (
    <>
        {image && (
            <>
                <div className="top-row">
                    <div className="number-overlay">{index + 1}</div>
                    {!isLanding && (
                        <div className="accordion-icon">
                            <i
                                className={`fw-bold bi bi-chevron-${isOpen ? "up" : "down"}`}
                            />
                        </div>
                    )}
                </div>
                <img
                    src={image}
                    alt=""
                    className="accordion-image"
                    loading="lazy"
                />
            </>
        )}
        <div
            className={`overview-section d-flex justify-content-between align-items-center ${isOpen ? "opened" : ""}`}
            aria-expanded={isOpen}
        >
            <Overview data={overview} />
        </div>
    </>
);

const AccordionContent = ({
    isLanding,
    processDetails,
    facilityData,
    txnHash,
    tokenId,
    explorer_url,
    contractAddress,
    chain,
}) => (
    <div className="process-details">
        <ProcessDetail data={processDetails} />
        {!isLanding && <FacilityDetail facilityData={facilityData} />}
        <div className="w-100 d-flex justify-content-center mt-4 mb-3">
            {txnHash && (
                <a
                    href={`${explorer_url}/tx/${txnHash}`}
                    className="btn btn-secondary"
                    target="_blank"
                    rel="noreferrer"
                >
                    Verify
                </a>
            )}
            {tokenId && (
                <a
                    href={`${explorer_url}/token/${contractAddress}?a=${tokenId}`}
                    className="btn btn-secondary ms-2"
                    target="_blank"
                    rel="noreferrer"
                >
                    View Token
                </a>
            )}
        </div>
    </div>
);

const AccordionItem = ({ item, index, isOpen, onClick, isLanding }) => (
    <div className={`card accordion-card ${isLanding ? "landing" : ""}`}>
        <div
            className={`card-header ${isOpen ? "opened" : ""}`}
            role="button"
            onClick={!isLanding ? onClick : undefined}
        >
            <AccordionHeader
                image={item.image}
                index={index}
                isLanding={isLanding}
                isOpen={isOpen}
                overview={item.overview}
            />
        </div>

        {(isOpen || isLanding) && (
            <div className="card-body">
                <AccordionContent
                    isLanding={isLanding}
                    processDetails={item.processDetails}
                    facilityData={item.facilityData}
                    txnHash={item.transaction_hash}
                    tokenId={item.token_id}
                    explorer_url={item.explorer_url}
                    contractAddress={item.contract_address}
                    chain={item.chain}
                />
            </div>
        )}
    </div>
);

const Accordion = ({ items, isLanding, className = "" }) => {
    const [openIndex, setOpenIndex] = useState(isLanding ? 0 : null);

    const handleClick = (index) => {
        if (!isLanding) {
            setOpenIndex(openIndex === index ? null : index);
        }
    };

    // Convert single item to array if needed
    const itemsArray = Array.isArray(items) ? items : [items];

    return (
        <div className={`accordion ${className}`}>
            {itemsArray.map((item, index) => (
                <AccordionItem
                    key={index}
                    item={item}
                    index={index}
                    isOpen={isLanding || openIndex === index}
                    onClick={() => handleClick(index)}
                    isLanding={isLanding}
                />
            ))}
        </div>
    );
};

export default Accordion;
