import * as Yup from "yup";

export const designValidationSchema = Yup.object().shape({
    site_slug: Yup.string().required("Site slug is required"),
    primary_color: Yup.string().required("Primary color is required"),
});

// Home page validation schema
export const homeValidationSchema = Yup.object().shape({
    bannerImage: Yup.mixed().nullable(),
});

// About page validation schema
export const aboutValidationSchema = Yup.object().shape({
    page_title: Yup.string().nullable(),
    page_description: Yup.string().nullable(),
    bannerImage: Yup.mixed().nullable(),
    secondaryImage: Yup.mixed().nullable(),
    subtitle: Yup.string().nullable(),
    subtitle_description: Yup.string().nullable(),
    teamImage: Yup.mixed().nullable(),
    team_quote: Yup.string().nullable(),
    team_author: Yup.string().nullable(),
    videoContent: Yup.object()
        .shape({
            video_title: Yup.string().nullable(),
            video_link: Yup.string()
                .url("If provided, must be a valid URL")
                .nullable(),
            video_description: Yup.string().nullable(),
        })
        .nullable(),
    stories: Yup.array()
        .of(
            Yup.object().shape({
                story_title: Yup.string().nullable(),
                story_image: Yup.mixed().nullable(),
                story_content: Yup.string().nullable(),
            }),
        )
        .nullable(),
});

// Trace page validation schema
export const traceValidationSchema = Yup.object().shape({
    page_title: Yup.string().nullable(),
    page_description: Yup.string().nullable(),
    bannerImage: Yup.mixed().nullable(),
    show_trace_widget: Yup.boolean().nullable(),
});

export const certificationValidationSchema = Yup.object().shape({
    page_title: Yup.string().required("Page title is required"),
    page_description: Yup.string().nullable(),
    bannerImage: Yup.mixed().nullable(),
    standards: Yup.array()
        .of(
            Yup.object().shape({
                standard_title: Yup.string().nullable(),
                standard_image: Yup.mixed().nullable(),
                standard_description: Yup.string().nullable(),
            }),
        )
        .nullable(),
    qualities: Yup.array()
        .of(
            Yup.object().shape({
                quality_title: Yup.string().nullable(),
                quality_description: Yup.string().nullable(),
                quality_icon: Yup.string().nullable(),
            }),
        )
        .nullable(),
    videoContent: Yup.object()
        .shape({
            video_title: Yup.string().nullable(),
            video_link: Yup.string()
                .url("If provided, must be a valid URL")
                .nullable(),
            video_description: Yup.string().nullable(),
        })
        .nullable(),
});
