import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './footer.scss';

const Footer = () => {
  return (
    <footer className="py-3 footer-container d-flex justify-content-center align-items-center">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-auto">
            <div className="d-flex align-items-center">
              <span className="me-2 powered-by-text">Powered by</span>
              <img
                src="/images/hyperTrail.png"
                alt="Hypertrails"
                height="20"
                className="img-fluid footer-logo"
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
