import React, { useState } from "react";
import Typography from "../../../../../components/Typography/Typography";
import { useNavigate } from "react-router-dom";

const ConfigPreview = ({ config }) => {
    const [expanded] = useState(true);
    const navigate = useNavigate();

    if (!config || !config.config_data) return null;

    const handleNavigate = (e) => {
        // Prevent event bubbling and default form submission
        e.preventDefault();
        e.stopPropagation();

        const pageMap = {
            ABOUT_US: "about",
            TRACE: "trace",
            SUSTAINABILITY: "sustainability",
            CERTIFICATION: "certification",
        };
        const page = pageMap[config.config_type];
        if (page) {
            // Use navigate with replace option to avoid form submission
            navigate(`?page=${page}`, { replace: true });
        }
    };

    const renderConfigValue = (value) => {
        if (typeof value === "boolean") {
            return (
                <span
                    className={`badge ${value ? "bg-success" : "bg-secondary"}`}
                >
                    {value ? "Enabled" : "Disabled"}
                </span>
            );
        } else if (typeof value === "object") {
            return <span className="text-muted fst-italic">{"{object}"}</span>;
        } else if (value?.includes("http")) {
            return (
                <a
                    href={value}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary text-decoration-none text-truncate d-inline-block"
                    style={{ maxWidth: "200px" }}
                    title={value}
                >
                    {value.split("?")[0].split("/").pop() ||
                        value.split("?")[0]}
                    <i
                        className="bi bi-box-arrow-up-right ms-1"
                        style={{ fontSize: "0.8rem" }}
                    ></i>
                </a>
            );
        }
        return value || <span className="text-muted fst-italic">Not set</span>;
    };

    // Get first story if available
    const firstStory =
        config.config_data.stories && config.config_data.stories.length > 0
            ? config.config_data.stories[0]
            : null;

    return (
        <div
            className="mb-4 position-relative overflow-hidden shadow"
            style={{ borderRadius: "8px", transition: "all 0.3s ease" }}
        >
            <div
                className="p-3 d-flex justify-content-between align-items-center"
                style={{
                    borderLeft: `4px solid ${config.enabled ? "#198754" : "#6c757d"}`,
                    background: "#f8f9fa",
                    transition: "background 0.3s ease",
                }}
            >
                <div>
                    <Typography
                        variant="h3"
                        className="fw-bold mb-0 d-flex align-items-center"
                    >
                        <i className={`bi bi-info-circle me-2`}></i>
                        {(() => {
                            switch (config.config_type) {
                                case "TRACE":
                                    return "Trace Configuration";
                                case "ABOUT_US":
                                    return "About Us Configuration";
                                case "CERTIFICATION":
                                    return "Certification Configuration";
                                case "SUSTAINABILITY":
                                    return "Sustainability Configuration";
                                default:
                                    return "Configuration";
                            }
                        })()}
                    </Typography>
                    <Typography variant="body2" className="text-muted mt-1">
                        {config.config_data.page_description}
                    </Typography>
                </div>
                <div className="d-flex align-items-center">
                    <div
                        className={`badge me-3 ${config.enabled ? "bg-success" : "bg-secondary"}`}
                        style={{ fontSize: "0.75rem" }}
                    >
                        {config.enabled ? "Active" : "Inactive"}
                    </div>
                    <button
                        className="btn btn-link p-0 ms-2"
                        onClick={handleNavigate}
                        title="Go to configuration"
                        type="button"
                    >
                        <i className="bi bi-arrow-right-circle fs-5 text-primary"></i>
                    </button>
                </div>
            </div>

            {expanded && (
                <div
                    className="px-4 py-3 border-top"
                    style={{
                        background: "white",
                        transition: "all 0.3s ease",
                        animation: "fadeIn 0.3s ease-in-out",
                    }}
                >
                    <div className="row py-2 px-1 rounded">
                        <div className="col-md-6 mb-2">
                            <div className="d-flex align-items-center">
                                <Typography
                                    variant="body2"
                                    className="text-muted me-2 text-capitalize"
                                >
                                    Page Title:
                                </Typography>
                                <Typography variant="body2">
                                    {renderConfigValue(
                                        config.config_data.page_title,
                                    )}
                                </Typography>
                            </div>
                        </div>

                        {config.config_type === "TRACE" ? (
                            <div className="col-md-6 mb-2">
                                <div className="d-flex align-items-center">
                                    <Typography
                                        variant="body2"
                                        className="text-muted me-2 text-capitalize"
                                    >
                                        Trace Widget:
                                    </Typography>
                                    <Typography variant="body2">
                                        {renderConfigValue(
                                            config.config_data
                                                .show_trace_widget,
                                        )}
                                    </Typography>
                                </div>
                            </div>
                        ) : config.config_type === "CERTIFICATION" &&
                          config.config_data.qualities?.length > 0 ? (
                            <>
                                <div className="col-md-6 mb-2">
                                    <div className="d-flex align-items-center">
                                        <Typography
                                            variant="body2"
                                            className="text-muted me-2 text-capitalize"
                                        >
                                            Quality Parameters:
                                        </Typography>
                                        <Typography variant="body2">
                                            {
                                                config.config_data.qualities
                                                    .length
                                            }{" "}
                                            parameter(s)
                                        </Typography>
                                    </div>
                                </div>
                            </>
                        ) : (
                            firstStory && (
                                <>
                                    <div className="col-md-6 mb-2">
                                        <div className="d-flex align-items-center">
                                            <Typography
                                                variant="body2"
                                                className="text-muted me-2 text-capitalize"
                                            >
                                                Story Title:
                                            </Typography>
                                            <Typography variant="body2">
                                                {renderConfigValue(
                                                    firstStory.story_title,
                                                )}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <div className="d-flex align-items-center">
                                            <Typography
                                                variant="body2"
                                                className="text-muted me-2 text-capitalize"
                                            >
                                                Story Content:
                                            </Typography>
                                            <Typography variant="body2">
                                                {renderConfigValue(
                                                    firstStory.story_content,
                                                )}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <div className="d-flex flex-row">
                                            <div className="d-flex align-items-center mb-1">
                                                <Typography
                                                    variant="body2"
                                                    className="text-muted me-2 text-capitalize"
                                                >
                                                    Story Image:
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography
                                                    variant="body2"
                                                    className="m-0"
                                                >
                                                    {renderConfigValue(
                                                        firstStory.story_image_file_url,
                                                    )}
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ConfigPreview;
