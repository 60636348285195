import React from "react";
import Typography from "../../../../../../components/Typography/Typography";
import TextField from "../../../../../../components/forms/TextField";
import TextArea from "../../../../../../components/forms/TextArea";
import UnifiedFileUpload from "../../../../../../components/forms/UnifiedFileUpload";

const BasicInformation = ({
    initialValues,
    bannerImage,
    handleBannerFileSelected,
}) => {
    return (
        <div className="mb-4">
            <Typography variant="h5" className="mb-3">
                Basic Information
            </Typography>
            <div className="mb-3">
                <TextField name="page_title" label="Page Title" required />
            </div>
            <div className="mb-3">
                <TextArea
                    name="page_description"
                    label="Page Description"
                    rows={4}
                />
            </div>
            <div className="mb-3">
                <UnifiedFileUpload
                    name="bannerImage"
                    label="Banner Image"
                    fileType="image"
                    initialFile={
                        initialValues.banner_image_file_url
                            ? {
                                  url: initialValues.banner_image_file_url,
                                  name: initialValues.banner_image_file_name,
                                  isExisting: true,
                              }
                            : null
                    }
                    selectedFile={bannerImage}
                    onFileSelected={handleBannerFileSelected}
                />
            </div>
        </div>
    );
};

export default BasicInformation;
