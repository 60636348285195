import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSaveSiteConfigurationMutation } from "../../../../../services/supplyChainService";
import certificationsTemplate from "./template.json";
import { useImageProcessing } from "../../../../../utils/imageUtils";
import CertificationConfiguration from "./CertificationConfiguration";

export default function CertificationPageController({
    projectId,
    allConfigurations,
    isLoading: isLoadingAll,
    configId,
    setConfigId,
    initialValues,
    setInitialValues,
    pageConfigurations,
    setPageConfigurations,
}) {
    // API hooks
    const [saveSiteConfiguration, { isLoading: isSaving }] =
        useSaveSiteConfigurationMutation();
    const { processImage } = useImageProcessing();

    // Image state management
    const [certificationBannerImage, setCertificationBannerImage] =
        useState(null);

    const [standardImages, setstandardImages] = useState([]);

    // Initialize configuration from existing data
    useEffect(() => {
        if (!allConfigurations?.data) return;

        const certificationConfig = allConfigurations.data.find(
            (config) => config.config_type === "CERTIFICATION",
        );

        if (certificationConfig) {
            setConfigId(certificationConfig.id);
            const certificationConfigData = certificationConfig.config_data;

            // Set images from existing configuration
            if (certificationConfigData.banner_image_file_url) {
                setCertificationBannerImage(
                    certificationConfigData.banner_image_file_url,
                );
            }
            if (certificationConfigData.standards?.length) {
                setstandardImages(
                    certificationConfigData.standards.map(
                        (standard) => standard.standard_image_file_url || null,
                    ),
                );
            }

            setInitialValues({
                ...certificationConfigData,
            });
        }
    }, [allConfigurations, setConfigId, setInitialValues]);

    // Image selection handlers
    const handleCertificationBannerSelected = (file) => {
        if (file !== undefined) {
            setCertificationBannerImage(file);
        }
    };

    const handleStandardImageSelected = (file, index) => {
        setstandardImages((prev) => {
            const newImages = [...prev];
            newImages[index] = file;
            return newImages;
        });
    };

    // Form submission handler
    const handleSubmit = async (values) => {
        try {
            // Process all images in parallel
            const bannerImageFileId = await processImage(
                certificationBannerImage,
                initialValues.banner_image_file_id,
            );
            // Determine page enablement status
            const existingConfiguration =
                pageConfigurations.certification?.exists;

            const isEnabled = existingConfiguration
                ? pageConfigurations.certification?.enabled !== false
                : true;
            // Process standards to handle any image uploads
            const processStandards = await Promise.all(
                values.standards
                    .filter(
                        (standard) =>
                            standard.standard_title ||
                            standard.standard_description ||
                            standardImages[values.standards.indexOf(standard)],
                    )
                    .map(async (standard, index) => {
                        const standardImage = standardImages[index];

                        const standardImageFileId = await processImage(
                            standardImage,
                            standard.standard_image_file_id,
                        );

                        return {
                            standard_title: standard.standard_title || null,
                            standard_image_file_id: standardImageFileId,
                            standard_description:
                                standard.standard_description || null,
                        };
                    }),
            );

            const processQualities = await Promise.all(
                values.qualities
                    .filter(
                        (quality) =>
                            quality.quality_title ||
                            quality.quality_description ||
                            quality.quality_icon,
                    )
                    .map(async (quality) => {
                        return {
                            quality_title: quality.quality_title || null,
                            quality_description:
                                quality.quality_description || null,
                            quality_icon: quality.quality_icon || null,
                        };
                    }),
            );

            // Prepare configuration data
            const configData = {
                ...certificationsTemplate,
                config_data: {
                    page_title: values.page_title || initialValues.page_title,
                    page_description:
                        values.page_description ||
                        initialValues.page_description,
                    banner_image_file_id: bannerImageFileId,
                    standards: processStandards,
                    qualities: processQualities,
                    video_content:
                        values.video_content || initialValues.video_content,
                },
                enabled: isEnabled,
                required: certificationsTemplate.required,
            };

            // Save configuration to backend
            const result = await saveSiteConfiguration({
                id: projectId,
                configData,
            }).unwrap();

            // Update local state with new configuration
            if (result?.data?.id) {
                setConfigId(result.data.id);
                setPageConfigurations((prev) => ({
                    ...prev,
                    certification: {
                        ...prev.certification,
                        id: result.data.id,
                        exists: true,
                        enabled: existingConfiguration
                            ? prev.certification.enabled
                            : true,
                    },
                }));
            }

            toast.success(
                "Certification page configuration saved successfully",
            );
        } catch (error) {
            console.error("Error saving certification configuration:", error);
            toast.error("Failed to save certification page configuration");
        }
    };

    return (
        <CertificationConfiguration
            handleSubmit={handleSubmit}
            initialValues={initialValues}
            isLoading={isSaving || isLoadingAll}
            certificationsBannerImage={certificationBannerImage}
            handleCertificationsBannerSelected={
                handleCertificationBannerSelected
            }
            standardImages={standardImages}
            handleStandardImageselected={handleStandardImageSelected}
        />
    );
}
