import { useUploadFileMutation } from "../services/fileManagementService";

/**
 * Processes an image field to handle various formats and upload if needed
 * @param {File|string|Object|null} imageData - Image data in various formats
 * @param {string|null} existingImageId - Existing image ID from initial values
 * @returns {Promise<string|null>} - Resolved file ID
 */
export const processImageField = async (
    imageData,
    existingImageId,
    uploadFile,
) => {
    // If image is a File object, upload it
    if (imageData instanceof File) {
        const formData = new FormData();
        formData.append("file", imageData);

        try {
            const uploadResponse = await uploadFile({
                file: formData,
            }).unwrap();
            return uploadResponse?.data?.id || null;
        } catch (error) {
            console.error("Error uploading file:", error);
            return null;
        }
    }

    // If image is a string URL, keep using the existing ID
    if (typeof imageData === "string" && imageData.startsWith("http")) {
        return existingImageId;
    }

    // If image is null, explicitly set to null
    if (imageData === null) {
        return null;
    }

    // If image is an object with response data
    if (typeof imageData === "object" && (imageData?.id || imageData?.file)) {
        return imageData.id || imageData.file;
    }

    return existingImageId;
};

export const useImageProcessing = () => {
    const [uploadFile] = useUploadFileMutation();

    const processImage = async (imageData, existingImageId) => {
        return processImageField(imageData, existingImageId, uploadFile);
    };

    return { processImage };
};
