import React, { useMemo } from "react";
import {
    usePagination,
    useSortBy,
    useTable,
    useRowSelect,
    useExpanded,
} from "react-table";
import "./table.scss";

/**
 * Table component wrapped around `react-table`.
 * @param {{
 * data: any[],
 * columns: any[],
 * isSortable?: boolean,
 * isPaginated?: boolean,
 * isChecked?: boolean,
 * pageSize?: number,
 * pageIndex?: number,
 * renderExpandedContent?: (row: any) => React.ReactNode,
 * }} props
 * @returns {React.FunctionComponent}
 */
export default function BasicTable({
    data = [], // Default value for data prop
    columns,
    isSortable = false,
    isPaginated = false,
    pageSize = 10,
    currentPage = 0,
    showCheckBox = false,
    renderExpandedContent,
}) {
    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }, ref) => {
            const defaultRef = React.useRef();
            const resolvedRef = ref || defaultRef;

            React.useEffect(() => {
                resolvedRef.current.indeterminate = indeterminate;
            }, [resolvedRef, indeterminate]);

            return (
                <div className="m-2 align-middle">
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        ref={resolvedRef}
                        {...rest}
                    />
                </div>
            );
        },
    );

    const expandColumn = {
        id: "expander",
        Header: "", // Add empty string header
        Cell: ({ row }) => (
            <button {...row.getToggleRowExpandedProps()} className="expand-button">
                {row.isExpanded ? "▼" : "▶"}
            </button>
        ),
    };

    const tableColumns = useMemo(() => {
        const cols = columns.map((col) => ({
            ...col,
            Header: col.header,
        }));

        if (renderExpandedContent) {
            cols.unshift(expandColumn);
        }

        if (showCheckBox) {
            cols.unshift(checkboxColumn);
        }

        return cols;
    }, [columns, showCheckBox, renderExpandedContent]);

    const checkboxColumn = {
        id: "selection",
        Header: ({ getToggleAllRowsSelectedProps }) => (
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
        ),
        Cell: ({ row }) => (
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
        ),
    };

    const tableInstance = useTable(
        {
            columns: tableColumns,
            data: data,
            initialState: {
                pageSize: pageSize,
                pageIndex: currentPage,
            },
        },
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
    );

    const getHeaderProps = (tableInstance, column) => {
        if (isSortable) {
            return column.getHeaderProps(column.getSortByToggleProps());
        }

        return column.getHeaderProps();
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
    } = tableInstance;

    return (
        <div>
            <div className="table-responsive">
                {data.length === 0 ? (
                    <div className="text-center p-4">
                        <p className="text-muted mb-0">No data available</p>
                    </div>
                ) : (
                    <table
                        className="table table-borderless table-sm shadow-sm w-100 w-sm-auto mb-0"
                        {...getTableProps()}
                    >
                        <thead className="m-1 table-header">
                            {headerGroups.map((headerGroup) => {
                                const { key, ...headerGroupProps } =
                                    headerGroup.getHeaderGroupProps();
                                return (
                                    <tr key={key} {...headerGroupProps}>
                                        {headerGroup.headers.map((column) => {
                                            const { key, ...columnProps } =
                                                getHeaderProps(
                                                    tableInstance,
                                                    column,
                                                );
                                            return (
                                                <th
                                                    key={key}
                                                    className="align-middle px-3"
                                                    {...columnProps}
                                                >
                                                    {column.render("Header")}
                                                    {isSortable && (
                                                        <span>
                                                            {column.isSorted
                                                                ? column.isSortedDesc
                                                                    ? " ˅"
                                                                    : " ˄"
                                                                : ""}
                                                        </span>
                                                    )}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {(isPaginated ? tableInstance.page : rows).map(
                                (row) => {
                                    prepareRow(row);
                                    const rowProps = row.getRowProps();
                                    return [
                                        <tr
                                            key={`row-${rowProps.key}`}
                                            {...rowProps}
                                            className="table-row-border"
                                        >
                                            {row.cells.map((cell) => {
                                                const { key, ...cellProps } =
                                                    cell.getCellProps();
                                                return (
                                                    <td
                                                        key={key}
                                                        className="align-middle py-2 px-3"
                                                        {...cellProps}
                                                        data-label={String(
                                                            cell.column.Header,
                                                        )}
                                                    >
                                                        {cell.render("Cell")}
                                                    </td>
                                                );
                                            })}
                                        </tr>,
                                        row.isExpanded &&
                                            renderExpandedContent && (
                                                <tr
                                                    key={`expanded-${rowProps.key}`}
                                                >
                                                    <td
                                                        colSpan={
                                                            visibleColumns.length
                                                        }
                                                    >
                                                        {renderExpandedContent(
                                                            row.original,
                                                        )}
                                                    </td>
                                                </tr>
                                            ),
                                    ].filter(Boolean);
                                },
                            )}
                        </tbody>
                    </table>
                )}
            </div>
            {isPaginated && data.length > 0 && (
                <div className="d-flex justify-content-md-end justify-content-center mb-2 mb-md-0 mt-5 table-pagination">
                    <nav>
                        <ul className="pagination">
                            <li
                                className={`m-1 page-item ${
                                    !tableInstance.canPreviousPage
                                        ? "disabled"
                                        : ""
                                }`}
                            >
                                <button
                                    className="page-navigator page-link previous-page"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        tableInstance.previousPage();
                                    }}
                                >
                                    <span className="m-1">{`<`}</span>
                                    Previous
                                </button>
                            </li>
                            {tableInstance.pageCount > 0 && (
                                <>
                                    {[...Array(tableInstance.pageCount)].map(
                                        (_, p) => (
                                            <li
                                                key={p}
                                                className={`m-1 page-item ${
                                                    p ===
                                                    tableInstance.state
                                                        .pageIndex
                                                        ? "active"
                                                        : ""
                                                }`}
                                            >
                                                <button
                                                    className="page-link page-number"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        tableInstance.gotoPage(
                                                            p,
                                                        );
                                                    }}
                                                >
                                                    {p + 1}
                                                </button>
                                            </li>
                                        ),
                                    )}
                                </>
                            )}
                            <li
                                className={`m-1 page-item ${
                                    !tableInstance.canNextPage ? "disabled" : ""
                                }`}
                            >
                                <button
                                    className="page-navigator page-link next-page"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        tableInstance.nextPage();
                                    }}
                                >
                                    Next<span className="m-1">{`>`}</span>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            )}
        </div>
    );
}
